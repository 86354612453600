import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoles } from 'app/core/data/user-roles';
import { User } from '../../../../../projects/tilled-api-client/src';
import { UserViewModel } from '../user-list.component';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite-dialog.component.html',
  styleUrls: ['./user-invite-dialog.component.scss'],
})
export class UserInviteDialogComponent implements OnInit {
  @Output() userInviteData;
  public userInviteForm: FormGroup;
  public availableUserRoles: IUserRole[];
  items: FormArray;
  public isEdit: boolean = false;
  public titleText: string;
  public secondaryText: string;
  public buttonText: string;
  private user: UserViewModel;
  private isMerchantUser: boolean = false;
  private isFirstUser: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
  ) {
    if (this._data.action === 'edit') {
      this.isEdit = true;
      this.titleText = 'Edit User';
      this.secondaryText = 'Select a new role for the user';
      this.buttonText = 'Save User';
    } else {
      this.isEdit = false;
      this.titleText = 'Invite User';
      this.secondaryText = 'Enter the user’s email and assign a role';
      this.buttonText = 'Invite User';
    }
    this.user = this._data?.user ?? null;

    this.isMerchantUser = this._data?.isMerchantUser ?? false;
    this.isFirstUser = this._data?.isFirstUser ?? false;
  }

  ngOnInit(): void {
    let email: string = null;
    if (this.user?.user_name) {
      email = this.user.user_name[1] ?? this.user.user_name[0];
    }

    this.availableUserRoles = this.generateUserRoles();

    this.userInviteForm = this._formBuilder.group({
      email: new FormControl({ value: email, disabled: this.isEdit }, [Validators.required, Validators.email]),
      role: new FormControl(this.user?.role ? UserRoles.KeysFromText.get(this.user.role) : null, [Validators.required]),
    });
  }

  generateUserRoles(): IUserRole[] {
    let allRoleValues: User.RoleEnum[] = [];

    UserRoles.DisplayText.get(User.RoleEnum.ADMIN);
    if (this.isMerchantUser) {
      if (this.isFirstUser) {
        allRoleValues.push(User.RoleEnum.MERCHANT_OWNER);
      } else {
        allRoleValues.push(User.RoleEnum.MERCHANT_ADMIN);
      }
    } else {
      if (this.isFirstUser) {
        allRoleValues.push(User.RoleEnum.OWNER);
      } else {
        allRoleValues.push(User.RoleEnum.ADMIN);
        allRoleValues.push(User.RoleEnum.DEVELOPER);
        allRoleValues.push(User.RoleEnum.ANALYST);
        allRoleValues.push(User.RoleEnum.VIEW_ONLY);
      }
    }
    const userRoles: IUserRole[] = [];
    for (const roleValue of allRoleValues) {
      userRoles.push({
        value: roleValue,
        name: UserRoles.DisplayText.get(roleValue),
        description: UserRoles.Description.get(roleValue),
      });
    }
    return userRoles;
  }

  public inviteOrEditUser(): void {
    if (!this.userInviteForm.invalid) {
      this.dialogRef.close(this.userInviteForm);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

interface IUserRole {
  value: User.RoleEnum;
  name: string;
  description: string;
}
