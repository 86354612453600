import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { TooltipListPipe } from 'app/core/pipes/tooltip-list.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { mapSubtypeToTitle } from 'app/shared/utils/onboarding-utils';
import { Observable, Subscription, filter, takeUntil } from 'rxjs';
import { BulkSubmitDocumentsRequestParams } from '../../../../../projects/tilled-api-client/src/api/documents.service';
import { BulkDocumentSubmission } from '../../../../../projects/tilled-api-client/src/model/bulkDocumentSubmission';
import { BulkDocumentSubmissionResponse } from '../../../../../projects/tilled-api-client/src/model/bulkDocumentSubmissionResponse';
import { DocumentDto } from '../../../../../projects/tilled-api-client/src/model/documentDto';
import { InternalAccount } from '../../../../../projects/tilled-api-client/src/model/internalAccount';
import { ModelFile } from '../../../../../projects/tilled-api-client/src/model/modelFile';

export interface RequestNote {
  description: string;
  note: string;
}

export interface DocumentResponse {
  fileId?: string;
  written?: string;
}

export interface WrittenFormViewModel {
  document: DocumentDto;
  responseForm: AbstractControl;
}

@Component({
  selector: 'app-account-documents-upload',
  templateUrl: './account-documents-upload.component.html',
  styleUrls: ['./account-documents-upload.component.scss'],
})
export class AccountDocumentsUploadComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() accountId: string = null;
  @Input() forConsole: boolean = false;
  @Output() lastRequestedDocumentDate: EventEmitter<string> = new EventEmitter<string>();
  public mapSubtypeToTitle = mapSubtypeToTitle;

  public fileTypes = [ModelFile.TypeEnum.PDF, ModelFile.TypeEnum.PNG, ModelFile.TypeEnum.JPG, ModelFile.TypeEnum.TIFF];
  public filePurpose = ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION;
  private pendingFiles: number = 0;

  private docRequests: DocumentDto[];
  private docResponses: Map<string, DocumentResponse> = new Map<string, DocumentResponse>();
  public writtenRequests: DocumentDto[];
  public fileRequests: DocumentDto[];
  public requestNotes: RequestNote[];
  public bankLetterTooltipText = '';

  public writtenFormArray: FormArray = new FormArray([]);
  public writtenRequestsViewArray: WrittenFormViewModel[] = [];
  public understandChecked: boolean = false;
  public updatingDocuments: boolean = false;

  private allDocuments$: Observable<DocumentDto[]>;
  private documentsAllSub: Subscription;
  private latestRequest: DocumentDto = null;

  private documentsLoading = false;

  constructor(
    private _authService: AuthService,
    private _tooltipListPipe: TooltipListPipe,
    private _documentsAppService: DocumentsAppService,
    private _alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.allDocuments$ = this._documentsAppService.documentsAll$;
    this.documentsAllSub = this.allDocuments$.pipe(filter((allDocuments) => allDocuments !== null)).subscribe({
      next: (reqDocs) => {
        if (this.documentsLoading) {
          return;
        }
        this.documentsLoading = true;

        this.docRequests = reqDocs;
        this.writtenRequests = [];
        this.fileRequests = [];
        this.requestNotes = [];
        this.writtenRequestsViewArray = [];

        this.latestRequest = this.docRequests?.reduce((max, current) => {
          const maxDate = new Date(max.created_at);
          const currentDate = new Date(current.created_at);

          return currentDate > maxDate ? current : max;
        }, this.docRequests[0]);

        if (this.docRequests?.length === 0) {
          this.lastRequestedDocumentDate.emit(null);
        } else {
          this.lastRequestedDocumentDate.emit(this.latestRequest.created_at);
        }

        for (const req of this.docRequests) {
          if (req.request_notes) {
            this.requestNotes.push({ description: this.mapSubtypeToTitle(req.subtype), note: req.request_notes });
          }
          this.docResponses.set(req.id, null);
        }

        this.writtenRequests = this.docRequests.filter((doc) => doc.type === DocumentDto.TypeEnum.WRITTEN);
        this.fileRequests = this.docRequests.filter((doc) => doc.type === DocumentDto.TypeEnum.FILE);

        this.fileRequests = this.fileRequests.sort((a, b) => {
          const subtypeOrder = {
            [DocumentDto.SubtypeEnum.DL_PASSPORT]: 1,
            [DocumentDto.SubtypeEnum.DL_BACK]: 2,
          };
          const aOrder = subtypeOrder[a.subtype] || 3;
          const bOrder = subtypeOrder[b.subtype] || 3;
          return aOrder - bOrder;
        });

        if (this.writtenRequests) {
          for (const written of this.writtenRequests) {
            const newControl = new FormControl(null);
            const viewModel: WrittenFormViewModel = {
              document: written,
              responseForm: newControl,
            };

            this.writtenRequestsViewArray.push(viewModel);
            this.writtenFormArray.push(newControl);
          }
        }
      },
      error: (err) => {
        const message: TilledAlert = {
          message: `Failed to load one or more documents. Please refresh and try again`,
          title: 'Loading failed',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });

    if (this.accountId) {
      this._documentsAppService.getAllDocuments(this.accountId);
    }

    const voidedCheckList = this._tooltipListPipe.transform([
      ' Be a pre-printed voided check (not a temporary check)',
      ' Include the merchant legal name',
      ' Include the merchant business address',
    ]);

    const bankLetterList = this._tooltipListPipe.transform([
      ' Be on a bank letterhead',
      ' Include the registered name of your business',
      ' Include an ACH routing number',
      ' Include an ACH account number',
      ' Be signed and dated by a bank representative within the last 6 months',
    ]);

    this.bankLetterTooltipText =
      'Voided Check Guidelines \n The voided check should:\n' +
      voidedCheckList +
      '\n Bank Letter Guidelines \n The bank letter should:\n' +
      bankLetterList;

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      if (this.accountId !== account?.id && this._authService.isMerchantUser()) {
        this.accountId = account?.id;
        this._documentsAppService.getAllDocuments(this.accountId);
        this.documentsLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.documentsAllSub.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public fileUploaded(event: ModelFile, docId: string): void {
    if (event?.id) {
      this.docResponses.set(docId, { fileId: event.id });
    }
  }

  public fileDeleted(fileId: string, docId: string): void {
    if (fileId) {
      this.docResponses.set(docId, null);
    }
  }

  public pendingFilesChange(numOfFiles: number): void {
    this.pendingFiles = numOfFiles;
  }

  public submitDocuments(): void {
    if (!this.understandChecked) {
      return;
    }
    this.updatingDocuments = true;

    for (const resp of this.writtenRequestsViewArray) {
      const response = resp.responseForm.value;
      if (response) {
        this.docResponses.set(resp.document.id, { written: response });
      }
    }

    const requestArray: BulkDocumentSubmission[] = [];
    for (const [docId, value] of this.docResponses.entries()) {
      if (!value) {
        continue;
      }
      if (!value?.fileId && !value?.written) {
        //nothing to update doc request with
        continue;
      }

      requestArray.push({
        document_id: docId,
        submission_data: {
          file_id: value.fileId ?? null,
          written_response: value.written ?? null,
        },
      });
    }

    let requestParams: BulkSubmitDocumentsRequestParams = {
      tilledAccount: this.accountId,
      bulkDocumentSubmitRequestParams: {
        submissions: requestArray,
      },
    };

    const request$ = this._documentsAppService.bulkSubmitDocuments(requestParams);

    request$.subscribe({
      next: (response) => {
        if (!response) {
          this.showErrorAlert();
          return;
        }
        const successfulSubmission = response.submissions.filter(
          (submission) => submission.status === BulkDocumentSubmissionResponse.StatusEnum.SUCCEEDED,
        );
        if (successfulSubmission?.length === 0) {
          const failedSubmissions = response.submissions.filter(
            (submission) => submission.status === BulkDocumentSubmissionResponse.StatusEnum.FAILED,
          );
          let failureMessage = '';
          for (const submission of failedSubmissions) {
            failureMessage += submission.failure_message + '\n';
          }
          this.showErrorAlert(failureMessage);
          return;
        }
        this.docResponses = new Map<string, DocumentResponse>();
        this.updatingDocuments = false;
        this.documentsLoading = false;
        this.understandChecked = false;
        this._documentsAppService.getAllDocuments(this.accountId);

        if (this.docRequests.length === 0) {
          //this._router.navigate(['/onboarding/submitted']);
          this.lastRequestedDocumentDate.emit(this.latestRequest.created_at);
        } else {
          const message: TilledAlert = {
            message: `Successfully updated one or more document requests`,
            title: 'Document update successful',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);
        }
      },
      error: (err) => {
        this.showErrorAlert();
      },
    });
  }

  private showErrorAlert(failureMessage?: string) {
    const message: TilledAlert = {
      message: failureMessage ?? `Failed to update one or more documents. Please try again`,
      title: 'Update failed',
      type: 'error',
    };
    this._alertService.showAlert(message);
    this.updatingDocuments = false;
  }

  public mapSubtypeToDescription(subtype: DocumentDto.SubtypeEnum): string {
    switch (subtype) {
      //file
      case DocumentDto.SubtypeEnum.DL_PASSPORT:
        return 'Submit a clear photo of your driver’s license or passport. Ensure that all details are visible, including your photo and personal information.';
      case DocumentDto.SubtypeEnum.DL_BACK:
        return 'If you submitted a photo of your driver’s license in the previous step, now capture an image of the back of your driver’s license. Make sure all relevant details, such as your address and signature, are clearly visible. If you submitted a photo of your passport, you can ignore this step.';
      //written
      case DocumentDto.SubtypeEnum.BUSINESS_MODEL:
        return 'Please describe the products/services you offer, your target market, and any unique features or value propositions that differentiate your business from others in your industry.';
      case DocumentDto.SubtypeEnum.PRICING_MODEL:
        return 'Please describe your pricing model. How much do the goods or services you offer cost?';
      case DocumentDto.SubtypeEnum.WEBSITE:
        return 'Please provide a link to your website. Facebook and Instagram websites are not accepted.';
      case DocumentDto.SubtypeEnum.SHOPPING_CART:
        return 'We noticed your website doesn’t have a shopping cart. How does your business typically handle order placements, and what’s the usual lead time between when an order is made and when it’s fulfilled?';
      default:
        return '';
    }
  }
}
