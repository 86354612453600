import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { PaymentsTypes } from 'app/core/data/payments-types';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { CheckoutSessionAppService } from 'app/core/services/checkout-session.app.service';
import { minCheckboxValidator } from 'app/shared/validators/min-checkbox.validator';
import { Subject } from 'rxjs';
import {
  Account,
  AccountCapability,
  CheckoutSessionCreateParams,
  PaymentMethod,
} from '../../../../../projects/tilled-api-client/src';

const demo = require('../../../../assets/js/asset-load.js').demo;

@Component({
  selector: 'app-create-payment-link',
  templateUrl: './create-payment-link-dialog.component.html',
  animations: fuseAnimations,
})
export class CreatePaymentLinkDialogComponent implements OnInit {
  public merchantAccount: Account;
  public isMerchant: boolean;
  public paymentLinkForm: FormGroup;
  public availablePaymentMethods: Map<string, string>;
  public availableCurrencies: Map<string, string>;
  public selectedPaymentType: PaymentMethod.TypeEnum;
  public selectedCurrency: string;
  public disableCard: boolean = false;
  public disableAch: boolean = false;
  public disableEft: boolean = false;
  public disableCurrency: boolean = false;

  private _creatingPaymentLink$ = new Subject<boolean>();
  public creatingPaymentLink$ = this._creatingPaymentLink$.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<CreatePaymentLinkDialogComponent>,
    private _formBuilder: FormBuilder,
    private _checkoutSessionAppService: CheckoutSessionAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.merchantAccount = this._data?.merchantAccount;
    if (this.merchantAccount) {
      this.isMerchant = true;
    }
    if (this.isMerchant) {
      this.parseAccountData();
    }

    this.paymentLinkForm = this._formBuilder.group({
      currency: new FormControl({ value: this.selectedCurrency, disabled: false }, [Validators.required]),
      amount: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(0.01)]),
      description: new FormControl({ value: null, disabled: false }, [Validators.required]),
      paymentMethodCheckboxGroup: new FormGroup(
        {
          cardCheckbox: new FormControl(!this.disableCard),
          achCheckbox: new FormControl(!this.disableAch),
          eftCheckbox: new FormControl(!this.disableEft),
        },
        minCheckboxValidator(),
      ),
    });

    this.paymentLinkForm.get('description').valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }
      if (value[0] === ' ') {
        this.paymentLinkForm.get('description').setValue(value.trim());
      }
    });
  }

  public parseAccountData(account?: Account): void {
    this.merchantAccount = account ?? this.merchantAccount;

    if (!this.merchantAccount) {
      return;
    }

    this.availablePaymentMethods = new Map<string, string>();
    this.availableCurrencies = new Map<string, string>();
    for (let capability of this.merchantAccount.capabilities) {
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        this.availablePaymentMethods.set(
          capability?.product_code?.payment_method_type,
          PaymentsTypes.PaymentMethodDisplayText.get(capability?.product_code?.payment_method_type),
        );
      }
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        this.availableCurrencies.set(
          capability?.product_code?.currency,
          PaymentsTypes.CurrencyDisplayText.get(capability?.product_code?.currency),
        );
      }
    }

    if (!Array.from(this.availablePaymentMethods.keys()).includes('card')) {
      this.disableCard = true;
    } else {
      this.disableCard = false;
    }
    if (!Array.from(this.availablePaymentMethods.keys()).includes('ach_debit')) {
      this.disableAch = true;
    } else {
      this.disableAch = false;
    }
    if (!Array.from(this.availablePaymentMethods.keys()).includes('eft_debit')) {
      this.disableEft = true;
    } else {
      this.disableEft = false;
    }

    if (this.availableCurrencies.size === 1) {
      this.selectedCurrency = Array.from(this.availableCurrencies.keys())[0];
      this.disableCurrency = true;
    } else {
      this.selectedCurrency = null;
      this.disableCurrency = false;
    }

    if (account) {
      this.paymentLinkForm.get('currency').patchValue(this.selectedCurrency);
      this.paymentLinkForm.get('paymentMethodCheckboxGroup').get('cardCheckbox').patchValue(!this.disableCard);
      this.paymentLinkForm.get('paymentMethodCheckboxGroup').get('achCheckbox').patchValue(!this.disableAch);
      this.paymentLinkForm.get('paymentMethodCheckboxGroup').get('eftCheckbox').patchValue(!this.disableEft);
    }
  }

  public closeDialog(value?: any): void {
    this.dialogRef.close(value);
  }

  public generatePaymentLink(): void {
    this._creatingPaymentLink$.next(true);
    let paymentMethods: PaymentMethod.TypeEnum[] = [];
    if (this.paymentLinkForm.get('paymentMethodCheckboxGroup').get('cardCheckbox').value) {
      paymentMethods.push(PaymentMethod.TypeEnum.CARD);
    }
    if (this.paymentLinkForm.get('paymentMethodCheckboxGroup').get('achCheckbox').value) {
      paymentMethods.push(PaymentMethod.TypeEnum.ACH_DEBIT);
    }
    if (this.paymentLinkForm.get('paymentMethodCheckboxGroup').get('eftCheckbox').value) {
      paymentMethods.push(PaymentMethod.TypeEnum.EFT_DEBIT);
    }

    let expiresDate = new Date();
    expiresDate.setDate(expiresDate.getDate() + 14);
    const createParams: CheckoutSessionCreateParams = {
      line_items: [
        {
          quantity: 1,
          price_data: {
            unit_amount: this._numericToMinorPipe.transform(this.paymentLinkForm.get('amount').value),
            currency: this.paymentLinkForm.get('currency').value,
            product_data: {
              name: this.paymentLinkForm.get('description').value,
            },
          },
        },
      ],
      payment_intent_data: {
        payment_method_types: paymentMethods,
      },
      expires_at: expiresDate.toISOString(),
    };

    this._checkoutSessionAppService.createCheckoutSession(createParams, this.merchantAccount.id).subscribe({
      next: (result) => {
        result.url = result.url.replace(/https:\/\/.*\/pay/, `https://${demo.partnerUrlReplacement}/pay`);
        // close and open up success dialog.
        this.closeDialog({
          url: result.url,
          amount: result.amount_total,
          currency: result.currency,
        });
      },
      error: (err) => {
        const message: TilledAlert = {
          message: err?.error?.message ?? 'There was an error creating the payment link, please try again.',
          title: 'Generating payment link failed',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._creatingPaymentLink$.next(false);
      },
    });
  }
}
