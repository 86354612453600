//For a new demo, need to update values here, and also the primary and accent palettes in tailwind.config.js
export const demo = {
  partnerName: 'Authorize.net',
  assetsLogoPath: 'assets/images/logos/',
  partnerFaviconAlt: 'Authorize.net Logo',
  partnerUrlReplacement: 'authorize-net-app.tilled.com',
  partnerFaviconLogo: 'anet-logo-favicon.jpg', //browser tab and loading logo (transparent bg)
  partnerLightLogo: 'anet-logo-light.svg', //light mode logo (dark text, transparent bg)
  partnerDarkLogo: 'anet-logo-dark.png', //dark mode logo (light text, transparent bg)
};

document.title = demo.partnerName;
document.getElementById('logo').style.display = '';
document.getElementById('logoImg').setAttribute('alt', demo.partnerFaviconAlt);
document.getElementById('logoImg').setAttribute('src', demo.assetsLogoPath + demo.partnerFaviconLogo);
