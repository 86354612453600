import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentBase } from '../../core/componentBase';

@Component({
  selector: 'merchant-app-unsaved-dialog',
  templateUrl: './merchant-app-unsaved-dialog.component.html',
})
export class MerchantAppUnsavedDialogComponent extends ComponentBase implements OnInit {
  public title: string;
  public text: string;
  public stayButtonText: string;
  public leaveButtonText: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<MerchantAppUnsavedDialogComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = this._data.title;
    this.text = this._data.text;
    this.stayButtonText = this._data.stayButtonText;
    this.leaveButtonText = this._data.leaveButtonText;
  }

  public closeDialog(stayOnPage: boolean): void {
    this.dialogRef.close(stayOnPage);
  }
}
