<div>
  <mat-chip-list>
    <!-- [color]='green' will apply class '.mat-green' -->
    <mat-chip
      *ngIf="!hasAction"
      id="tilled-chip"
      class="min-w-10 truncate text-sm"
      disabled
      [matTooltip]="tooltipText"
      matTooltipPosition="above"
      [color]="colorClass"
      >{{ displayStatus }}
    </mat-chip>
    <mat-chip
      *ngIf="hasAction"
      (click)="performAction()"
      id="tilled-chip"
      class="min-w-10 cursor-pointer truncate text-sm"
      disabled
      [matTooltip]="tooltipText"
      matTooltipPosition="above"
      [color]="colorClass"
      [matMenuTriggerFor]="documentsMenu"
      >{{ displayStatus }}
      <mat-icon class="icon-warn mt-1 -mr-2"></mat-icon>
    </mat-chip>
    <mat-icon
      *ngIf="requestedDocs"
      [ngClass]="{ 'text-white': solidColor, 'text-warn-500': !solidColor }"
      class="pointer-events-none z-10 -ml-8"
      [svgIcon]="'heroicons_solid:chevron-down'"
    ></mat-icon>
    <mat-icon
      *ngIf="submittedDocs"
      [ngClass]="{ 'text-white': solidColor, 'text-[#6c6dde]': !solidColor }"
      class="pointer-events-none z-10 -ml-8"
      [svgIcon]="'heroicons_solid:chevron-down'"
    ></mat-icon>
    <div class="z-5 relative pl-6 sm:right-6 sm:pl-0">
      <mat-menu
        #documentsMenu="matMenu"
        [hasBackdrop]="true"
        [backdropClass]="''"
        class="-ml-8 overflow-hidden rounded-[10px] bg-tilled-primary pr-10 text-tilled-neutral-100"
      >
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="requestedDocs">
          <span class="flex items-start text-left text-tilled-neutral-100">
            <span style="white-space: pre-line">
              <tilled-paragraph-p3 [white]="true"> Requested Documents: </tilled-paragraph-p3>
              <tilled-paragraph-p4 [white]="true" *ngFor="let doc of requestedDocs"
                >&bull; {{ doc }}</tilled-paragraph-p4
              >
            </span>
          </span>
        </div>
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="submittedDocs">
          <span class="flex items-start text-left text-tilled-neutral-100">
            <span style="white-space: pre-line">
              <tilled-paragraph-p3 [white]="true"> Documents In Review: </tilled-paragraph-p3>
              <tilled-paragraph-p4 [white]="true" *ngFor="let doc of submittedDocs"
                >&bull; {{ doc }}</tilled-paragraph-p4
              >
            </span>
          </span>
        </div>
      </mat-menu>
    </div>
  </mat-chip-list>
</div>
