<mat-form-field
  class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-fit min-w-64"
  appearance="fill"
  floatLabel="auto"
>
  <mat-date-range-input
    [rangePicker]="rangePicker"
    [formGroup]="filterForm"
    style="vertical-align: middle"
    class="text-secondary w-fit text-p3"
  >
    <input
      matStartDate
      placeholder="Start date"
      formControlName="minDate"
      (dateChange)="startDateChanged($event)"
      class="text-secondary w-fit text-p3"
    />
    <input
      matEndDate
      placeholder="End date"
      formControlName="maxDate"
      (dateChange)="endDateChanged($event)"
      class="text-secondary w-fit text-p3"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>

  <mat-date-range-picker #rangePicker [calendarHeaderComponent]="DateRangeHeaderComponent">
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-raised-button color="primary" matDateRangePickerApply>
        <div class="text-tilled-neutral-100">Apply</div>
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <button mat-button *ngIf="hasDate" matSuffix mat-icon-button aria-label="Clear" (click)="clearDateRange()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
