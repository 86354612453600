<ng-container>
  <div class="flex justify-between">
    <div class="mt-2">
      <tilled-label-l1 secondary="true">{{ label }}</tilled-label-l1>
      <tilled-paragraph-p3 *ngIf="paragraphText" secondary="true">{{ paragraphText }}</tilled-paragraph-p3>
      <mat-error color="warn" *ngIf="errors && errorMessage">{{ errorMessage }}</mat-error>

      <ng-content *ngIf="errors && !errorMessage"></ng-content>
    </div>
    <div class="{{ width }} flex justify-around">
      <mat-form-field appearance="outline" class="w-full overflow-visible" [hideRequiredMarker]="hideRequiredMarker">
        <div class="flex">
          <span *ngIf="showPercent" class="{{ styles }} pt-0.5" matSuffix>%</span>
          <span matPrefix *ngIf="showCurrency" class="{{ styles }} pt-0.5">$&nbsp;</span>
          <input
            (blur)="onInputBlur()"
            type="{{ fieldType }}"
            [formControlName]="name"
            matInput
            [class]="inputTextStyle"
            placeholder="{{ placeholder }}"
            mask="{{ mask }}"
            [dropSpecialCharacters]="dropMaskCharacters"
            [leadZeroDateTime]="leadZeroDateTime"
            thousandSeparator="{{ thousandSeparator }}"
            [readonly]="readonly"
            minlength="{{ minLength }}"
            maxlength="{{ maxLength }}"
            min="{{ minValue }}"
            max="{{ maxValue }}"
            [value]="value"
          />
        </div>
      </mat-form-field>
    </div>
  </div>
</ng-container>
