import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CardPricingTemplateDto } from 'app/core/models/pricing-templates';
import { GenericPricingTemplateListItem } from '../../../shared/pricing-templates/generic-pricing-template-list-item';
@Component({
  selector: 'card-pricing-template-list-item',
  templateUrl: './card-pricing-template-list-item.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CardPricingTemplateListItemComponent extends GenericPricingTemplateListItem implements OnInit {
  @Input() detailView: boolean = false;
  @Input() canClick: boolean = true;
  @Input() canEdit: boolean = false;
  @Input() template: CardPricingTemplateDto;
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();

  constructor(private router: Router) {
    super(router);
  }

  ngOnInit() {}

  onButtonClick() {}
}
