import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tilled-card-row',
  templateUrl: './tilled-card-row.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TilledCardRowComponent implements OnInit, OnChanges {
  @Input() labelText: string;
  @Input() valueText: string;
  @Input() labelInfoTooltip: string;
  @Input() valueInfoTooltip: string;
  @Input() urlValue: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
