import { Pipe, PipeTransform } from '@angular/core';
import { ApiKey } from '../../../../projects/tilled-api-client/src';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'scopeAble',
  pure: false,
})
export class ScopeAblePipe implements PipeTransform {
  constructor(private _authService: AuthService) {}
  transform(scope: ApiKey.ScopesEnum): boolean {
    return this._authService.isScopeAble(scope);
  }
}

@Pipe({
  name: 'scopeAbleTooltip',
  pure: false,
})
export class ScopeAbleTooltipPipe implements PipeTransform {
  constructor(private _authService: AuthService) {}
  transform(scope: ApiKey.ScopesEnum): string {
    return this._authService.isScopeAble(scope) ? null : 'Insufficient privileges';
  }
}
