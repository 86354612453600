/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AccountCapabilityProcessingDetail,
  InternalAccountCapability,
  InternalAccountCapabilityUpdateParams,
  InternalService,
} from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';

@Component({
  selector: 'billing-payout-schedule-dialog',
  templateUrl: './billing-payout-schedule-dialog.component.html',
  styleUrls: [],
})
export class BillingPayoutScheduleDialogComponent extends ComponentBase implements OnInit {
  public capability: InternalAccountCapability;
  public scheduleForm: FormGroup;
  public isSubmitting = false;

  public readonly daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public readonly monthlyAnchorTypes = [
    {
      value: MonthlyAnchorDateType.LAST_DAY_OF_MONTH,
      text: monthlyAnchorDateTypeToText[MonthlyAnchorDateType.LAST_DAY_OF_MONTH],
    },
    {
      value: MonthlyAnchorDateType.FIRST_DAY_OF_MONTH,
      text: monthlyAnchorDateTypeToText[MonthlyAnchorDateType.FIRST_DAY_OF_MONTH],
    },
    {
      value: MonthlyAnchorDateType.FIFTH_DAY_OF_MONTH,
      text: monthlyAnchorDateTypeToText[MonthlyAnchorDateType.FIFTH_DAY_OF_MONTH],
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<BillingPayoutScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: BillingPayoutScheduleDialogData,
    private _formBuilder: FormBuilder,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {
    super();

    this.capability = this._data.capability;
  }

  public static computeMonthlyAnchorDateType(anchor: Date | string): MonthlyAnchorDateType {
    if (anchor) {
      let anchorDate: Date;

      if (anchor instanceof Date) {
        anchorDate = anchor;
      } else {
        anchorDate = new Date(anchor);
      }

      const lastDayOfAnchorMonth = new Date(anchorDate.getFullYear(), anchorDate.getMonth() + 1, 0);

      if (anchorDate.getUTCDate() === 1) {
        return MonthlyAnchorDateType.FIRST_DAY_OF_MONTH;
      } else if (anchorDate.getUTCDate() === 5) {
        return MonthlyAnchorDateType.FIFTH_DAY_OF_MONTH;
      } else if (anchorDate.getUTCDate() === lastDayOfAnchorMonth.getUTCDate()) {
        return MonthlyAnchorDateType.LAST_DAY_OF_MONTH;
      }
    }
    return undefined;
  }

  ngOnInit(): void {
    // We're currently only going to allow 'Daily' or 'Weekly' updates
    // but technically, the billing_interval might not equal 1
    // Options: 'Daily', 'Weekly', billing_frequency (e.g. '2 days', '1 month')
    // TODO: Handle billing_interval_count != 1
    // TODO: Handle payout_interval_count != 1

    const currentBillingAnchor = new Date(this.capability.processing_details.billing_anchor);
    const currentPayoutAnchor = new Date(this.capability.processing_details.payout_anchor);

    const billingAnchorDayOfWeek = currentBillingAnchor.getUTCDay();
    const payoutAnchorDayOfWeek = currentPayoutAnchor.getUTCDay();

    const billingMonthlyAnchorDateType =
      BillingPayoutScheduleDialogComponent.computeMonthlyAnchorDateType(currentBillingAnchor) ??
      MonthlyAnchorDateType.LAST_DAY_OF_MONTH;
    const payoutMonthlyAnchorDateType =
      BillingPayoutScheduleDialogComponent.computeMonthlyAnchorDateType(currentPayoutAnchor) ??
      MonthlyAnchorDateType.LAST_DAY_OF_MONTH;

    this.scheduleForm = this._formBuilder.group({
      billingIntervalUnit: new FormControl(
        this.capability.processing_details.billing_interval_unit ||
          AccountCapabilityProcessingDetail.BillingIntervalUnitEnum.DAY,
      ),
      payoutIntervalUnit: new FormControl(
        this.capability.processing_details.payout_interval_unit ||
          AccountCapabilityProcessingDetail.BillingIntervalUnitEnum.DAY,
      ),
      billingAnchorDayOfWeek: new FormControl(billingAnchorDayOfWeek),
      billingMonthlyAnchorDateType: new FormControl(billingMonthlyAnchorDateType),
      payoutAnchorDayOfWeek: new FormControl(payoutAnchorDayOfWeek),
      payoutMonthlyAnchorDateType: new FormControl(payoutMonthlyAnchorDateType),
    });
  }

  public closeDialog(dialogResult?: any): void {
    this.dialogRef.close(dialogResult);
  }

  public submitClicked(): void {
    this.isSubmitting = true;

    const updateParams: InternalAccountCapabilityUpdateParams = { processing_details: {} };
    const formValues: BillingPayoutScheduleFormValue = this.scheduleForm.value;

    if (formValues.billingIntervalUnit === 'day') {
      updateParams.processing_details.billing_interval_unit = formValues.billingIntervalUnit;
      updateParams.processing_details.billing_interval_count = 1;
    } else if (formValues.billingIntervalUnit === 'week') {
      updateParams.processing_details.billing_interval_unit = formValues.billingIntervalUnit;
      updateParams.processing_details.billing_interval_count = 1;

      const today = new Date();
      // e.g. We're looking for the previous "Tuesday" (value: 2)
      //      Let's assume today is Thursday the 20th.
      //      20 - (4 + (7 - 2)) % 7 == 18
      // e.g. Today is 20th Sunday and we're looking for Tuesday
      //      20 - (0 + (7 - 2)) % 7 == 15
      const dateOfPreviousBillingAnchorDayOfWeek =
        today.getDate() - ((today.getDay() + (7 - formValues.billingAnchorDayOfWeek)) % 7);
      const billingAnchor = new Date(new Date().toDateString()); // Strip time
      billingAnchor.setDate(dateOfPreviousBillingAnchorDayOfWeek);
      updateParams.processing_details.billing_anchor = billingAnchor.toISOString();
    } else if (formValues.billingIntervalUnit === 'month') {
      updateParams.processing_details.billing_interval_unit = formValues.billingIntervalUnit;
      updateParams.processing_details.billing_interval_count = 1;

      const billingAnchor = this.computeMonthlyAnchorDate(formValues.billingMonthlyAnchorDateType);
      updateParams.processing_details.billing_anchor = billingAnchor?.toISOString();
    }

    if (formValues.payoutIntervalUnit === 'day') {
      updateParams.processing_details.payout_interval_unit = formValues.payoutIntervalUnit;
      updateParams.processing_details.payout_interval_count = 1;
    } else if (formValues.payoutIntervalUnit === 'week') {
      updateParams.processing_details.payout_interval_unit = formValues.payoutIntervalUnit;
      updateParams.processing_details.payout_interval_count = 1;

      const today = new Date();
      const dateOfPreviousPayoutAnchorDayOfWeek =
        today.getDate() - ((today.getDay() + (7 - formValues.payoutAnchorDayOfWeek)) % 7);
      const payoutAnchor = new Date(new Date().toDateString()); // Strip time
      payoutAnchor.setDate(dateOfPreviousPayoutAnchorDayOfWeek);
      updateParams.processing_details.payout_anchor = payoutAnchor.toISOString();
    } else if (formValues.payoutIntervalUnit === 'month') {
      updateParams.processing_details.payout_interval_unit = formValues.payoutIntervalUnit;
      updateParams.processing_details.payout_interval_count = 1;

      const payoutAnchor = this.computeMonthlyAnchorDate(formValues.payoutMonthlyAnchorDateType);
      updateParams.processing_details.payout_anchor = payoutAnchor?.toISOString();
    }

    this._internalService
      .internalUpdateAccountCapability({
        tilledAccount: this._data.accountId,
        capabilityId: this.capability.id,
        internalAccountCapabilityUpdateParams: updateParams,
      })
      .subscribe({
        next: (account) => {
          this.closeDialog(account);
        },
        error: (err) => {
          const message: TilledAlert = {
            message: 'Could not update billing and payout schedules.',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
          this.isSubmitting = false;
          throw new Error('Error updating billing and payout schedules ' + JSON.stringify(err));
        },
      });
  }

  private computeMonthlyAnchorDate(anchorDateType: MonthlyAnchorDateType): Date {
    const today = new Date();

    const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const fifthDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 5);

    switch (anchorDateType) {
      case MonthlyAnchorDateType.LAST_DAY_OF_MONTH:
        return lastDayOfPreviousMonth;

      case MonthlyAnchorDateType.FIRST_DAY_OF_MONTH:
        return firstDayOfPreviousMonth;

      case MonthlyAnchorDateType.FIFTH_DAY_OF_MONTH:
        return fifthDayOfPreviousMonth;

      default:
        break;
    }

    return undefined;
  }
}

export interface BillingPayoutScheduleDialogData {
  action: 'edit';
  accountId: string;
  capability: InternalAccountCapability;
}

interface BillingPayoutScheduleFormValue {
  billingIntervalUnit: AccountCapabilityProcessingDetail.BillingIntervalUnitEnum;
  payoutIntervalUnit: AccountCapabilityProcessingDetail.BillingIntervalUnitEnum;
  billingAnchorDayOfWeek: number;
  payoutAnchorDayOfWeek: number;
  billingMonthlyAnchorDateType: MonthlyAnchorDateType;
  payoutMonthlyAnchorDateType: MonthlyAnchorDateType;
}

export enum MonthlyAnchorDateType {
  LAST_DAY_OF_MONTH = 0,
  FIRST_DAY_OF_MONTH = 1,
  FIFTH_DAY_OF_MONTH = 2,
}

export const monthlyAnchorDateTypeToText: {
  [Key in MonthlyAnchorDateType]: string;
} = {
  [MonthlyAnchorDateType.LAST_DAY_OF_MONTH]: 'Last day of the current month',
  [MonthlyAnchorDateType.FIRST_DAY_OF_MONTH]: '1st of the following month',
  [MonthlyAnchorDateType.FIFTH_DAY_OF_MONTH]: '5th of the following month',
};
