<div class="bg-tilled-neutral-100">
  <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
    <mat-icon class="icon-size-5" [color]="'primary'" [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
  </button>
  <mat-menu
    #actionsMenu="matMenu"
    [hasBackdrop]="true"
    [backdropClass]="''"
    [class]="'action-list-menu-arrow-top rounded-[10px] bg-tilled-primary text-tilled-neutral-100 overflow-hidden py-2'"
  >
    <ng-container *ngFor="let actionListItem of actionList">
      <button
        mat-menu-item
        [class]="'max-h-6'"
        [disabled]="actionListItem.disabled"
        (click)="actionListItem.callback()"
      >
        <span [class]="'text-tilled-neutral-100 flex items-center '">
          <span
            ><tilled-paragraph-p3 [white]="true" [bold]="true">{{ actionListItem.name }}</tilled-paragraph-p3></span
          >
        </span>
      </button>
    </ng-container>
  </mat-menu>
</div>
