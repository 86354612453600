import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CardChargeFeeTemplate, PricingTemplate } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'card-pricing',
  templateUrl: './card-pricing-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardPricingCardComponent implements OnInit {
  @Input() showCard?: boolean = true;
  @Input() label?: string = 'PRICING DETAILS';
  @Input() currency?: PricingTemplate.CurrencyEnum = PricingTemplate.CurrencyEnum.USD;
  @Input() feeType?: CardChargeFeeTemplate.TransactionFeeTypeEnum;
  @Input() visaRate?: number = 0;
  @Input() amexRate?: number = 0;
  @Input() transactionFee?: number = 0;
  @Input() chargebackFee?: number = 0;
  @Input() retrievalFee?: number = 0;
  @Input() reversalFee?: number = 0;
  @Input() accountMonthlyFee?: number = 0;
  @Input() accountMonthlyMinimumFee?: number = 0;

  constructor() {}

  ngOnInit() {}
}
