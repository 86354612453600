import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { mapSubtypeToTitle } from 'app/shared/utils/onboarding-utils';
import { Account, AccountCapability, AccountDocument } from '../../../../projects/tilled-api-client/src';

/**
 * Takes a AccountCapability StatusEnum
 */
@Pipe({
  name: 'accountStatus',
})
export class AccountStatusPipe implements PipeTransform {
  /**
   *
   * @param status AccountCapability StatusEnum
   * @returns
   */
  transform(status: AccountCapability.StatusEnum, account: Account): TilledChipConfig {
    let chipConfig: TilledChipConfig = {
      color: ChipColorClass.SOLID_ORANGE,
      text: status,
      toolTip: '',
    };
    if (account.document_requests?.length > 0) {
      const reqDocs = account.document_requests.filter((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED);
      if (reqDocs?.length > 0) {
        chipConfig.color = ChipColorClass.SOLID_RED;
        chipConfig.text = 'ACTION REQUIRED';
        const reqDocTypes: string[] = [];
        for (const doc of reqDocs) {
          reqDocTypes.push(mapSubtypeToTitle(doc.subtype));
        }
        chipConfig.requestedDocuments = reqDocTypes;
      } else {
        const subDocs = account.document_requests.filter((doc) => doc.status === AccountDocument.StatusEnum.SUBMITTED);
        if (subDocs?.length > 0) {
          chipConfig.color = ChipColorClass.SOLID_PURPLE;
          if (status === AccountCapability.StatusEnum.IN_REVIEW) {
            chipConfig.text = 'IN REVIEW';
          } else {
            chipConfig.text = 'SUBMITTED';
          }
          const subDocTypes: string[] = [];
          for (const doc of subDocs) {
            subDocTypes.push(mapSubtypeToTitle(doc.subtype));
          }
          chipConfig.submittedDocuments = subDocTypes;
        }
      }
    } else if (status === AccountCapability.StatusEnum.ACTIVE) {
      chipConfig.color = ChipColorClass.SOLID_GREEN;
      chipConfig.text = 'ACTIVE';
    } else if (status === AccountCapability.StatusEnum.CREATED) {
      chipConfig.color = ChipColorClass.SOLID_ORANGE;
      chipConfig.text = 'CREATED';
    } else if (status === AccountCapability.StatusEnum.DISABLED) {
      chipConfig.color = ChipColorClass.SOLID_ACCENT;
      chipConfig.text = 'DISABLED';
    } else if (status === AccountCapability.StatusEnum.IN_REVIEW) {
      chipConfig.color = ChipColorClass.SOLID_PURPLE;
      chipConfig.text = 'IN REVIEW';
      chipConfig.toolTip =
        'This account is under review by the Underwriting team. No additional documentation is needed at this time.';
    } else if (status === AccountCapability.StatusEnum.REJECTED) {
      chipConfig.color = ChipColorClass.SOLID_ACCENT;
      chipConfig.text = 'REJECTED';
    } else if (status === AccountCapability.StatusEnum.STARTED) {
      chipConfig.color = ChipColorClass.SOLID_ORANGE;
      chipConfig.text = 'STARTED';
    } else if (status === AccountCapability.StatusEnum.SUBMITTED) {
      chipConfig.color = ChipColorClass.SOLID_PURPLE;
      chipConfig.text = 'SUBMITTED';
    } else if (status === AccountCapability.StatusEnum.WITHDRAWN) {
      chipConfig.color = ChipColorClass.SOLID_ACCENT;
      chipConfig.text = 'WITHDRAWN';
    }

    return chipConfig;
  }
}
