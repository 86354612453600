import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionListItem } from './action-list.model';

@Component({
  selector: 'action-list-component',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
})
export class ActionListComponent implements OnInit {
  @Input() actionList: ActionListItem[];

  constructor() {}

  ngOnInit(): void {}
}
