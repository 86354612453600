import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { startWith } from 'rxjs';

@Component({
  selector: 'tilled-search',
  templateUrl: './tilled-search.component.html',
  styleUrls: ['./tilled-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TilledSearchComponent extends ComponentBase implements OnInit {
  @Input() controlName: FormControl;
  @Input() placeholder?: string;
  @Input() toolTip?: string;
  @Input() styles?: string = '';

  public showClear: boolean;
  constructor() {
    super();
  }

  ngOnInit() {
    this.controlName.valueChanges.pipe(startWith(this.controlName.value)).subscribe((q) => {
      if (q && q.length > 0) {
        this.showClear = true;
      }
    });
  }

  clearSearchText() {
    this.showClear = false;
    this.controlName.patchValue(null);
  }
}
