import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectedAccountDialogComponent } from 'app/shared/connected-account/connected-account-dialog/connected-account-dialog.component';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
})
export class CustomerDialogComponent implements OnInit {
  public isLoading: boolean;
  public customerForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConnectedAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.customerForm = this._formBuilder.group({
      first_name: new FormControl({ value: null, disabled: false }, [Validators.required]),
      middle_name: new FormControl({ value: null, disabled: false }, []),
      last_name: new FormControl({ value: null, disabled: false }, [Validators.required]),
      email: new FormControl(null, []),
      phone: new FormControl(null, []),
    });
  }

  addCustomerClicked() {
    this.dialogRef.close(this.customerForm);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
