import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tilled-card-underline',
  templateUrl: './tilled-card-underline.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TilledCardUnderlineComponent implements OnInit, OnChanges {
  @Input() headingText: string;
  @Input() rowsData: TilledCardRowData[];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}

export interface TilledCardRowData {
  labelText: string;
  valueText: string;
  tooltipText?: string;
  urlValue?: string;
}
