import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UsersAppService } from '../../core/services/users.app.service';
import {
  CreateUserInvitationRequestParams,
  UserInvitationCreateParams,
  User,
} from '../../../../projects/tilled-api-client/src';
import { ComponentBase } from '../../core/componentBase';
import { FuseAlertService } from '../../../@fuse/components/alert';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from '../../core/services/alert.service';
import { TilledAlert } from '../../core/models/tilled-alert';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'merchant-app-invitation-dialog',
  templateUrl: './merchant-app-invitation-dialog.component.html',
})
export class MerchantAppInvitationDialogComponent extends ComponentBase implements OnInit, OnDestroy {
  public userInviteForm: FormGroup;
  public accountId: string = '';
  public title: string;
  public legalName: string;
  public showCopyUrlButton: boolean;
  public submitButtonText: string;
  public showLinkToUsers: boolean;
  public inviteText: string;
  public alertMessage: string;
  public accountUserCount: number = 0;
  public disabled: boolean = false;
  private usersCount$: Observable<number>;
  private userInvitationsCount$: Observable<number>;
  private invitationResponse$: Observable<any>;
  private _displayAlert$ = new Subject<boolean>();
  public displayAlert$ = this._displayAlert$.asObservable();
  private action: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: InviteMerchantDialogData,
    private dialogRef: MatDialogRef<MerchantAppInvitationDialogComponent>,
    private _formBuilder: FormBuilder,
    private _fuseAlertService: FuseAlertService,
    private _usersAppService: UsersAppService,
    private _router: Router,
    private _clipboard: Clipboard,
    private _alertService: AlertService,
    private _authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.accountId = this._data.accountId;
    this.title = this._data.title;
    this.legalName = this._data.legalName;
    this.submitButtonText = this._data.submitButtonText;
    this.showCopyUrlButton = this._data.showCopyUrlButton;
    this.showLinkToUsers = this._data.showLinkToUsers;

    this.usersCount$ = this._usersAppService.usersCount$;
    this.userInvitationsCount$ = this._usersAppService.userInvitationsCount$;
    this.invitationResponse$ = this._usersAppService.userInvitationResponse$;
    this.userInvitationsCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res1) => {
      this.usersCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res2) => {
        this.accountUserCount = res1 + res2;
        if (this.accountUserCount === 0) {
          this.inviteText = `Share ${this.legalName} application with an business owner`;
        } else {
          this.inviteText = `Share ${this.legalName} application with a business representative`;
        }
      });
    });

    if (!this._authService.isScopeAble('user_invitations:write')) {
      this.disabled = true;
      this.inviteText = 'Not authorized to invite users';
    } else {
      this._usersAppService.getAllUsers({ tilledAccount: this.accountId });
      this._usersAppService.getAllUserInvitations({ tilledAccount: this.accountId });
    }
    this.userInviteForm = this._formBuilder.group({
      email: new FormControl({ value: this._data.email, disabled: this.disabled }, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  public inviteUser(action: string): void {
    this.action = action;

    if (!this.userInviteForm.invalid && !this.userInviteForm.disabled) {
      const params: CreateUserInvitationRequestParams = {
        tilledAccount: this.accountId,
        userInvitationCreateParams: {
          role:
            this.accountUserCount === 0
              ? UserInvitationCreateParams.RoleEnum.MERCHANT_OWNER
              : UserInvitationCreateParams.RoleEnum.MERCHANT_ADMIN,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          email_template:
            action === 'email'
              ? UserInvitationCreateParams.EmailTemplateEnum.MERCHANT_APPLICATION
              : UserInvitationCreateParams.EmailTemplateEnum.NONE,
          email: this.userInviteForm.getRawValue().email,
        },
      };
      this._usersAppService.inviteUser(params);

      this.invitationResponse$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: (resp) => {
          if (resp.error?.message) {
            this.alertMessage =
              resp?.error?.message ?? 'System Error, please try again or contact support for assistance.';
            this._displayAlert$.next(true);
            this._fuseAlertService.show('invitationDialogAlertBox');
            return;
          }
          let message: TilledAlert;
          if (action === 'copy') {
            this._clipboard.copy(resp.invitation_url);
            message = {
              message: `User invitation link for ${resp.email} copied`,
              title: 'User invitation link copied',
              type: 'success',
              timer: 8000,
            };
          } else if (action === 'email') {
            message = {
              message: `User invitation for ${resp.email} sent successfully`,
              title: 'User invitation sent',
              type: 'success',
              timer: 8000,
            };
          }
          this._alertService.showAlert(message);

          this.closeDialog();
        },
      });
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  navigateToUsers(): void {
    this.closeDialog();
    this._router.navigate([`/merchants/${this.accountId}`], { queryParams: { tab: 'users' } });
  }
}

export interface InviteMerchantDialogData {
  accountId: string;
  title: string;
  legalName: string;
  email: string;
  showCopyUrlButton: boolean;
  submitButtonText: string;
  showLinkToUsers: boolean;
}
