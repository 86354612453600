/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BalanceTransaction } from './balanceTransaction';


export interface Payout { 
    account_id: string;
    /**
     * Amount to be transferred to your bank account, in the smallest currency unit (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency).
     */
    amount: number;
    balance_transaction: BalanceTransaction;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: Payout.CurrencyEnum;
    /**
     * An arbitrary string attached to the object. Often useful for displaying to users.
     */
    description?: string;
    /**
     * Error code explaining the reason for a payout failure, if available.
     */
    failure_code?: Payout.FailureCodeEnum;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The date the payout was paid.
     */
    paid_at?: string;
    /**
     * Current status of the payout: `paid`, `pending`, `in_transit`, `canceled` or `failed`. A payout is `pending` until it is submitted to the bank, when it becomes `in_transit`. The status then changes to `paid` if the transaction goes through, or to `failed` or `canceled` (within 5 business days). Some failed payouts may initially show as `paid` but then change to `failed`.
     */
    status: Payout.StatusEnum;
    /**
     * An optional property that is included when specified in a query parameter on request. It represents the number of balance_transaction objects associated with the payout (aside from the payout balance transaction itself).
     */
    transaction_count?: number;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace Payout {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type FailureCodeEnum = 'account_closed' | 'account_frozen' | 'bank_account_restricted' | 'bank_ownership_changed' | 'could_not_process' | 'debit_not_authorized' | 'declined' | 'invalid_account_number' | 'incorrect_account_holder_name' | 'incorrect_account_holder_address' | 'incorrect_account_holder_tax_id' | 'invalid_currency' | 'no_account';
    export const FailureCodeEnum = {
        ACCOUNT_CLOSED: 'account_closed' as FailureCodeEnum,
        ACCOUNT_FROZEN: 'account_frozen' as FailureCodeEnum,
        BANK_ACCOUNT_RESTRICTED: 'bank_account_restricted' as FailureCodeEnum,
        BANK_OWNERSHIP_CHANGED: 'bank_ownership_changed' as FailureCodeEnum,
        COULD_NOT_PROCESS: 'could_not_process' as FailureCodeEnum,
        DEBIT_NOT_AUTHORIZED: 'debit_not_authorized' as FailureCodeEnum,
        DECLINED: 'declined' as FailureCodeEnum,
        INVALID_ACCOUNT_NUMBER: 'invalid_account_number' as FailureCodeEnum,
        INCORRECT_ACCOUNT_HOLDER_NAME: 'incorrect_account_holder_name' as FailureCodeEnum,
        INCORRECT_ACCOUNT_HOLDER_ADDRESS: 'incorrect_account_holder_address' as FailureCodeEnum,
        INCORRECT_ACCOUNT_HOLDER_TAX_ID: 'incorrect_account_holder_tax_id' as FailureCodeEnum,
        INVALID_CURRENCY: 'invalid_currency' as FailureCodeEnum,
        NO_ACCOUNT: 'no_account' as FailureCodeEnum
    };
    export type StatusEnum = 'canceled' | 'failed' | 'in_transit' | 'paid' | 'pending';
    export const StatusEnum = {
        CANCELED: 'canceled' as StatusEnum,
        FAILED: 'failed' as StatusEnum,
        IN_TRANSIT: 'in_transit' as StatusEnum,
        PAID: 'paid' as StatusEnum,
        PENDING: 'pending' as StatusEnum
    };
}


