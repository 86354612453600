<div class="no-scrollbar -mb-8 max-h-[100vh] w-fit overflow-y-scroll sm:min-w-120">
  <mat-stepper #stepper labelPosition="bottom">
    <mat-step label="New Payment" color="primary">
      <form-card
        (exitClick)="closeDialog()"
        (nextClick)="nextPage()"
        [backButton]="false"
        [nextButton]="true"
        [nextDisabled]="newPaymentForm.invalid"
      >
        <form [formGroup]="newPaymentForm">
          <div data-test-id="modal-header" class="grid w-full justify-items-center pb-2 text-center">
            <!-- Title -->
            <tilled-heading-h2 class="pb-2">Create New Payment</tilled-heading-h2>
            <tilled-paragraph-p3 class="pb-2">Enter payment details below.</tilled-paragraph-p3>
          </div>
          <div>
            <ng-container *ngIf="!isMerchant">
              <div class="w-full pt-8">
                <tilled-label-l1 secondary="true">Merchant</tilled-label-l1>
              </div>
              <app-merchant-selector
                class="-mt-3"
                inputAppearance="standard"
                additionalClasses="w-full"
                (setMerchantAccount)="merchantAccountChanged($event)"
              >
              </app-merchant-selector>
            </ng-container>

            <div [ngClass]="disablePaymentMethod ? 'hidden' : ''">
              <mat-form-field floatLabel="always" appearance="standard" class="-mt-4 w-full">
                <tilled-label-l1 secondary="true">Payment Method</tilled-label-l1>
                <mat-select
                  class="text-secondary pt-4 text-p3"
                  formControlName="paymentMethod"
                  [(value)]="selectedPaymentMethod"
                  [disabled]="disablePaymentMethod"
                  placeholder="Select a payment method"
                  data-test-id="payment-method"
                >
                  <mat-option
                    *ngFor="let paymentMethod of availablePaymentMethods | keyvalue"
                    [value]="paymentMethod.key"
                  >
                    {{ paymentMethod.value }}
                  </mat-option>
                </mat-select>
                <mat-error class="text-sm" color="warn" *ngIf="newPaymentForm.get('paymentMethod').hasError('required')"
                  >A payment method is required</mat-error
                >
              </mat-form-field>
            </div>

            <div [ngClass]="disableCurrency ? 'hidden' : ''">
              <mat-form-field floatLabel="always" appearance="standard" class="-mt-4 w-full pb-4">
                <tilled-label-l1 secondary="true" class="">Currency</tilled-label-l1>
                <mat-select
                  class="text-secondary pt-4 text-p3"
                  formControlName="currency"
                  [(value)]="selectedCurrency"
                  [disabled]="disableCurrency"
                  placeholder="Select a currency"
                  data-test-id="currency"
                >
                  <mat-option *ngFor="let currency of availableCurrencies | keyvalue" [value]="currency.key">
                    {{ currency.value }}
                  </mat-option>
                </mat-select>
                <mat-error class="text-sm" color="warn" *ngIf="newPaymentForm.get('currency').hasError('required')"
                  >A currency is required</mat-error
                >
              </mat-form-field>
            </div>

            <outline-input
              name="amount"
              showCurrency="'true'"
              [errors]="newPaymentForm.get('amount').hasError('required') && newPaymentForm.get('amount').touched"
              label="Payment Amount"
              inputTextStyle="mb-2 text-tilled-primary py-1"
              mask="separator.2"
              data-test-id="payment-amount"
            >
              <div class="pt-1 text-sm text-warn" *ngIf="newPaymentForm.get('amount').invalid">
                A valid payment amount is required
              </div>
            </outline-input>
          </div>
        </form>
      </form-card>
    </mat-step>

    <mat-step label="Collect Payment Details">
      <!-- Card Present -->
      <div [ngClass]="selectedPaymentType == 'card_present' ? 'visible grid min-w-100 sm:min-w-[700px]' : 'hidden'">
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="collectCardPresentPayment()"
          [submitDisabled]="cardPresentPaymentForm.invalid || (submittingPayment$ | async)"
          [submitText]="'Collect Payment'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
        >
          <form [formGroup]="cardPresentPaymentForm">
            <div data-test-id="card-present-header" class="grid min-w-fit justify-items-center px-4 pb-10">
              <!-- Title -->
              <div class="flex-cols flex pb-2">
                <tilled-heading-h2> Card Present </tilled-heading-h2>
                <tilled-heading-h2 primaryHighlight="true"
                  >&nbsp;{{ newPaymentForm.get('amount').value * 100 | minorUnitsToCurrency }}</tilled-heading-h2
                >
              </div>
              <tilled-paragraph-p3>Enter terminal information</tilled-paragraph-p3>
              <div class="col-span-1 -mb-2 flex flex-col">
                <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                  <tilled-label-l1 secondary="true">Select Terminal</tilled-label-l1>
                  <mat-select class="text-secondary pt-4 text-p3" formControlName="terminalIds">
                    <mat-option *ngFor="let terminal of terminalIds" [value]="terminal.id">
                      {{ terminal.id }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </form-card>
      </div>
      <!-- Credit Card -->
      <div [ngClass]="selectedPaymentType == 'card' ? 'visible grid min-w-100 sm:min-w-[700px]' : 'hidden'">
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="collectCardPayment()"
          [submitDisabled]="cardPaymentForm.invalid || tilledCardForm?.invalid || (submittingPayment$ | async)"
          [submitText]="'Collect Payment'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
        >
          <form [formGroup]="cardPaymentForm">
            <div data-test-id="credit-card-header" class="grid min-w-fit justify-items-center px-4 pb-10">
              <!-- Title -->
              <div class="flex-cols flex pb-2">
                <tilled-heading-h2>{{ pageTitle }}</tilled-heading-h2>
                <tilled-heading-h2 primaryHighlight="true"
                  >&nbsp;{{ newPaymentForm.get('amount').value * 100 | minorUnitsToCurrency }}</tilled-heading-h2
                >
              </div>
              <tilled-paragraph-p3>Enter the customer’s payment method information</tilled-paragraph-p3>
            </div>

            <div class="flex flex-col">
              <div class="grid w-[full] grid-cols-2 gap-x-6 sm:grid-cols-4">
                <div class="col-span-4 flex flex-col items-center">
                  <div class="mb-5 w-32">
                    <div id="native-payment-element"></div>
                  </div>
                </div>
                <div class="col-span-2 -mb-2 flex flex-col">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-1.5">Credit Card Number</tilled-label-l1>
                    <div class="flex flex-row">
                      <div class="inputField" id="card-number-element"></div>
                      <div id="card-suffix" class="-ml-10 flex h-[40px] w-[40px] items-center">
                        <mat-icon>credit_card</mat-icon>
                      </div>
                    </div>
                    <div class="pt-1 text-sm text-warn" *ngIf="showCardNumberError; else noCardNumberError" @fadeIn>
                      A valid credit card number is required
                    </div>
                    <ng-template #noCardNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div class="col-span-1 -mb-2 flex w-[170px] flex-col">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-1.5">Expiration</tilled-label-l1>
                    <div class="inputField" id="card-expiration-element"></div>
                    <div class="pt-1 text-sm text-warn" *ngIf="showExpirationError; else noExpirationError" @fadeIn>
                      A valid expiration date is required
                    </div>
                    <ng-template #noExpirationError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div class="col-span-1 -mb-2 flex w-[170px] flex-col">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-1.5">CVV</tilled-label-l1>
                    <div class="inputField" id="card-cvv-element"></div>
                    <div class="pt-1 text-sm text-warn" *ngIf="showCvvError; else noCvvError" @fadeIn>
                      A valid CVV number is required
                    </div>
                    <ng-template #noCvvError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <!-- new row -->
                <div class="col-span-2 -mt-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Name on Card</tilled-label-l1>
                    <input
                      data-test-id="cardholder-name"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="cardholderName"
                      placeholder=""
                    />
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="cardPaymentForm.get('cardholderName').hasError('required')"
                      >A name on card is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-2 -mt-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Billing Zip</tilled-label-l1>
                    <input
                      data-test-id="billing-zip"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="postalCode"
                      placeholder="12345"
                      maxlength="10"
                    />
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="cardPaymentForm.get('postalCode').hasError('required')"
                      >A billing ZIP is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <!-- end of Card Info 4 col grid-->

              <!-- start of Billing Address toggle -->
              <div class="pt-6">
                <mat-slide-toggle
                  color="primary"
                  (change)="cardAddressToggled($event)"
                  [(ngModel)]="cardAddressIsChecked"
                  [ngModelOptions]="{ standalone: true }"
                  data-test-id="billing-address-toggle"
                >
                  <tilled-paragraph-p3>Billing Address</tilled-paragraph-p3>
                </mat-slide-toggle>
                <tilled-paragraph-p4>Optional</tilled-paragraph-p4>
                <div *ngIf="cardAddressIsChecked" @expandCollapse>
                  <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-4">
                    <div class="col-span-2 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Address line 1</tilled-label-l1>
                        <input
                          data-test-id="billing-address-1"
                          class="pt-4 text-p3 text-tilled-primary"
                          matInput
                          formControlName="street1"
                          placeholder=""
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-span-2 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Address line 2</tilled-label-l1>
                        <input
                          data-test-id="billing-address-2"
                          class="pt-4 text-p3 text-tilled-primary"
                          matInput
                          formControlName="street2"
                          placeholder=""
                        />
                      </mat-form-field>
                    </div>
                    <!-- new row -->
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Country</tilled-label-l1>
                        <mat-select
                          data-test-id="billing-country"
                          class="text-secondary pt-4 text-p3"
                          formControlName="country"
                          placeholder=""
                        >
                          <mat-option
                            *ngFor="let countryAndCode of countryCodeMap | keyvalue : originalOrder"
                            [value]="countryAndCode.key"
                          >
                            {{ countryAndCode.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">State</tilled-label-l1>
                        <mat-select
                          data-test-id="billing-state"
                          class="text-secondary pt-4 text-p3"
                          formControlName="state"
                          placeholder=""
                        >
                          <mat-option *ngFor="let stateAndCode of stateCodeMap | keyvalue" [value]="stateAndCode.key">
                            {{ stateAndCode.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-span-2 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">City</tilled-label-l1>
                        <input
                          data-test-id="billing-city"
                          class="pt-4 text-p3 text-tilled-primary"
                          matInput
                          formControlName="city"
                          placeholder=""
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- end of Billing Address 4 column grid -->
                </div>
              </div>
              <!-- end of Billing Address toggle -->

              <!-- start of Level 3 toggle -->
              <div class="pt-6" formGroupName="level3Form">
                <mat-slide-toggle
                  data-test-id="level-3-toggle"
                  color="primary"
                  (change)="cardLevel3Toggled($event)"
                  [(ngModel)]="cardLevel3IsChecked"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <tilled-paragraph-p3>Level II/III Data</tilled-paragraph-p3>
                </mat-slide-toggle>
                <tilled-paragraph-p4>Optional</tilled-paragraph-p4>
                <div *ngIf="cardLevel3IsChecked" @expandCollapse>
                  <div class="pt-6">
                    <tilled-heading-h5>Shipping Details</tilled-heading-h5>
                  </div>
                  <div class="grid w-full grid-cols-3 gap-x-6">
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Ship to Country</tilled-label-l1>
                        <mat-select
                          data-test-id="level-3-country"
                          class="text-secondary pt-4 text-p3"
                          formControlName="shipToCountry"
                          placeholder=""
                        >
                          <mat-option
                            *ngFor="let countryAndCode of countryCodeMap | keyvalue : originalOrder"
                            [value]="countryAndCode.key"
                          >
                            {{ countryAndCode.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Ship to Zip</tilled-label-l1>
                        <input
                          data-test-id="level-3-ship-to-zip"
                          class="pt-4 text-p3 text-tilled-primary"
                          matInput
                          formControlName="shipToZip"
                          placeholder="12345"
                          maxlength="10"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Ship from Zip</tilled-label-l1>
                        <input
                          data-test-id="level-3-ship-from-zip"
                          class="pt-4 text-p3 text-tilled-primary"
                          matInput
                          formControlName="shipFromZip"
                          placeholder="12345"
                          maxlength="10"
                        />
                      </mat-form-field>
                    </div>
                    <!-- new row -->
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Shipping Amount</tilled-label-l1>
                        <div class="flex">
                          <span matPrefix class="pt-3.5 text-p3 text-tilled-primary">$&nbsp;</span>
                          <input
                            data-test-id="level-3-shipping-amount"
                            class="pt-4 text-p3 text-tilled-primary"
                            matInput
                            formControlName="shipAmount"
                            placeholder=""
                            mask="separator.2"
                          />
                        </div>
                      </mat-form-field>
                    </div>
                    <div class="col-span-1 -mb-2 flex flex-col">
                      <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                        <tilled-label-l1 secondary="true">Duty Amount</tilled-label-l1>
                        <div class="flex">
                          <span matPrefix class="pt-3.5 text-p3 text-tilled-primary">$&nbsp;</span>
                          <input
                            data-test-id="level-3-duty-amount"
                            class="pt-4 text-p3 text-tilled-primary"
                            matInput
                            formControlName="dutyAmount"
                            placeholder=""
                            mask="separator.2"
                          />
                        </div>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- end of Level 3 - 3 column grid -->

                  <div class="flex w-full flex-col pt-8">
                    <app-line-items
                      [lineItems]="lineItems"
                      [updateLineItemsCallback]="updateLineItems"
                    ></app-line-items>
                  </div>
                </div>
              </div>
              <!-- end of Level 3 toggle -->
            </div>
          </form>
        </form-card>
      </div>

      <!-- ACH Debit -->
      <div
        [ngClass]="
          selectedPaymentType == 'ach_debit' || selectedPaymentType == 'eft_debit'
            ? 'visible grid min-w-100 sm:min-w-[700px]'
            : 'hidden'
        "
      >
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="collectBankPayment()"
          [submitDisabled]="bankPaymentForm.invalid || tilledBankForm?.invalid || (submittingPayment$ | async)"
          [submitText]="'Collect Payment'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
        >
          <form [formGroup]="bankPaymentForm">
            <div data-test-id="ach-header" class="grid min-w-fit justify-items-center px-4 pb-2">
              <!-- Title -->
              <div class="flex-cols flex pb-2">
                <tilled-heading-h2>{{ pageTitle }}</tilled-heading-h2>
                <tilled-heading-h2 primaryHighlight="true"
                  >&nbsp;{{ newPaymentForm.get('amount').value * 100 | minorUnitsToCurrency }}</tilled-heading-h2
                >
              </div>
              <tilled-paragraph-p3>Enter the customer’s payment method information</tilled-paragraph-p3>
            </div>
            <div class="flex flex-col">
              <div class="grid w-[full] grid-cols-1 gap-x-6 sm:grid-cols-4">
                <div class="col-span-2 flex flex-col pt-8">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-1.5">Account Number</tilled-label-l1>
                    <div class="inputField" id="bank-account-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showAccountNumberError; else noAchAccountNumberError"
                      @fadeIn
                    >
                      A valid account number is required
                    </div>
                    <ng-template #noAchAccountNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div
                  [ngClass]="
                    selectedPaymentType == 'ach_debit'
                      ? 'col-span-1 flex w-[170px] flex-col pt-8'
                      : 'col-span-2 flex flex-col pt-8'
                  "
                >
                  <div class="flex w-full flex-col">
                    <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else eftLabel">
                      <tilled-label-l1 secondary="true" class="pb-1.5">Routing Number</tilled-label-l1>
                    </ng-container>
                    <ng-template #eftLabel>
                      <tilled-label-l1 secondary="true" class="pb-1.5"
                        >Transit Number and Institution Id</tilled-label-l1
                      >
                    </ng-template>
                    <div class="inputField" id="bank-routing-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showRoutingNumberError; else noRoutingNumberError"
                      @fadeIn
                    >
                      A valid
                      {{ selectedPaymentType == 'ach_debit' ? 'routing number' : 'transit number and institution ID' }}
                      is required
                    </div>
                    <ng-template #noRoutingNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="selectedPaymentType == 'ach_debit'" class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Account Type</tilled-label-l1>
                    <mat-select
                      data-test-id="ach-account-type"
                      class="text-secondary pt-4 text-p3"
                      formControlName="accountType"
                    >
                      <mat-option [value]="'checking'">Checking</mat-option>
                      <mat-option [value]="'savings'">Savings</mat-option>
                    </mat-select>
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="bankPaymentForm.get('accountType').hasError('required')"
                      >An account type is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <!-- new row -->
                <div class="col-span-4 -mt-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Account Holder Name</tilled-label-l1>
                    <input
                      data-test-id="ach-account-holder"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="accountholderName"
                      placeholder=""
                    />
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="bankPaymentForm.get('accountholderName').hasError('required')"
                      >An account holder name is required</mat-error
                    >
                  </mat-form-field>
                </div>

                <!-- billing details -->
                <div class="col-span-2 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Address line 1</tilled-label-l1>
                    <input
                      data-test-id="ach-address-1"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="street1"
                      placeholder=""
                    />
                    <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('street1').hasError('required')"
                      >An address line 1 is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-2 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Address line 2</tilled-label-l1>
                    <input
                      data-test-id="ach-address-2"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="street2"
                      placeholder=""
                    />
                  </mat-form-field>
                </div>
                <!-- new row -->
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Country</tilled-label-l1>
                    <mat-select
                      data-test-id="ach-country"
                      class="text-secondary pt-4 text-p3"
                      formControlName="country"
                      placeholder=""
                    >
                      <mat-option
                        *ngFor="let countryAndCode of countryCodeMap | keyvalue : originalOrder"
                        [value]="countryAndCode.key"
                      >
                        {{ countryAndCode.value }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('country').hasError('required')"
                      >A country is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">State</tilled-label-l1>
                    <mat-select
                      data-test-id="ach-state"
                      class="text-secondary pt-4 text-p3"
                      formControlName="state"
                      placeholder=""
                    >
                      <mat-option *ngFor="let stateAndCode of stateCodeMap | keyvalue" [value]="stateAndCode.key">
                        {{ stateAndCode.value }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('state').hasError('required')"
                      >A state is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">City</tilled-label-l1>
                    <input
                      data-test-id="ach-city"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="city"
                      placeholder=""
                    />
                    <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('city').hasError('required')"
                      >A city is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else postalLable">
                      <tilled-label-l1 secondary="true">Zip</tilled-label-l1>
                    </ng-container>
                    <ng-template #postalLable>
                      <tilled-label-l1 secondary="true">Postal Code</tilled-label-l1>
                    </ng-template>
                    <input
                      data-test-id="ach-zip"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="postalCode"
                      placeholder="12345"
                      maxlength="10"
                    />
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="bankPaymentForm.get('postalCode').hasError('required')"
                      >A {{ selectedPaymentType == 'ach_debit' ? 'zip' : 'postal code' }} is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <!-- end of ACH Info 4 col grid-->
            </div>
          </form>
        </form-card>
      </div>
    </mat-step>
  </mat-stepper>
</div>
