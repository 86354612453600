import { Pipe, PipeTransform } from '@angular/core';
import { InternalPaymentIntent } from '../../../../projects/tilled-api-client/src/model/internalPaymentIntent';

/**
 * Takes a InternalPaymentIntent.PaymentMethodTypesEnum
 */
@Pipe({
  name: 'paymentMethod',
})
export class PaymentMethodPipe implements PipeTransform {
  /**
   *
   * @param paymentMethod InternalPaymentIntent.PaymentMethodTypesEnum
   * @returns
   */
  transform(paymentMethod: InternalPaymentIntent.PaymentMethodTypesEnum): string {
    if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.CARD) {
      return 'Card';
    } else if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.ACH_DEBIT) {
      return 'ACH Debit';
    } else if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.EFT_DEBIT) {
      return 'EFT Debit';
    } else if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.CARD_PRESENT) {
      return 'Card Present';
    }
    return '';
  }
}
