import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { UserModule } from 'app/layout/common/user/user.module';
import { OnboardingLayoutComponent } from 'app/layout/layouts/onboarding/onboarding.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [OnboardingLayoutComponent],
  imports: [RouterModule, FuseLoadingBarModule, SharedModule, UserModule],
  exports: [OnboardingLayoutComponent],
})
export class OnboardingLayoutModule {}
