/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountCapabilityProcessingDetailDestinationBankAccount } from './accountCapabilityProcessingDetailDestinationBankAccount';


export interface AccountCapabilityProcessingDetail { 
    billing_anchor: string;
    billing_frequency: string;
    billing_interval_count?: number;
    billing_interval_unit?: AccountCapabilityProcessingDetail.BillingIntervalUnitEnum;
    default_statement_descriptor: string;
    destination_bank_account?: AccountCapabilityProcessingDetailDestinationBankAccount;
    funding_hold: boolean;
    funding_hold_reason?: string;
    mcc_code: string;
    mcc_description: string;
    payout_anchor: string;
    payout_frequency: string;
    payout_interval_count?: number;
    payout_interval_unit?: AccountCapabilityProcessingDetail.PayoutIntervalUnitEnum;
    payout_speed: string;
    processing_currency: AccountCapabilityProcessingDetail.ProcessingCurrencyEnum;
    settlement_currency: AccountCapabilityProcessingDetail.SettlementCurrencyEnum;
    supports_3ds: boolean;
    supports_avs: boolean;
    supports_cvd: boolean;
}
export namespace AccountCapabilityProcessingDetail {
    export type BillingIntervalUnitEnum = 'day' | 'week' | 'month';
    export const BillingIntervalUnitEnum = {
        DAY: 'day' as BillingIntervalUnitEnum,
        WEEK: 'week' as BillingIntervalUnitEnum,
        MONTH: 'month' as BillingIntervalUnitEnum
    };
    export type PayoutIntervalUnitEnum = 'day' | 'week' | 'month';
    export const PayoutIntervalUnitEnum = {
        DAY: 'day' as PayoutIntervalUnitEnum,
        WEEK: 'week' as PayoutIntervalUnitEnum,
        MONTH: 'month' as PayoutIntervalUnitEnum
    };
    export type ProcessingCurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const ProcessingCurrencyEnum = {
        AUD: 'aud' as ProcessingCurrencyEnum,
        CAD: 'cad' as ProcessingCurrencyEnum,
        DKK: 'dkk' as ProcessingCurrencyEnum,
        EUR: 'eur' as ProcessingCurrencyEnum,
        HKD: 'hkd' as ProcessingCurrencyEnum,
        JPY: 'jpy' as ProcessingCurrencyEnum,
        NZD: 'nzd' as ProcessingCurrencyEnum,
        NOK: 'nok' as ProcessingCurrencyEnum,
        GBP: 'gbp' as ProcessingCurrencyEnum,
        ZAR: 'zar' as ProcessingCurrencyEnum,
        SEK: 'sek' as ProcessingCurrencyEnum,
        CHF: 'chf' as ProcessingCurrencyEnum,
        USD: 'usd' as ProcessingCurrencyEnum
    };
    export type SettlementCurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const SettlementCurrencyEnum = {
        AUD: 'aud' as SettlementCurrencyEnum,
        CAD: 'cad' as SettlementCurrencyEnum,
        DKK: 'dkk' as SettlementCurrencyEnum,
        EUR: 'eur' as SettlementCurrencyEnum,
        HKD: 'hkd' as SettlementCurrencyEnum,
        JPY: 'jpy' as SettlementCurrencyEnum,
        NZD: 'nzd' as SettlementCurrencyEnum,
        NOK: 'nok' as SettlementCurrencyEnum,
        GBP: 'gbp' as SettlementCurrencyEnum,
        ZAR: 'zar' as SettlementCurrencyEnum,
        SEK: 'sek' as SettlementCurrencyEnum,
        CHF: 'chf' as SettlementCurrencyEnum,
        USD: 'usd' as SettlementCurrencyEnum
    };
}


