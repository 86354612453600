<div>
  <div>
    <div class="pl-3 pb-4">
      <tilled-label-l1 secondary="true">{{ label }}</tilled-label-l1>
    </div>
    <div class="flex h-[33px] w-[198px] items-center justify-center rounded-2xl bg-tilled-neutral-300 pl-2.5">
      <button
        class="-ml-2 h-[90%] w-[102px] rounded-2xl px-4 transition-all duration-400"
        [ngClass]="leftSelect ? 'bg-white' : ''"
        (click)="onToggle()"
      >
        <div>
          <tilled-paragraph-p3> {{ textA }}</tilled-paragraph-p3>
        </div>
      </button>
      <button
        class="-ml-1.5 mr-0.5 h-[90%] w-[102px] rounded-2xl px-4 transition-all duration-400"
        [ngClass]="!leftSelect ? 'bg-white' : ''"
        (click)="onToggle()"
      >
        <div>
          <tilled-paragraph-p3> {{ textB }}</tilled-paragraph-p3>
        </div>
      </button>
    </div>
  </div>
</div>
