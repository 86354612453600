import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tilled-button',
  templateUrl: './tilled-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TilledButtonComponent implements OnInit, OnChanges {
  @Input() buttonText?: string;
  @Input() rounded?: boolean = true;
  @Input() styles?: string;
  @Input() bgColor?: string = 'primary';
  @Input() bgOpacity?: string = '100';
  @Input() textColor?: string = 'white';
  @Input() whiteText?: boolean = true;
  @Input() disabled?: boolean = false;
  @Input() secondary?: boolean = false;
  @Input() disabledTooltip?: string;
  @Input() outlineColor?: string;
  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();

  public displayWhite: boolean;
  public backgroundColor: string;
  public textDisplayColor: string;

  constructor() {}

  ngOnInit() {
    this.checkDisabled();
  }

  ngOnChanges(): void {
    this.checkDisabled();
  }

  checkDisabled() {
    if (this.disabled) {
      this.displayWhite = false;
      this.backgroundColor = 'neutral-300';
    } else {
      this.displayWhite = this.whiteText;
      this.backgroundColor = this.bgColor;
      this.textDisplayColor = this.textColor;
    }
  }

  onButtonClick() {
    this.buttonClick.emit(this.buttonText);
  }
}
