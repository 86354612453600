<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div
  class="fixed left-0 right-0 z-[99999] m-auto w-full max-w-full px-6 sm:w-fit sm:max-w-[50%] sm:px-0"
  [ngClass]="{
    'top-20': !(alert$ | async)?.bottom,
    'bottom-20': (alert$ | async)?.bottom
  }"
>
  <tilled-alert></tilled-alert>
</div>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-accent-700 print:hidden"
  [ngClass]="{
    'text-white': whiteText,
    'text-black': !whiteText
  }"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navItems"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationHeader>
    <!-- Logo -->
    <div
      class="mb-6 flex h-20 items-center pl-7 pr-6"
      [ngClass]="{ 'bg-white': (isWhiteLabel$ | async), 'p-6 pb-0': !(isWhiteLabel$ | async) }"
    >
      <img
        [ngClass]="{ 'w-fit pr-14': !(isWhiteLabel$ | async), 'max-h-[75px] w-fit': (isWhiteLabel$ | async) }"
        src="{{ (darkLogoUrl$ | async) ?? (logoUrl$ | async) }}"
      />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col bg-tilled-neutral-200">
  <!-- Header -->
  <div
    class="relative z-49 flex h-16 w-full flex-0 items-center bg-transparent px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none print:hidden md:px-6"
  >
    <!-- Navigation toggle button -->
    <button data-test-id="logout-nav" class="mr-auto" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon color="primary" [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>

    <!-- Multi-account Selector -->
    <ng-container *ngIf="this.user?.account_roles?.length > 1">
      <app-multi-account-selector class="border-r border-tilled-neutral-300 pr-4"></app-multi-account-selector>
    </ng-container>

    <!-- Components -->
    <ng-container *ngIf="this.account?.type === 'merchant'">
      <notifications></notifications>
    </ng-container>

    <div class="-mt-0.5 flex items-center space-x-0.5 sm:space-x-2">
      <button mat-icon-button (click)="helpCenter.toggle()">
        <mat-icon color="primary" [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
      </button>
    </div>

    <ng-container *ngIf="this.user">
      <user></user>
    </ng-container>
  </div>

  <!-- Content -->
  <div class="flex flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent print:hidden md:px-6"
  >
    <span *ngIf="!(isWhiteLabel$ | async)" class="text-secondary font-medium"
      >{{ companyName }} &copy; {{ currentYear }}</span
    >
  </div>
</div>

<!-- Help center -->
<help-center #helpCenter="helpCenter"></help-center>
