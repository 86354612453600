import { Pipe, PipeTransform } from '@angular/core';
import { AccountStatusPipe } from 'app/core/pipes/account-status.pipe';
import { CapabilityStatusPipe } from 'app/core/pipes/capability-status.pipe';
import { TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { Account } from '../../../../projects/tilled-api-client/src/model/account';

// TODO: Do we need this? It is only used for Account Details page
@Pipe({ name: 'combinedStatus' })
export class CombinedStatusPipe implements PipeTransform {
  transform(account: Account): TilledChipConfig {
    // Apply CapabilityStatusPipe
    const capabilityStatus = this.capabilityStatusPipe.transform(account);

    // Apply AccountStatusPipe
    return this.accountStatusPipe.transform(capabilityStatus, account);
  }

  constructor(private capabilityStatusPipe: CapabilityStatusPipe, private accountStatusPipe: AccountStatusPipe) {}
}
