<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Representatives' : null"
  [description]="
    !forConsole ? 'At least one owner, director, partner, or trustee must be listed as an applicant.' : null
  "
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="representatives-step" class="w-full" [formGroup]="representativesFormArray">
    <div *ngIf="!businessEntityType">
      <merchant-app-alert
        data-test-id="businessTypeAlert"
        alertName="businessTypeWarnAlertBox"
        title="Business type not set"
        description="A business type must be set on the Business Details page before adding
                representatives"
        (editClick)="onEditClicked($event)"
      ></merchant-app-alert>
    </div>
    <div *ngIf="businessEntityType">
      <fuse-alert
        [type]="'basic'"
        [appearance]="'outline'"
        [dismissible]="false"
        [dismissed]="false"
        [name]="'representativeAlertBox'"
      >
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'mat_solid:badge'"> </mat-icon>
        <tilled-heading-h6 fuseAlertTitle>Your business type: {{ businessEntityType.desc }}</tilled-heading-h6>
        <div *ngFor="let req of requirements" class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3>{{ req }}</tilled-paragraph-p3>
        </div>
      </fuse-alert>
    </div>
    <div class="pt-6" *ngFor="let group of representativesFormArray.controls; let i = index" [formGroup]="group">
      <!--Add An Owner-->
      <div
        class="grid grid-cols-5 items-center gap-4"
        [ngClass]="{
          'border-t-2 border-neutral-400 pt-6': i > 0,
          'border-neutral-400': i > 0,
          'pt-6': i > 0
        }"
      >
        <tilled-heading-h4 class="col-span-5">Add a Business Owner</tilled-heading-h4>
        <tilled-paragraph-p3 class="col-span-2">Is this individual the applicant?</tilled-paragraph-p3>
        <mat-radio-group
          data-test-id="reps-applicant"
          class="col-span-3 space-x-4"
          color="primary"
          [formControlName]="'isApplicant'"
        >
          <mat-radio-button [value]="true" (change)="onIsApplicantYes(i)">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
          <mat-icon
            class="icon-size-5"
            style=""
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="It is required to designate one Business Owner as the applicant. Only one owner may be the designated applicant."
          ></mat-icon>
        </mat-radio-group>
        <tilled-paragraph-p3 class="col-span-2">Is this individual a control prong?</tilled-paragraph-p3>
        <mat-radio-group
          data-test-id="reps-control-prong"
          class="col-span-3 space-x-4"
          color="primary"
          [formControlName]="'isControlProng'"
        >
          <mat-radio-button [value]="true" (change)="onIsControlProngYes(i)">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
          <mat-icon
            class="icon-size-5"
            style=""
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The control prong, of which there must be one, is the person with the most control and responsibility over the account (ie: (e.g., a Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice-President, or Treasurer)."
          ></mat-icon>
        </mat-radio-group>
      </div>
      <!--Owner Details-->
      <div class="pt-4">
        <tilled-heading-h5>Owner Details</tilled-heading-h5>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-first-name"
              name="firstName"
              label="FIRST NAME"
              tilledLabel="true"
              errors="{{group.get('firstName').errors?.['required']}}"
              errorMessage="First Name is Required."
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-middle-name"
              name="middleName"
              label="MIDDLE NAME (OPTIONAL)"
              tilledLabel="true"
            ></tilled-input>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-last-name"
              class="w-full"
              name="lastName"
              label="LAST NAME"
              tilledLabel="true"
              errors="{{group.get('lastName').errors?.['required']}}"
              errorMessage="Last Name is Required."
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-phone-number"
              class="w-full"
              name="phone"
              mask="(000) 000-0000"
              label="PHONE NUMBER"
              tilledLabel="true"
              errors="{{ group.get('phone').hasError('invalidPhone') }}"
              errorMessage="Must be a valid phone number."
            ></tilled-input>
          </div>
        </div>

        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-dob"
              name="dob"
              label="DATE OF BIRTH"
              placeholder="MM/DD/YYYY"
              tilledLabel="true"
              mask="M0/d0/0000"
              [dropMaskCharacters]="false"
              [leadZeroDateTime]="true"
              errors="{{ group.get('dob').hasError('younger') || group.get('dob').hasError('mask') }}"
              errorMessage="Business owner must be older than 18 years and have a valid birth year."
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-ssn"
              name="ssn"
              mask="000-00-0000"
              placeholder="{{
                representativesFormArray.value[i].ssn ? 'Already submitted. Click if you need to update SSN' : ''
              }}"
              label="SOCIAL SECURITY NUMBER"
              tilledLabel="true"
              [errors]="group.get('ssn').errors?.['isEqualToList']"
              [errorMessage]="'A valid SSN is required'"
            ></tilled-input>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-job-title"
              name="jobTitle"
              label="JOB TITLE"
              tilledLabel="true"
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-row">
            <div class="pt-8">
              <outline-input
                data-test-id="reps-ownership-percentage"
                label="Ownership %"
                paragraphText="Enter the % amount of the business owned by this individual"
                width="w-24"
                name="percentageShareholding"
                fieldType="number"
                minValue="0"
                maxValue="100"
                maxLength="3"
                [errors]="group.get('percentageShareholding').errors?.['min'] || group.get('percentageShareholding').errors?.['max']"
                [errorMessage]="'Must not be greater than 100%'"
              ></outline-input>
            </div>
          </div>
        </div>
      </div>
      <!--Owner Current Residential Address-->
      <div class="pt-4">
        <tilled-heading-h5>Owner Residential Address</tilled-heading-h5>
        <div class="pt-4">
          <app-autocomplete
            data-test-id="contact-current-street"
            class="w-full"
            name="currentStreet"
            label="STREET ADDRESS"
            tilledLabel="true"
            [autocompleteAddress]="representativesFormArray.value[i].currentStreet"
            (setAddress)="setPrincipalAddress($event, i, 'current')"
          ></app-autocomplete>
        </div>
        <tilled-input
          data-test-id="contact-current-street2"
          class="w-full"
          name="currentStreet2"
          label="STREET ADDRESS 2"
          tilledLabel="true"
        ></tilled-input>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="contact-current-city"
              name="currentCity"
              label="CITY"
              tilledLabel="true"
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-label-l1 secondary="true" class="pt-8">STATE</tilled-label-l1>
            <mat-form-field appearance="standard" class="-mt-4 w-full">
              <mat-select
                data-test-id="contact-current-state"
                class="italic"
                placeholder="Choose One"
                [formControlName]="'currentState'"
              >
                <mat-option *ngFor="let state of selectorTypes.states" [value]="state">{{ state }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="contact-current-zip"
              name="currentZip"
              label="ZIP"
              tilledLabel="true"
              maxLength="10"
            ></tilled-input>
          </div>
          <div *ngIf="isCanadian" class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-years-at-current-address"
              name="yearsAtCurrentAddress"
              label="# OF YEARS AT THIS ADDRESS"
              tilledLabel="true"
              fieldType="number"
              minValue="1"
            ></tilled-input>
          </div>
        </div>
      </div>
      <!--Owner Current Residential Address-->
      <div class="pt-4">
        <div *ngIf="representativesFormArray.value[i].yearsAtCurrentAddress <= 3 && isCanadian">
          <tilled-heading-h5>Owner Previous Residential Address</tilled-heading-h5>
          <div class="pt-4">
            <app-autocomplete
              data-test-id="contact-previous-street"
              class="w-full"
              name="previousStreet"
              label="STREET ADDRESS"
              tilledLabel="true"
              [autocompleteAddress]="representativesFormArray.value[i].previousStreet"
              (setAddress)="setPrincipalAddress($event, i, 'previous')"
            ></app-autocomplete>
          </div>
          <tilled-input
            data-test-id="contact-previous-street2"
            class="w-full"
            name="previousStreet2"
            label="STREET ADDRESS 2"
            tilledLabel="true"
          ></tilled-input>
          <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-previous-city"
                name="previousCity"
                label="CITY"
                tilledLabel="true"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-label-l1 secondary="true" class="pt-8">STATE</tilled-label-l1>
              <mat-form-field appearance="standard" class="-mt-4 w-full">
                <mat-select
                  data-test-id="contact-previous-state"
                  class="italic"
                  placeholder="Choose One"
                  [formControlName]="'previousState'"
                >
                  <mat-option *ngFor="let state of selectorTypes.states" [value]="state">{{ state }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-previous-zip"
                name="previousZip"
                label="ZIP"
                tilledLabel="true"
                maxLength="10"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="reps-years-at-previous-address"
                name="yearsAtPreviousAddress"
                label="# OF YEARS AT THIS ADDRESS"
                tilledLabel="true"
                fieldType="number"
                minValue="1"
              ></tilled-input>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="representativesFormArray.length > 1" class="flex w-full items-end justify-end pb-4">
        <tilled-button
          [whiteText]="false"
          [rounded]="true"
          [secondary]="true"
          (buttonClick)="removePrincipalFormGroup(i)"
          bgColor="tilled-neutral-300"
          [disabled]="disabled$ | async"
          buttonText="Delete"
        ></tilled-button>
      </div>
    </div>
    <tilled-button
      data-test-id="businessRep-addRepBtn"
      *ngIf="representativesFormArray.length < 4"
      class="pt-4"
      bgColor="primary"
      [rounded]="true"
      [disabled]="disabled$ | async"
      buttonText="Add Another"
      (buttonClick)="representativesFormArray.push(getPrincipalFormGroup(null))"
    ></tilled-button>
  </form>
</merchant-app-card>
