import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BusinessRepresentative, InternalAccount } from '../../../../projects/tilled-api-client/src';

@Component({
  selector: 'company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyProfileComponent implements OnInit {
  @Input() account: InternalAccount;

  constructor() {}

  ngOnInit(): void {}

  getRepName(businessRep: BusinessRepresentative): string {
    let nameString = '';
    nameString = businessRep.first_name + ' ';
    if (businessRep.middle_name) {
      nameString += businessRep.middle_name + ' ';
    }
    nameString += businessRep.last_name;

    return nameString;
  }
}
