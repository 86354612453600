import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { mapSubtypeToTitle } from 'app/shared/utils/onboarding-utils';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  Account,
  AccountCapability,
  AccountDocument,
  InternalListConnectedAccountsRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { CapabilityStatusPipe } from '../../core/pipes/capability-status.pipe';
import { AccountAppService } from '../../core/services/account.app.service';
import { ChipColorClass, TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';

@Component({
  selector: 'app-connected-account-list',
  templateUrl: './connected-account-list.component.html',
  styleUrls: ['./connected-account-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConnectedAccountListComponent extends ComponentBase implements OnInit {
  @Input() accountId: string;
  @Input() query: string = null;
  @Input() statuses$: Observable<any>;

  public connectedAccounts$: Observable<Account[]>;
  public connectedAccountsCount$: Observable<number>;
  public viewModels$: Observable<ConnectedAccountViewModel[]>;
  public isLoading = true;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public secondaryReasonText = 'Add a new merchant to see merchant data.';
  public capabilityStatuses: AccountCapability.StatusEnum[] | null;

  constructor(
    private _accountService: AccountAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _capabilityStatus: CapabilityStatusPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.connectedAccounts$ = this._accountService.connectedAccountsPaginated$;
    this.connectedAccountsCount$ = this._accountService.connectedAccountsCount$;
    this.statuses$.pipe(takeUntil(this._unsubscribeAll)).subscribe((statuses) => {
      this.capabilityStatuses = statuses;
    });

    this.viewModels$ = this.connectedAccounts$.pipe(
      map((accounts) => this.getViewModelsFromConnectedAccounts(accounts)),
    );
    this.connectedAccountsCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      if (res === 0) {
        if (this.query) {
          this.secondaryReasonText = 'Update the merchant filter to see results.';
        } else {
          this.secondaryReasonText = 'Add a new merchant to see merchant data.';
        }
      }
    });

    this.getConnectedAccounts(this.pageIndex, this.pageSize);
  }

  getConnectedAccounts = (size: number, index: number, sort?: string): void => {
    const params: InternalListConnectedAccountsRequestParams = {
      tilledAccount: this.accountId,
      offset: index * size,
      limit: size,
      sort: sort,
      q: this.query,
      capabilityStatus: this.capabilityStatuses,
    };
    this._accountService.loadConnectedAccounts(params);
  };

  getViewModelsFromConnectedAccounts(accounts: Account[]): ConnectedAccountViewModel[] {
    const viewModels: ConnectedAccountViewModel[] = [];
    if (!accounts || accounts.length === 0) {
      const temp: ConnectedAccountViewModel = new ConnectedAccountViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const account of accounts) {
      const temp: ConnectedAccountViewModel = new ConnectedAccountViewModel();
      temp.id = account.id;
      temp.name = account.name;

      if (account.capabilities?.length > 0) {
        temp.status = this._capabilityStatus.transform(account);
        temp.chipConfig = this.buildChipStatusConfig(temp.status, account);
        temp.progress =
          temp.status === AccountCapability.StatusEnum.ACTIVE
            ? 100
            : account.capabilities[0].onboarding_application_progress;
        if (
          temp.status === AccountCapability.StatusEnum.CREATED ||
          temp.status === AccountCapability.StatusEnum.STARTED
        ) {
          temp.accountIdForInvitation = account.id;
        }
      } else {
        temp.chipConfig = {
          color: ChipColorClass.OPAQUE_ORANGE,
          text: 'REQUIRES PRICING',
          toolTip: '',
        };
        temp.progress = 0;
        temp.accountIdForInvitation = '';
      }
      temp.created_at = this._dateFormatPipe.transform(account.created_at);

      viewModels.push(temp);
    }
    this.isLoading = false;
    return viewModels;
  }

  buildChipStatusConfig(status: string, account: Account): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_YELLOW,
      text: status,
      toolTip: '',
    };
    if (account.document_requests?.length > 0) {
      const reqDocs = account.document_requests.filter((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED);
      if (reqDocs?.length > 0) {
        chipConfig.color = ChipColorClass.OPAQUE_RED;
        chipConfig.text = 'ACTION REQUIRED';
        const reqDocTypes: string[] = [];
        for (const doc of reqDocs) {
          reqDocTypes.push(mapSubtypeToTitle(doc.subtype));
        }
        chipConfig.requestedDocuments = reqDocTypes;
      } else {
        const subDocs = account.document_requests.filter((doc) => doc.status === AccountDocument.StatusEnum.SUBMITTED);
        if (subDocs?.length > 0) {
          chipConfig.color = ChipColorClass.OPAQUE_PURPLE;
          if (status === AccountCapability.StatusEnum.IN_REVIEW) {
            chipConfig.text = 'IN REVIEW';
          } else {
            chipConfig.text = 'SUBMITTED';
          }
          const subDocTypes: string[] = [];
          for (const doc of subDocs) {
            subDocTypes.push(mapSubtypeToTitle(doc.subtype));
          }
          chipConfig.submittedDocuments = subDocTypes;
        }
      }
    } else if (status === AccountCapability.StatusEnum.ACTIVE) {
      chipConfig.color = ChipColorClass.OPAQUE_GREEN;
      chipConfig.text = 'ACTIVE';
    } else if (status === AccountCapability.StatusEnum.CREATED) {
      chipConfig.color = ChipColorClass.OPAQUE_ORANGE;
      chipConfig.text = 'CREATED';
    } else if (status === AccountCapability.StatusEnum.DISABLED) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'DISABLED';
    } else if (status === AccountCapability.StatusEnum.IN_REVIEW) {
      chipConfig.color = ChipColorClass.OPAQUE_PURPLE;
      chipConfig.text = 'IN REVIEW';
      chipConfig.toolTip =
        'This account is under review by the Underwriting team. No additional documentation is needed at this time.';
    } else if (status === AccountCapability.StatusEnum.REJECTED) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'REJECTED';
    } else if (status === AccountCapability.StatusEnum.STARTED) {
      chipConfig.color = ChipColorClass.OPAQUE_ORANGE;
      chipConfig.text = 'STARTED';
    } else if (status === AccountCapability.StatusEnum.SUBMITTED) {
      chipConfig.color = ChipColorClass.OPAQUE_PURPLE;
      chipConfig.text = 'SUBMITTED';
    } else if (status === AccountCapability.StatusEnum.WITHDRAWN) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'WITHDRAWN';
    }

    return chipConfig;
  }

  rowClickedCallback = (data: ConnectedAccountViewModel): void => {
    if (data && data.id) {
      let path = '';
      path += `/merchants/${data.id}`;
      this._router.navigate([path]);
    }
  };
}

export class ConnectedAccountViewModel {
  id: string;

  @Column({
    order: 0,
    name: 'Created Date',
    canSort: true,
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Name',
    canSort: true,
  })
  name: string;

  // non display, needed if trying to display status chip
  chipConfig: TilledChipConfig;

  @Column({
    order: 2,
    name: 'Application Status',
    isChip: true,
  })
  status: string;

  @Column({
    order: 3,
    name: 'Application Progress',
    isProgress: true,
  })
  progress: number;

  @Column({
    order: 4,
    //name: '',
    isShareLink: true,
  })
  accountIdForInvitation: string;

  @Column({
    order: 5,
    isAction: true,
  })
  action: string;
}
