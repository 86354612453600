import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MetadataDialogComponent } from '../metadata-dialog/metadata-dialog.component';

@Component({
  selector: 'app-metadata-card',
  templateUrl: './metadata-card.component.html',
  styleUrls: ['./metadata-card.component.scss'],
})
export class MetadataCardComponent implements OnInit {
  @Input() metadata: { [key: string]: string };
  @Input() saveMetadataCallback: (metadataForm: FormGroup) => void;
  @Input() disableEdit: boolean = false;
  @Input() disableAdd: boolean = false;

  public openDialogMode: boolean = true;
  public displayView = true;
  public metadataForm: FormGroup;
  items: FormArray;
  public dialogRef: any;
  public editToolTip: string;

  constructor(private _matDialog: MatDialog, private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.editToolTip = this.disableEdit ? 'You do not have sufficient privileges to edit metadata.' : '';
  }

  public editMetadata(): void {
    if (this.openDialogMode) {
      this.openEditDialog();
    } else {
      this.setValuesFromInput();
      this.displayView = false;
    }
  }

  public closeMetadata(response: FormGroup) {
    if (response) {
      this.saveMetadataCallback(response);
    }

    this.displayView = true;
  }

  setValuesFromInput(): void {
    this.items = null;
    if (this.metadataForm) {
      this.metadataForm.reset();
    }
    this.metadataForm = this._formBuilder.group({
      items: this._formBuilder.array([]),
    });
    if (this.metadata) {
      for (const [key, value] of Object.entries(this.metadata)) {
        this.addMetadataItem(key, value);
      }
    }
  }

  addMetadataItem(key = '', value = ''): void {
    this.items = this.metadataForm.get('items') as FormArray;
    this.items.push(this.createMetadataItem(key, value));
  }

  createMetadataItem(k, v): FormGroup {
    return this._formBuilder.group({
      key: [
        {
          value: k,
          disabled: k, // disable input if k is passed in
        },
      ],
      value: v,
    });
  }

  removeMetadataRow(index): void {
    this.items.removeAt(index);
    this._formBuilder.group({ items: this.items });
  }

  private openEditDialog(): void {
    this.dialogRef = this._matDialog.open(MetadataDialogComponent, {
      panelClass: 'meta-data-dialog',
      data: {
        action: 'edit',
        metadata: this.metadata,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      this.saveMetadataCallback(response);
    });
  }
}
