<form [formGroup]="searchForm">
  <mat-form-field
    class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-96 {{ additionalClasses }}"
    [floatLabel]="'always'"
    [appearance]="inputAppearance"
  >
    <mat-icon
      matPrefix
      [ngClass]="{
        'mr-2 icon-size-4': inputAppearance === 'standard'
      }"
      [svgIcon]="'heroicons_solid:search'"
    ></mat-icon>

    <input
      data-test-id="merchant-selector"
      [placeholder]="'Search for merchant'"
      #merchantSelectorInput
      matInput
      [formControlName]="'searchInput'"
      [matAutocomplete]="auto"
      (focus)="onFocus()"
      class="text-secondary text-p3"
    />

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="merchantSelected($event)">
      <div
        *ngIf="merchantSelectorInput.value.length > 2 && !showRecentMerchants"
        class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-56"
      >
        <mat-option class="multiline-mat-option" *ngFor="let merchant of filteredMerchants$ | async" [value]="merchant">
          <div
            class="flex flex-row justify-between py-1"
            matTooltipPosition="right"
            [matTooltip]="merchantTooltip(merchant.id, merchant.business_profile?.representatives[0]?.first_name)"
          >
            <div>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:search'"></mat-icon>
              <tilled-paragraph-p3
                [innerHTML]="merchant.name | matchingStringToBold : merchantSelectorInput.value | truncateString : 20"
              ></tilled-paragraph-p3>
            </div>
            <tilled-paragraph-p3 italics="true">{{
              merchant.business_profile?.address?.street | truncateString : 20
            }}</tilled-paragraph-p3>
          </div>
        </mat-option>
      </div>

      <div *ngIf="showRecentMerchants" class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-56">
        <mat-option
          class="multiline-mat-option"
          *ngFor="let merchant of recentlyViewedMerchants$ | async"
          [value]="merchant"
        >
          <div
            class="flex flex-row content-center justify-between py-1"
            matTooltipPosition="right"
            [matTooltip]="merchantTooltip(merchant.account_id, merchant.owner_name)"
          >
            <div class="flex flex-row content-center justify-start">
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:clock'"></mat-icon>
              <tilled-paragraph-p3>{{ merchant.name | truncateString : 20 }}</tilled-paragraph-p3>
            </div>
            <div class="flex flex-row content-center justify-end">
              <tilled-paragraph-p3 italics="true">{{ merchant.street | truncateString : 20 }}</tilled-paragraph-p3>
              <button
                class="focus:shadow-outline ml-2 inline-flex h-6 w-6 items-center justify-center rounded-full pb-1"
                (click)="$event.stopImmediatePropagation(); deleteRecentMerchant(merchant.account_id)"
              >
                <mat-icon class="ml-4 mt-1 icon-size-5" [svgIcon]="'heroicons_outline:x'"></mat-icon>
              </button>
            </div>
          </div>
        </mat-option>
      </div>
      <mat-option disabled *ngIf="!showRecentMerchants && (filteredMerchants$ | async)?.length == 0">
        No results
      </mat-option>
    </mat-autocomplete>

    <button
      data-test-id="merchant-selector-clear-Btn"
      mat-button
      *ngIf="showClear"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSearchText()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
