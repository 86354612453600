import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { InternalAccount, InternalAccountCapability } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { takeUntil } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';

type AccountPseudoStatus = 'active' | 'disabled' | 'in_progress';

@Component({
  selector: 'app-multi-account-selector',
  templateUrl: './multi-account-selector.component.html',
  styleUrls: ['./multi-account-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiAccountSelectorComponent extends ComponentBase implements OnInit {
  public account: AccountSelectorViewModel;
  public accountGroups: AccountPseudoStatusGroup[];

  constructor(private _authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      this.account = account ? new AccountSelectorViewModel(account) : null;
    });

    this._authService.accounts$.pipe(takeUntil(this._unsubscribeAll)).subscribe((accounts: InternalAccount[]) => {
      const allAccounts = accounts?.map((a) => new AccountSelectorViewModel(a));

      const activeAccounts = allAccounts?.filter((a) => a.status === 'active');
      const disabledAccounts = allAccounts?.filter((a) => a.status === 'disabled');
      const inProgressAccounts = allAccounts?.filter((a) => a.status === 'in_progress');

      const accountGroups: AccountPseudoStatusGroup[] = [];

      if (inProgressAccounts?.length > 0) {
        accountGroups.push({ title: 'In-Progress Applications', status: 'in_progress', accounts: inProgressAccounts });
      }

      if (activeAccounts?.length > 0) {
        accountGroups.push({ title: 'Active Merchants', status: 'active', accounts: activeAccounts });
      }

      if (disabledAccounts?.length > 0) {
        accountGroups.push({ title: 'Disabled Merchants', status: 'disabled', accounts: disabledAccounts });
      }

      this.accountGroups = accountGroups;
    });
  }

  updateAccount(event: MatSelectChange): void {
    const accountId = event.value as string;

    this._authService.setCurrentAccountId(accountId);
  }
}

class AccountSelectorViewModel {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  type: InternalAccount.TypeEnum;
  merchantSupport: boolean;

  status: AccountPseudoStatus;

  constructor(account: InternalAccount) {
    this.id = account.id;
    this.title = account.name;
    this.type = account.type;
    this.merchantSupport = account.merchant_support;

    if (account.business_profile?.address?.street) {
      let addressText = account.business_profile?.address?.street;
      if (account.business_profile?.address?.state || account.business_profile?.address?.postal_code) {
        addressText += ',';
      }

      if (account.business_profile?.address?.state) {
        addressText += ' ' + account.business_profile?.address?.state;
      }

      if (account.business_profile?.address?.postal_code) {
        addressText += ' ' + account.business_profile?.address?.postal_code;
      }

      this.subtitle = addressText;
    } else {
      this.subtitle = account.id;
    }

    let status: AccountPseudoStatus = 'in_progress';
    let icon: 'store' | 'assignment' = 'assignment';

    const inProgressStatuses: InternalAccountCapability.StatusEnum[] = ['created', 'in_review', 'started', 'submitted'];

    const hasActiveCapability = !!account.capabilities?.find((c) => c.status === 'active');
    const hasInProgressCapability = !!account.capabilities?.find((c) => inProgressStatuses.includes(c.status));

    if (hasActiveCapability) {
      status = 'active';
      icon = 'store';
    } else if (hasInProgressCapability) {
      status = 'in_progress';
      icon = 'assignment';
    } else {
      status = 'disabled';
      icon = 'store';
    }

    this.status = status;
    this.icon = icon;
  }
}

class AccountPseudoStatusGroup {
  title: string;
  status: AccountPseudoStatus;
  accounts: AccountSelectorViewModel[];
}
