import { DocumentDto } from '@tilled-api-client';

export function mapSubtypeToTitle(subtype: DocumentDto.SubtypeEnum, requestNotes?: string): string {
  switch (subtype) {
    //file
    case DocumentDto.SubtypeEnum.BANK_LETTER_VOIDED_CHECK:
      return 'Bank Letter or Voided Check';
    case DocumentDto.SubtypeEnum.EIN_LETTER:
      return 'EIN Letter';
    case DocumentDto.SubtypeEnum.ARTICLES_OF_INCORP:
      return 'Articles of Incorporation';
    case DocumentDto.SubtypeEnum.BUSINESS_LICENSE:
      return 'Business License';
    case DocumentDto.SubtypeEnum.UTILITY_BILL:
      return 'Utility Bill';
    case DocumentDto.SubtypeEnum.PROCESSING_STATEMENT:
      return 'Processing Statement (past 3 - 6 months)';
    case DocumentDto.SubtypeEnum.FINANCIAL_STATEMENT:
      return 'Financial Statement (past 3 - 6 months)';
    case DocumentDto.SubtypeEnum._501C3:
      return '501c3';
    case DocumentDto.SubtypeEnum.TAX_RETURN:
      return 'Tax Return for Last Year';
    case DocumentDto.SubtypeEnum.INVOICE:
      return 'Invoice';
    case DocumentDto.SubtypeEnum.DL_PASSPORT:
      return 'Driver’s License/Passport';
    case DocumentDto.SubtypeEnum.DL_BACK:
      return 'Driver’s License - Back side';
    //written
    case DocumentDto.SubtypeEnum.BUSINESS_MODEL:
      return 'Business model details';
    case DocumentDto.SubtypeEnum.PRICING_MODEL:
      return 'Pricing model details';
    case DocumentDto.SubtypeEnum.WEBSITE:
      return 'Website';
    case DocumentDto.SubtypeEnum.SHOPPING_CART:
      return 'No shopping cart';
    case DocumentDto.SubtypeEnum.OTHER:
      if (requestNotes) {
        return 'Other - ' + requestNotes;
      }
      return 'Other';
    default:
      return '';
  }
}
