import { Component, ViewEncapsulation } from '@angular/core';
import { TilledHeadingGeneric } from './tilled-heading-generic.component';

@Component({
  selector: 'tilled-heading-h3',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TilledHeadingH3Component extends TilledHeadingGeneric {
  public styles: string;
  constructor() {
    super();
    this.internalStyles = 'font-bold text-h3';
  }
}
