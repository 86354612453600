import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { OutboundTransfer } from '../../../../projects/tilled-api-client/src';

/**
 * Takes an OutboundTransfer StatusEnum
 */
@Pipe({
  name: 'outboundTransferStatus',
})
export class OutboundTransferStatusPipe implements PipeTransform {
  transform(status: OutboundTransfer.StatusEnum, opaque: boolean = false): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: status.toUpperCase(),
      toolTip: '',
    };
    switch (status) {
      case OutboundTransfer.StatusEnum.CANCELED:
      case OutboundTransfer.StatusEnum.PENDING:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_ACCENT : ChipColorClass.SOLID_ACCENT;
        break;
      case OutboundTransfer.StatusEnum.SUCCEEDED:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_GREEN : ChipColorClass.SOLID_GREEN;
        break;
      case OutboundTransfer.StatusEnum.FAILED:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_RED : ChipColorClass.SOLID_RED;
        break;
      default:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_ACCENT : ChipColorClass.SOLID_ACCENT;
        break;
    }
    return chipConfig;
  }
}
