<div class="top-of-form"></div>
<merchant-app-card
  *ngIf="!canSubmitApplication"
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Submit Application' : null"
  [description]="
    !forConsole
      ? 'Review pricing and terms before submitting your application. If there is any missing information we’ll let you know before submitting.'
      : null
  "
  [backButton]="false"
  [continueButton]="false"
>
  <tilled-heading-h3 class="topOfPage pb-2">Review</tilled-heading-h3>
  <!-- Business Details Alert-->
  <div *ngIf="!businessDetailsComplete.complete" class="pt-6">
    <tilled-heading-h4>Business Details</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessDetailsError"
      alertName="businessDetailsWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessDetailsComplete.missingFields"
      (editClick)="goToStep(businessDetailsStep)"
    ></merchant-app-alert>
  </div>

  <!-- Contact Info Alert-->
  <div *ngIf="!businessContactComplete.complete" class="pt-6">
    <tilled-heading-h4>Contact Info</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessContactError"
      alertName="contactInfoWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessContactComplete.missingFields"
      (editClick)="goToStep(businessContactStep)"
    ></merchant-app-alert>
  </div>

  <!-- Processing Volumes Alert-->
  <div *ngIf="!processingVolumeComplete.complete" class="pt-6">
    <tilled-heading-h4>Processing Volumes</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="processingVolumeError"
      alertName="processingVolumesWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="processingVolumeComplete.missingFields"
      (editClick)="goToStep(processingVolumesStep)"
    ></merchant-app-alert>
  </div>

  <!-- Representatives Alert-->
  <div *ngIf="!businessRepresentativesComplete.complete" class="pt-6">
    <tilled-heading-h4>Representatives</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessRepsError"
      alertName="representativesWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessRepresentativesComplete.missingFields"
      (editClick)="goToStep(representativesStep)"
    ></merchant-app-alert>
  </div>

  <!-- Bank Account Alert-->
  <div *ngIf="!bankAccountComplete.complete" class="pt-6">
    <tilled-heading-h4>Bank Account</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="bankAccountError"
      alertName="bankAccountWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="bankAccountComplete.missingFields"
      (editClick)="goToStep(bankAccountStep)"
    ></merchant-app-alert>
  </div>
</merchant-app-card>

<div [ngClass]="{ '-mt-14': !canSubmitApplication }">
  <merchant-app-card
    [stepNumber]="canSubmitApplication && !forConsole ? this.stepNumber + 1 : null"
    [title]="canSubmitApplication && !forConsole ? 'Submit Application' : null"
    [description]="
      canSubmitApplication && !forConsole
        ? 'Review pricing and terms before submitting your application. If there is any missing information we’ll let you know before submitting.'
        : null
    "
    [backButton]="!forConsole"
    (backClick)="onBackClicked($event)"
    (continueClick)="onContinueClicked($event)"
    continueButtonText="Submit Application"
    [disableContinueButton]="!(canSubmitApplication && termsAgreed) || disableButton || (submittingApp$ | async)"
  >
    <tilled-heading-h3 class="pb-6">Pricing</tilled-heading-h3>
    <div
      class="pb-8"
      [ngClass]="{
        'grid w-full grid-cols-2 gap-16': hasBoth,
        'w-full': !hasBoth
      }"
    >
      <div *ngIf="cardPricingTemplate$ | async as cardPricingTemplate">
        <card-pricing
          data-test-id="card-pricing-details"
          [showCard]="cardPricingTemplate"
          label="CREDIT CARD PRICING"
          [currency]="cardPricingTemplate.currency"
          [feeType]="cardPricingTemplate.fee_type"
          [visaRate]="cardPricingTemplate.visa_rate"
          [amexRate]="cardPricingTemplate.amex_rate"
          [transactionFee]="cardPricingTemplate.transaction_fee"
          [chargebackFee]="cardPricingTemplate.chargeback_fee"
          [retrievalFee]="cardPricingTemplate.retrieval_fee"
          [reversalFee]="cardPricingTemplate.reversal_fee"
          [accountMonthlyFee]="cardPricingTemplate.account_monthly_fee"
          [accountMonthlyMinimumFee]="cardPricingTemplate.account_monthly_minimum_fee"
        ></card-pricing>
      </div>
      <div *ngIf="debitPricingTemplate$ | async as debitPricingTemplate">
        <debit-pricing
          data-test-id="debit-pricing-details"
          [showCard]="debitPricingTemplate"
          label="DIRECT DEBIT PRICING"
          [currency]="debitPricingTemplate.currency"
          [feeType]="debitPricingTemplate.fee_type"
          [transactionFee]="debitPricingTemplate.transaction_fee"
          [returnFee]="debitPricingTemplate.return_fee"
          [accountMonthlyFee]="debitPricingTemplate.account_monthly_fee"
          [accountMonthlyMinimumFee]="debitPricingTemplate.account_monthly_minimum_fee"
        ></debit-pricing>
      </div>
    </div>
    <form data-test-id="submit-application-step" class="w-full" [formGroup]="submitApplicationForm">
      <div class="grid auto-cols-auto grid-flow-col gap-10">
        <mat-checkbox
          data-test-id="checkbox"
          class="pt-1 text-p3"
          [color]="'primary'"
          [formControlName]="'acceptedTerms'"
          (change)="onAgreeTermsClicked($event)"
        >
          I agree
        </mat-checkbox>
        <tilled-paragraph-p1
          >By checking this box and submitting this information you agree to the pricing,
          <a class="text-primary" href="{{ merchantTermsLink }}" target="_blank">Merchant Terms and Conditions</a>,
          <ng-container *ngIf="debitPricingTemplate$ | async">
            <a class="text-primary" href="{{ achDebitTermsLink }}" target="_blank">ACH Terms</a>,
          </ng-container>
          <a class="text-primary" href="{{ portalTermsLink }}" target="_blank">Portal Terms of Use</a>, and
          <a class="text-primary" href="{{ portalTermsLink }}" target="_blank">Privacy Policy</a>. Additionally you
          confirm that the information you have provided is accurate and you are an authorized by your company to enter
          into this agreement
        </tilled-paragraph-p1>
      </div>
      <mat-error
        data-test-id="accept-terms-error"
        class="pt-4 text-md"
        color="warn"
        *ngIf="!submitApplicationForm.get('acceptedTerms').value && submitApplicationForm.get('acceptedTerms').touched"
        >You must accept the Terms and Conditions</mat-error
      >
    </form>
  </merchant-app-card>
</div>
<div *ngIf="displayAlert$ | async" class="grid w-full grid-cols-1 justify-items-start px-8 pb-8">
  <fuse-alert
    [type]="'warn'"
    [appearance]="'outline'"
    [dismissible]="'true'"
    [dismissed]="false"
    [name]="'merchantAppAlertBox'"
  >
    <span fuseAlertTitle>Submitting Application Failed</span>
    {{ alertMessage }}
  </fuse-alert>
</div>
