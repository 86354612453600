<div
  *ngIf="lineItems"
  data-test-id="line-items-table"
  class="flex flex-col"
  [ngClass]="{ 'max-w-fit': currentEditIndex !== -1, 'w-full': currentEditIndex === -1 }"
>
  <!-- List Column Headers-->
  <div class="grid w-full grid-cols-6 gap-x-4">
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Product Code</tilled-label-l1>
    </div>
    <div class="col-span-2 flex flex-col">
      <tilled-label-l1 secondary="true">Product Description</tilled-label-l1>
    </div>
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Quantity</tilled-label-l1>
    </div>
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Unit Amount</tilled-label-l1>
    </div>
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Tax Amount</tilled-label-l1>
    </div>
  </div>
  <mat-divider class="mt-6 w-full border-tilled-neutral-400"></mat-divider>

  <!-- List Data Rows-->
  <ng-container *ngFor="let lineItem of lineItems; let i = index">
    <!-- Display Row -->
    <ng-container *ngIf="currentEditIndex !== i; else editRow">
      <div class="grid w-full grid-cols-6 gap-x-4 pt-6">
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.product_code }}</tilled-label-p3>
        </div>
        <div class="col-span-2 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.product_description }}</tilled-label-p3>
        </div>
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.quantity }}</tilled-label-p3>
        </div>
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.unit_amount }}</tilled-label-p3>
        </div>
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <div class="pr-6">
            <tilled-label-p3>{{ lineItem.tax_amount }}</tilled-label-p3>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="lineItemMenu" class="-mt-7 self-end" [disabled]="editDisabled">
            <mat-icon class="icon-size-5" color="primary" [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
          </button>
          <mat-menu #lineItemMenu="matMenu">
            <button mat-menu-item (click)="editLineItemRow(i)">Edit</button>
            <button mat-menu-item (click)="deleteLineItemRow(i)">Delete</button>
          </mat-menu>
        </div>
        <mat-divider class="col-span-6 flex flex-col border-tilled-neutral-300"></mat-divider>
      </div>
    </ng-container>
    <!-- Editing Row -->
    <ng-template #editRow>
      <form [formGroup]="lineItemForm">
        <div class="grid w-full grid-cols-6 gap-x-4 pt-6">
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <input
              class="lineItemInput text-p3 text-tilled-primary"
              matInput
              formControlName="productCode"
              maxlength="12"
            />
            <div
              class="text-sm text-warn"
              *ngIf="lineItemForm.get('productCode').hasError('required') && lineItemForm.get('productCode').touched"
            >
              Required
            </div>
          </div>
          <div class="col-span-2 -mt-3 mb-1 flex flex-col">
            <input
              class="lineItemInput text-p3 text-tilled-primary"
              matInput
              formControlName="productDescription"
              maxlength="35"
            />
            <div
              class="text-sm text-warn"
              *ngIf="
                lineItemForm.get('productDescription').hasError('required') &&
                lineItemForm.get('productDescription').touched
              "
            >
              Required
            </div>
          </div>
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <input
              class="lineItemInput text-p3 text-tilled-primary"
              matInput
              formControlName="quantity"
              mask="separator"
            />
            <div
              class="text-sm text-warn"
              *ngIf="lineItemForm.get('quantity').hasError('required') && lineItemForm.get('quantity').touched"
            >
              Required
            </div>
          </div>
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <div class="flex">
              <span matPrefix class="-mr-0.5 -mt-0.5 text-p3 text-tilled-primary">$&nbsp;</span>
              <input
                class="lineItemInput text-p3 text-tilled-primary"
                matInput
                formControlName="unitAmount"
                mask="separator.2"
              />
            </div>
            <div
              class="text-sm text-warn"
              *ngIf="lineItemForm.get('unitAmount').hasError('required') && lineItemForm.get('unitAmount').touched"
            >
              Required
            </div>
          </div>
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <div class="pr-8">
              <div class="flex">
                <span matPrefix class="-mr-0.5 -mt-0.5 text-p3 text-tilled-primary">$&nbsp;</span>
                <input
                  class="lineItemInput text-p3 text-tilled-primary"
                  matInput
                  formControlName="taxAmount"
                  mask="separator.2"
                />
              </div>
              <div
                class="text-sm text-warn"
                *ngIf="lineItemForm.get('taxAmount').hasError('required') && lineItemForm.get('taxAmount').touched"
              >
                Required
              </div>
            </div>
            <button mat-icon-button (click)="saveLineItemRow(i)" class="-mt-7 self-end">
              <mat-icon [svgIcon]="'heroicons_solid:check-circle'" class="icon-size-6" color="primary"></mat-icon>
            </button>
          </div>
          <mat-divider class="col-span-6 flex flex-col border-tilled-neutral-300"></mat-divider>
        </div>
      </form>
    </ng-template>
  </ng-container>
  <div class="flex w-full flex-col">
    <button mat-icon-button (click)="addLineItemRow()" class="self-end" [disabled]="editDisabled">
      <mat-icon [svgIcon]="'heroicons_solid:plus'" class="icon-size-6" color="primary"></mat-icon>
    </button>
  </div>
</div>

<div class="w-fulll flex h-fit flex-auto">
  <!-- No templates -->
  <div *ngIf="!lineItems" class="h-fit w-full">
    <!-- Admin no templates-->
    <dashed-line-container>
      <div class="my-[5%] text-center">
        <div class="flex flex-col items-center">
          <tilled-heading-h2 class="mb-2">No line items</tilled-heading-h2>
          <tilled-paragraph-p1 class="mb-10">Minimum 1 line item required</tilled-paragraph-p1>
        </div>
        <tilled-button
          bgColor="primary"
          [rounded]="true"
          [buttonText]="'Add Line Items'"
          (buttonClick)="addFirstLineItem()"
        ></tilled-button>
      </div>
    </dashed-line-container>
  </div>
</div>
