import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FuseAlertService } from '@fuse/components/alert';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'form-card',
  templateUrl: './form-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormCardComponent implements OnInit, OnChanges, OnDestroy {
  @Output() exitClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() nextClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() backClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() centerClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() exitButton?: boolean;
  @Input() nextButton?: string;
  @Input() backButton?: string;
  @Input() submitButton?: string;
  @Input() centerButton?: string;
  @Input() nextDisabled?: boolean = false;
  @Input() submitDisabled?: boolean = false;
  @Input() submitText?: string = 'Submit';
  @Input() buttonPosition: ButtonPositionEnum;
  @Input() displayAlert?: boolean = false;
  @Input() showAlert$?: Observable<boolean>;
  @Input() alertTitle?: string;
  @Input() alertMessage?: string;

  private showAlertSub: Subscription;

  constructor(private _fuseAlertService: FuseAlertService) {}

  ngOnInit() {
    if (this.showAlert$) {
      this.showAlertSub = this.showAlert$.subscribe((res) => {
        if (res) {
          this._fuseAlertService.show('formCardAlertBox');
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alertMessage) {
      this._fuseAlertService.show('formCardAlertBox');
    }
  }

  ngOnDestroy(): void {
    if (this.showAlertSub) {
      this.showAlertSub.unsubscribe();
    }
  }

  showAlert() {
    this._fuseAlertService.show('formCardAlertBox');
  }

  onButtonClick(text) {
    switch (text) {
      case 'exit':
        this.exitClick.emit();
        break;
      case 'next':
        this.nextClick.emit();
        break;
      case 'submit':
        this.submitClick.emit();
        break;
      case 'back':
        this.backClick.emit();
        break;
      case 'center':
        this.centerClick.emit();
        break;
    }
  }
}

export enum ButtonPositionEnum {
  left = 'left',
  center = 'center',
}
