<div class="my-[5px] grid w-full grid-flow-col gap-4">
  <tilled-paragraph-p3 class="justify-self-start" [secondary]="true"
    >{{ labelText }}
    <mat-icon
      *ngIf="labelInfoTooltip"
      class="icon-size-4 align-sub"
      [svgIcon]="'heroicons_solid:information-circle'"
      [matTooltip]="labelInfoTooltip"
    ></mat-icon>
  </tilled-paragraph-p3>

  <ng-container *ngIf="valueInfoTooltip">
    <tilled-paragraph-p3 breakWords="true" class="-mr-5 justify-self-end text-right">
      <a *ngIf="urlValue; else noUrl" class="text-primary" [href]="urlValue">{{ valueText }} </a>
      <ng-template #noUrl>{{ valueText }}</ng-template>
      <mat-icon
        *ngIf="valueInfoTooltip"
        class="icon-size-4 align-sub"
        [svgIcon]="'heroicons_solid:information-circle'"
        [matTooltip]="valueInfoTooltip"
      ></mat-icon>
    </tilled-paragraph-p3>
  </ng-container>
  <!-- We wrap a div around tilled-paragraph-p3 so break-works works properly -->
  <div *ngIf="!valueInfoTooltip">
    <tilled-paragraph-p3 breakWords="true" class="justify-self-end text-right">
      <a *ngIf="urlValue; else noUrl" class="text-primary" [href]="urlValue">{{ valueText }} </a>
      <ng-template #noUrl>{{ valueText }}</ng-template>
    </tilled-paragraph-p3>
  </div>
</div>
