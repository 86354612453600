import { Pipe, PipeTransform } from '@angular/core';
import { BusinessProfile } from '../../../../projects/tilled-api-client/src';

/**
 * Takes a BusinessProfile LocaleEnum
 */
@Pipe({
  name: 'accountLocale',
})
export class AccountLocalePipe implements PipeTransform {
  /**
   *
   * @param locale LocaleEnum of locale
   * @returns
   */
  transform(locale: BusinessProfile.LocaleEnum): string {
    let localeString = 'US - ENG';
    if (locale === BusinessProfile.LocaleEnum.EN_US) {
      localeString = 'US - ENG';
    } else if (locale === BusinessProfile.LocaleEnum.EN_CA) {
      localeString = 'CA - ENG';
    } else if (locale === BusinessProfile.LocaleEnum.FR_CA) {
      localeString = 'CA - FRENCH';
    } else if (locale === BusinessProfile.LocaleEnum.EN_GB) {
      localeString = 'EUROPE - ENG';
    }
    return localeString;
  }
}
