import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ApplicationStep } from 'app/core/models/application-step';

@Component({
  selector: 'isv-app-summary-card',
  templateUrl: './app-summary-card.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PartnerApplicationSummaryCardComponent implements OnInit {
  @Input() appStep?: ApplicationStep;
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();
  constructor() {}

  ngOnInit() {}

  onButtonClick() {
    this.cardClick.emit(this.appStep.order);
  }
}
