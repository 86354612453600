<div class="flex min-w-100 flex-auto flex-col">
  <div class="flex justify-end">
    <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center px-4">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2">Add New Customer</tilled-heading-h2>
      <tilled-paragraph-p3>Enter customer details below.</tilled-paragraph-p3>

      <!-- Form -->
      <ng-container>
        <div class="min-w-100">
          <form [formGroup]="customerForm">
            <mat-form-field floatLabel="always" appearance="standard" class="-mb-4 flex w-full flex-col">
              <tilled-label-l1 secondary="true">FIRST NAME</tilled-label-l1>
              <input
                data-test-id="customer-first-name"
                class="text-secondary pt-4 text-p3"
                matInput
                formControlName="first_name"
                placeholder="Enter first name"
              />
              <mat-error class="text-sm" color="warn" *ngIf="customerForm.get('first_name').hasError('required')"
                >First name is required</mat-error
              >
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="standard" class="-mb-4 flex w-full flex-col">
              <tilled-label-l1 secondary="true">MIDDLE NAME</tilled-label-l1>
              <input
                data-test-id="customer-middle-name"
                class="text-secondary pt-4 text-p3"
                matInput
                formControlName="middle_name"
                placeholder="Enter middle name"
              />
            </mat-form-field>
            <tilled-paragraph-p4 class="italic">Optional</tilled-paragraph-p4>
            <mat-form-field floatLabel="always" appearance="standard" class="-mb-4 flex w-full flex-col">
              <tilled-label-l1 secondary="true">LAST NAME</tilled-label-l1>
              <input
                data-test-id="customer-last-name"
                class="text-secondary pt-4 text-p3"
                matInput
                formControlName="last_name"
                placeholder="Enter last name"
              />
              <mat-error class="text-sm" color="warn" *ngIf="customerForm.get('last_name').hasError('required')"
                >Last name is required</mat-error
              >
            </mat-form-field>

            <mat-form-field floatLabel="always" appearance="standard" class="-mb-4 flex w-full flex-col">
              <tilled-label-l1 secondary="true">EMAIL</tilled-label-l1>
              <input
                data-test-id="customer-email"
                class="text-secondary pt-4 text-p3"
                matInput
                formControlName="email"
                placeholder="Enter email"
              />

              <mat-error class="text-sm" color="warn" *ngIf="customerForm.get('email').hasError('email')"
                >Email invalid</mat-error
              >
            </mat-form-field>
            <tilled-paragraph-p4 class="italic">Optional</tilled-paragraph-p4>
            <mat-form-field floatLabel="always" appearance="standard" class="-mb-4 flex w-full flex-col">
              <tilled-label-l1 secondary="true">PHONE</tilled-label-l1>
              <input
                data-test-id="customer-phone-number"
                class="text-secondary pt-4 text-p3"
                matInput
                formControlName="phone"
                placeholder="Enter phone number"
              />

              <mat-error class="text-sm" color="warn" *ngIf="customerForm.get('phone').hasError('phone')"
                >Phone number invalid</mat-error
              >
            </mat-form-field>
            <tilled-paragraph-p4 class="italic">Optional</tilled-paragraph-p4>
          </form>
        </div>
      </ng-container>
    </div>

    <!-- Add Actions -->
    <div class="grid w-full justify-items-end">
      <!-- Save -->
      <tilled-button
        [disabled]="!customerForm.valid"
        data-test-id="add-customer-save-Btn"
        bgColor="primary"
        [rounded]="true"
        buttonText="Add Customer"
        (buttonClick)="addCustomerClicked()"
        [whiteText]="true"
      ></tilled-button>
    </div>
  </ng-container>
</div>
