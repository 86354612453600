<div class="max-h-[100vh] w-fit overflow-y-auto px-4 sm:min-w-120">
  <form-card
    (exitClick)="closeDialog()"
    [backButton]="false"
    [submitButton]="true"
    (submitClick)="generatePaymentLink()"
    [submitDisabled]="paymentLinkForm.invalid || (creatingPaymentLink$ | async)"
    submitText="Generate Payment Link"
  >
    <form [formGroup]="paymentLinkForm">
      <div data-test-id="modal-header" class="grid w-full justify-items-center pb-2 text-center">
        <!-- Title -->
        <tilled-heading-h2 class="pb-2">Create a Payment Link</tilled-heading-h2>
        <tilled-paragraph-p3 class="pb-6"
          >Enter payment details below to generate a unique payment link.</tilled-paragraph-p3
        >
      </div>
      <div>
        <ng-container *ngIf="!isMerchant">
          <div class="w-full pt-8">
            <tilled-label-l1 secondary="true">Merchant</tilled-label-l1>
          </div>
          <app-merchant-selector
            class="-mt-3"
            inputAppearance="standard"
            additionalClasses="w-full"
            (setMerchantAccount)="parseAccountData($event)"
          >
          </app-merchant-selector>
        </ng-container>
        <div *ngIf="!disableCurrency && availableCurrencies">
          <tilled-label-l1 secondary="true">Currency</tilled-label-l1>
          <mat-form-field floatLabel="always" appearance="standard" class="-mt-4 w-full pb-4">
            <mat-select
              class="text-secondary text-p3"
              formControlName="currency"
              [(value)]="selectedCurrency"
              placeholder="Select a currency"
              data-test-id="currency"
            >
              <mat-option *ngFor="let currency of availableCurrencies | keyvalue" [value]="currency.key">
                {{ currency.value }}
              </mat-option>
            </mat-select>
            <mat-error class="text-sm" color="warn" *ngIf="paymentLinkForm.get('currency').hasError('required')"
              >A currency is required</mat-error
            >
          </mat-form-field>
        </div>

        <div *ngIf="availablePaymentMethods" class="flex flex-col" formGroupName="paymentMethodCheckboxGroup">
          <tilled-label-l1 secondary="true" class="pb-3">Payment Options</tilled-label-l1>
          <mat-checkbox
            [ngClass]="disableCard ? 'hidden' : ''"
            data-test-id="card-checkbox"
            class="pb-2 text-p3"
            [color]="'primary'"
            [formControlName]="'cardCheckbox'"
          >
            <tilled-paragraph-p3>Credit and debit card</tilled-paragraph-p3>
          </mat-checkbox>
          <mat-checkbox
            [ngClass]="disableAch ? 'hidden' : ''"
            data-test-id="ach-checkbox"
            class="pb-2 text-p3"
            [color]="'primary'"
            [formControlName]="'achCheckbox'"
          >
            <tilled-paragraph-p3>ACH debit</tilled-paragraph-p3>
          </mat-checkbox>
          <mat-checkbox
            [ngClass]="disableEft ? 'hidden' : ''"
            data-test-id="eft-checkbox"
            class="pb-2 text-p3"
            [color]="'primary'"
            [formControlName]="'eftCheckbox'"
          >
            <tilled-paragraph-p3>EFT debit</tilled-paragraph-p3>
          </mat-checkbox>
          <mat-error
            class="text-sm"
            color="warn"
            *ngIf="paymentLinkForm.get('paymentMethodCheckboxGroup').hasError('requiresCheckbox')"
          >
            At least one payment method is required</mat-error
          >
        </div>

        <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col pb-2">
          <tilled-label-l1 secondary="true">Payment Description</tilled-label-l1>
          <input
            data-test-id="payment-description"
            class="pt-4 text-p3 text-tilled-primary"
            matInput
            formControlName="description"
            placeholder="Enter the name of the product or service"
          />
          <mat-error class="text-sm" color="warn" *ngIf="paymentLinkForm.get('description').hasError('required')"
            >A payment description is required</mat-error
          >
        </mat-form-field>
        <div class="-mb-4">
          <outline-input
            name="amount"
            showCurrency="'true'"
            [errors]="paymentLinkForm.get('amount').hasError('required') && paymentLinkForm.get('amount').touched"
            label="Amount"
            inputTextStyle="mb-2 text-tilled-primary py-1"
            mask="separator.2"
            data-test-id="payment-amount"
          >
            <div class="w-full text-sm text-warn sm:pt-8" *ngIf="paymentLinkForm.get('amount').invalid">
              A valid payment amount is required
            </div>
          </outline-input>
          <tilled-paragraph-p3 [neutral]="true" class="relative -top-4 sm:-top-10">
            Enter amount for the product or service</tilled-paragraph-p3
          >
        </div>
      </div>
    </form>
  </form-card>
</div>
