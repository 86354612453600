import { NgModule } from '@angular/core';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { SharedModule } from 'app/shared/shared.module';
import { HelpCenterComponent } from './help-center.component';

@NgModule({
  declarations: [HelpCenterComponent],
  imports: [FuseScrollbarModule, SharedModule],
  exports: [HelpCenterComponent],
})
export class HelpCenterModule {}
