import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tilled-switch',
  templateUrl: './tilled-switch.component.html',
  styleUrls: ['./tilled-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TilledSwitchComponent implements OnInit {
  @Output() onSwitch: EventEmitter<string> = new EventEmitter<string>();

  @Input() label: string;
  @Input() textA: string;
  @Input() textB: string;
  @Input() selected: string;
  public leftSelect: boolean = false;

  constructor() {}

  ngOnInit() {
    if (!this.selected) {
      this.selected = this.textA;
    }

    this.leftSelect = this.selected === this.textA;
  }

  onToggle() {
    if (this.selected === this.textA) {
      this.onSwitch.emit(this.textB);
      this.leftSelect = false;
      this.selected = this.textB;
    } else {
      this.onSwitch.emit(this.textA);
      this.leftSelect = true;
      this.selected = this.textA;
    }
  }
}
