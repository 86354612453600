<div class="flex min-w-100 flex-auto flex-col">
  <div class="flex justify-end pt-2 pr-2">
    <mat-icon style="cursor: pointer" (click)="closeDialog(true)" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <div class="w-full px-4 pb-2">
    <div class="mb-4 flex w-full flex-col items-center px-8">
      <!-- Title -->
      <tilled-heading-h2 class="pb-6">{{ title }}</tilled-heading-h2>
      <!-- Content -->
      <tilled-paragraph-p3>{{ text }} </tilled-paragraph-p3>
    </div>

    <!-- Add Actions -->
    <div class="flex w-full justify-end space-x-4 px-8 pt-8">
      <tilled-button
        data-test-id="leave-button"
        buttonText="{{ leaveButtonText }}"
        bgColor="warn"
        whiteText="true"
        (buttonClick)="closeDialog(false)"
      ></tilled-button>
      <tilled-button
        data-test-id="stay-button"
        buttonText="{{ stayButtonText }}"
        bgColor="primary"
        (buttonClick)="closeDialog(true)"
      ></tilled-button>
    </div>
  </div>
</div>
