<div class="flex flex-col flex-auto min-w-120">
  <div class="flex justify-end">
    <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center pb-8">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2">Initiate a Refund</tilled-heading-h2>
      <tilled-paragraph-p3>Provide refund details below.</tilled-paragraph-p3>
    </div>
    <!-- Form -->
    <form [formGroup]="refundForm" class="">
      <div class="px-8">
        <!-- Amount Start -->
        <outline-input
          name="amount"
          showCurrency="'true'"
          label="Refund Amount"
          inputTextStyle="mb-2 text-tilled-primary py-1"
          mask="separator.2"
          data-test-id="refund-amount"
        >
        </outline-input>
        <div class="-mt-11">
          <tilled-paragraph-p3>Maximum refund amount is {{ maxRefund | minorUnitsToCurrency }}</tilled-paragraph-p3>
        </div>
        <div
          class="text-sm text-warn"
          *ngIf="refundForm.get('amount').hasError('required') && refundForm.get('amount').touched; else hasValue"
        >
          A refund amount is required
        </div>
        <ng-template #hasValue>
          <div
            class="text-sm text-warn"
            *ngIf="refundForm.get('amount').hasError('max') && refundForm.get('amount').touched; else checkZero"
          >
            Cannot exceed the maximum refund amount
          </div>
        </ng-template>
        <ng-template #checkZero>
          <div
            class="text-sm text-warn"
            *ngIf="refundForm.get('amount').hasError('min') && refundForm.get('amount').touched; else noError"
          >
            Refund amount must be greater than 0
          </div>
        </ng-template>
        <ng-template #noError>
          <div class="text-sm">&nbsp;</div>
        </ng-template>
        <!-- Amount End -->

        <!-- Reason Start-->
        <div>
          <mat-form-field floatLabel="always" appearance="standard" class="w-full -mt-4 pb-4">
            <tilled-label-l1 secondary="true" class="">Refund Reason</tilled-label-l1>
            <mat-select
              class="text-p3 text-secondary pt-4"
              formControlName="reason"
              placeholder="Select a refund reason"
              data-test-id="reason"
            >
              <mat-option value="duplicate">Duplicate</mat-option>
              <mat-option value="fraudulent">Fraudulent</mat-option>
              <mat-option value="requested_by_customer">Requested by customer</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Reason End -->

        <!-- Platform Fee Start -->
        <div *ngIf="!isMerchant && payment.platform_fee_amount">
          <mat-slide-toggle color="primary" (change)="togglePlatformFeeRefund()" data-test-id="platform-fee-refund">
            <tilled-paragraph-p3>Refund platform fee</tilled-paragraph-p3>
          </mat-slide-toggle>
          <div class="flex flex-row pt-1">
            <mat-icon
              class="-mt-1 -ml-0.5 text-tilled-secondary"
              style="font-size: 4px; transform: scale(0.7)"
              svgIcon="heroicons_solid:exclamation-circle"
            >
            </mat-icon>
            <tilled-paragraph-p4 secondary="'true'"
              >Platform fee will be refunded in proportion to the refund amount</tilled-paragraph-p4
            >
          </div>
        </div>
        <!-- Platform Fee End -->

        <!-- Payment Method -->
        <div *ngIf="requireNewPaymentMethod" class="grid -mt-4">
          <div data-test-id="credit-card-header" class="grid min-w-fit justify-items-center pb-10">
            <fuse-alert [type]="'info'" [appearance]="'soft'" [dismissible]="false" [dismissed]="false">
              A refund of this payment cannot be automatically applied to the original card. Enter the customer's
              payment method information.
            </fuse-alert>
          </div>
          <div class="flex flex-col">
            <div class="grid grid-cols-2 w-[full] -mt-4">
              <div class="flex flex-col col-span-1 w-[210px]">
                <div class="flex flex-col w-full">
                  <tilled-label-l1 secondary="true" class="pb-1.5">Credit Card Number</tilled-label-l1>
                  <div class="flex flex-row">
                    <div class="inputField" id="card-number-element"></div>
                    <div id="card-suffix" class="flex w-[40px] h-[40px] items-center -ml-10">
                      <mat-icon>credit_card</mat-icon>
                    </div>
                  </div>
                  <div class="text-sm text-warn pt-1" *ngIf="showCardNumberError; else noCardNumberError" @fadeIn>
                    A valid card number is required
                  </div>
                  <ng-template #noCardNumberError>
                    <div class="text-sm pt-1">&nbsp;</div>
                  </ng-template>
                </div>
              </div>
              <div class="flex flex-col col-span-1 w-[150px] justify-self-end">
                <div class="flex flex-col w-full">
                  <tilled-label-l1 secondary="true" class="pb-1.5">Expiration</tilled-label-l1>
                  <div class="inputField" id="card-expiration-element"></div>
                  <div class="text-sm text-warn pt-1" *ngIf="showExpirationError; else noExpirationError" @fadeIn>
                    A valid date is required
                  </div>
                  <ng-template #noExpirationError>
                    <div class="text-sm pt-1">&nbsp;</div>
                  </ng-template>
                </div>
              </div>
              <div class="flex flex-col col-span-1 w-[170px]">
                <div class="flex flex-col w-full">
                  <tilled-label-l1 secondary="true" class="pb-1.5">CVV</tilled-label-l1>
                  <div class="inputField" id="card-cvv-element"></div>
                  <div class="text-sm text-warn pt-1" *ngIf="showCvvError; else noCvvError" @fadeIn>Required</div>
                  <ng-template #noCvvError>
                    <div class="text-sm pt-1">&nbsp;</div>
                  </ng-template>
                </div>
              </div>
              <div class="flex flex-col col-span-1 w-[150px] justify-self-end -mt-8">
                <mat-form-field floatLabel="always" appearance="standard" class="flex flex-col w-full">
                  <tilled-label-l1 secondary="true">Billing Zip</tilled-label-l1>
                  <input
                    data-test-id="billing-zip"
                    class="text-p3 text-tilled-primary pt-4"
                    matInput
                    formControlName="postalCode"
                    placeholder="12345"
                    maxlength="10"
                  />
                  <mat-error class="text-sm" color="warn" *ngIf="refundForm.get('postalCode').hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="flex flex-col col-span-2 w-[full] -mt-8">
                <mat-form-field floatLabel="always" appearance="standard" class="flex flex-col w-full">
                  <tilled-label-l1 secondary="true">Name on Card</tilled-label-l1>
                  <input
                    data-test-id="cardholder-name"
                    class="text-p3 text-tilled-primary pt-4"
                    matInput
                    formControlName="cardholderName"
                    placeholder=""
                  />
                  <mat-error class="text-sm" color="warn" *ngIf="refundForm.get('cardholderName').hasError('required')"
                    >A name on card is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <!-- end of Card Info 4 col grid-->
          </div>
        </div>
        <!-- Payment Method -->
      </div>
    </form>

    <div *ngIf="displayAlert$ | async" class="flex justify-start w-full px-8 pt-2">
      <fuse-alert
        [type]="alertType"
        [appearance]="'outline'"
        [dismissible]="alertDismissible"
        [dismissed]="false"
        [name]="'refundDialogAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>

    <div class="flex justify-end w-full px-8 pt-8">
      <!-- Cancel Button -->
      <tilled-button
        data-test-id="canel-refund-Btn"
        bgColor="white"
        styles="text-tilled-secondary"
        [whiteText]="false"
        [rounded]="true"
        buttonText="Cancel"
        (buttonClick)="closeDialog()"
      ></tilled-button>

      <!-- Submit Button -->
      <tilled-button
        data-test-id="submit-refund-Btn"
        bgColor="primary"
        [rounded]="true"
        buttonText="Submit Refund"
        (buttonClick)="submitRefundClicked()"
        [whiteText]="true"
        [disabled]="activelyRefunding || refundForm.invalid || (requireNewPaymentMethod && tilledJsCardForm?.invalid)"
      ></tilled-button>
    </div>
  </ng-container>
</div>
