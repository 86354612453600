import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { InternalAccount, InternalService } from '@tilled-api-client';
import { AuthService } from 'app/core/services/auth.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'helpCenter',
})
export class HelpCenterComponent implements OnInit, OnDestroy {
  opened: boolean = false;

  public merchantSupportCenterName: string;
  public merchantSupportCenterUrl: string;
  public supportPhone: string;
  public supportPhoneLink: string;
  public supportEmail: string;
  public supportEmailLink: string;

  public showPhone = true;
  public showEmail = true;
  public showTilledHours = false;
  public showPartnerHelpCenterLink = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
  private _overlay: HTMLElement;

  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    private _authService: AuthService,
    private _internalService: InternalService,
  ) {}

  ngOnInit(): void {
    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: async (account) => {
        this._internalService.internalGetAccountSettingsBranding({ tilledAccount: account.id }).subscribe((asb) => {
          // defaults
          this.showEmail = true;
          this.supportEmail = 'support@tilled.com';
          this.supportEmailLink = 'support@tilled.com';
          this.showPhone = true;
          this.supportPhoneLink = '8772790010';
          this.showTilledHours = true;
          this.showPartnerHelpCenterLink = account?.type === InternalAccount.TypeEnum.PARTNER;
          this.merchantSupportCenterName = 'Merchant Help Center';
          this.merchantSupportCenterUrl = 'https://paymentsonline.zendesk.com/hc/en-us';

          // customize for white labeled merchant
          if (asb && asb.is_white_label && account?.type === InternalAccount.TypeEnum.MERCHANT) {
            this.showEmail = asb.support_email != null;
            if (this.showEmail) {
              this.supportEmailLink = asb.support_email;
              if (asb.support_email.length > 30) {
                this.supportEmail = 'Email Support';
              } else {
                this.supportEmail = asb.support_email;
              }
            }
            this.showPhone = asb.support_phone != null;
            this.supportPhoneLink = asb.support_phone;
            this.showTilledHours = false;
            if (asb.merchant_support_center_name) {
              this.merchantSupportCenterName = asb.merchant_support_center_name;
            }
            if (asb.merchant_support_center_url) {
              this.merchantSupportCenterUrl = asb.merchant_support_center_url;
            }
          }
          if (this.showPhone) {
            this.supportPhone = this.supportPhoneLink.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          }
        });
      },
    });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Decorated methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'help-center-opened': this.opened,
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the panel
   */
  open(): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }

    // Open the panel
    this._toggleOpened(true);
  }

  /**
   * Close the panel
   */
  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }

    // Close the panel
    this._toggleOpened(false);
  }

  /**
   * Toggle the panel
   */
  toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the backdrop
   *
   * @private
   */
  private _showOverlay(): void {
    // Try hiding the overlay in case there is one already opened
    this._hideOverlay();

    // Create the backdrop element
    this._overlay = this._renderer2.createElement('div');

    // Return if overlay couldn't be created for some reason
    if (!this._overlay) {
      return;
    }

    // Add a class to the backdrop element
    this._overlay.classList.add('help-center-overlay');

    // Append the backdrop to the parent of the panel
    this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

    // Enable block scroll strategy
    this._scrollStrategy.enable();

    // Add an event listener to the overlay
    this._overlay.addEventListener('click', () => {
      this.close();
    });
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _hideOverlay(): void {
    if (!this._overlay) {
      return;
    }

    // If the backdrop still exists...
    if (this._overlay) {
      // Remove the backdrop
      this._overlay.parentNode.removeChild(this._overlay);
      this._overlay = null;
    }

    // Disable block scroll strategy
    this._scrollStrategy.disable();
  }

  /**
   * Open/close the panel
   *
   * @param open
   * @private
   */
  private _toggleOpened(open: boolean): void {
    // Set the opened
    this.opened = open;

    // If the panel opens, show the overlay
    if (open) {
      this._showOverlay();
    }
    // Otherwise, hide the overlay
    else {
      this._hideOverlay();
    }
  }
}
