// TODO: these will change depending on new TSYS/Paysafe app designs
// these are mainly used for navigating from the submit step to the correct step number
export enum MerchantApplicationStepType {
  BUSINESS_DETAILS = 'business_details',
  BUSINESS_CONTACT = 'business_contact',
  PROCESSING_VOLUMES = 'processing_volumes',
  REPRESENTATIVES = 'representatives',
  BANK_ACCOUNT = 'bank_account',
  SUBMIT = 'submit',
  OTHER = 'other', // placeholder for new steps
}

export enum PaysafeApplicationSteps {
  BUSINESS_DETAILS_STEP = 0,
  BUSINESS_CONTACT_STEP = 1,
  PROCESSING_VOLUMES_STEP = 2,
  REPRESENTATIVES_STEP = 3,
  BANK_ACCOUNT_STEP = 4,
  SUBMIT_STEP = 5,
}

export enum TsysApplicationSteps {
  WELCOME_STEP = 0,
  VERIFY_BUSINESS_STEP = 1,
  SUB_BUSINESS_DETAILS_STEP = 2,
  SUB_BUSINESS_CONTACT_STEP = 3,
  SUB_TAX_REGISTRATION_STEP = 4,
  SUB_PAYMENT_STEP = 5,
  SUB_PROCESSING_VOLUMES_STEP = 6,
  SUB_REPRESENTATIVES_STEP = 7,
  BANK_ACCOUNT_STEP = 8,
  DOCUMENTS_STEP = 9,
  SUBMIT_STEP = 10,
}

export const MerchantAppMissingFields = {
  BusinessDetailsStep: {
    LEGAL_NAME: 'Legal Business Name',
    DBA: 'Doing Business As',
    ENTITY_TYPE: 'Business Entity Type',
    BUSINESS_ID: 'Business Identifier',
    INDUSTRY: 'Industry',
    STATEMENT_DESCRIPTOR: 'Statement Descriptor',
    DESCRIPTION: 'Product and Services Description',
    COUNTRY_OF_INC: 'Country of Incorporation',
    YEAR_OF_INC: 'Year of Incorporation',
  },
  ContactInfoStep: {
    PHONE: 'Customer Support Phone Number',
    EMAIL: 'Customer Support Email',
    WEBSITE: 'Company Website',
    STREET: 'Legal Business Street Address',
    CITY: 'City',
    STATE: 'State',
    ZIP: 'Zip',
  },
  VolumesStep: {
    YEARLY_VOLUME: 'Annual Volume Processing Range',
    AVERAGE_AMOUNT: 'Average Transaction Amount',
    AVERAGE_TRX_PER_MONTH: 'Average Transactions Per Month',
    YEARLY_REVENUE: 'Annual Revenue',
  },
  RepresentativesStep: {
    NO_PRINCIPALS: 'Need at least one Business Representative',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    SSN: 'Social Security Number',
    PHONE: 'Phone Number',
    DOB: 'Date of Birth',
    JOB_TITLE: 'Job Title',
    OWNERSHIP: 'Ownership Percent',
    STREET: 'Street Address',
    CITY: 'City',
    STATE: 'State',
    ZIP: 'Zip',
    PREV_STREET: 'Previous Street Address',
    PREV_CITY: 'Previous City',
    PREV_STATE: 'Previous State',
    PREV_ZIP: 'Previous Zip',
    APPLICANT: 'One Business Representative must be marked as Applicant',
    CONTROL_PRONG: 'One Business Representative must be marked as Control Prong',
  },
  BankAccountStep: {
    ACCOUNT_NUMBER: 'Account Number',
    ROUTING_NUMBER: 'Routing Number',
    ROUTING_NUMBER_CA: 'Transit Number and Institution ID',
    TYPE: 'Account Type',
    ACCOUNT_HOLDER_NAME: 'Account Holder or Business Name',
    BANK_NAME: 'Bank Name',
    DOC_UPLOAD: 'Upload a Bank Letter or Voided Check',
  },
};
