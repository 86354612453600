import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
const generatePalette = require('@fuse/tailwind/utils/generate-palette');
const chroma = require('chroma-js');
const demo = require('../../../assets/js/asset-load.js').demo;

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  public logoUrl$: Observable<string>;
  public darkLogoUrl$: Observable<string>;
  public isWhiteLabel$: Observable<boolean>;
  private _primaryColor$ = new BehaviorSubject<string>(null);
  public primaryColor$ = this._primaryColor$.asObservable();
  private _secondaryColor$ = new BehaviorSubject<string>(null);
  public secondaryColor$ = this._secondaryColor$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _http: HttpClient,
  ) {
    this.isWhiteLabel$ = this._fuseConfigService.config$.pipe(
      map((config) => {
        const logoUrl = config['logoUrl'] as string;
        try {
          if (logoUrl && logoUrl.includes('http')) {
            return true;
          } else {
            return false;
          }
        } catch (err) {}
      }),
    );

    this.logoUrl$ = this._fuseConfigService.config$.pipe(
      map((config) => {
        try {
          const logoUrl = config['logoUrl'] as string;
          if (logoUrl) {
            if (logoUrl.includes('http')) {
              return logoUrl;
            }
            return demo.assetsLogoPath + demo.partnerLightLogo;
          } else {
            return null;
          }
        } catch (err) {}
      }),
    );

    this.darkLogoUrl$ = this._fuseConfigService.config$.pipe(
      map((config) => {
        try {
          const logoUrl = config['logoUrl'] as string;
          if (logoUrl) {
            return logoUrl;
          } else {
            return null;
          }
        } catch (err) {}
      }),
    );
  }

  public setBranding(): void {
    const hostname = new URL(window.location.href).hostname;
    const apiUrl = `${environment.api}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const getRequest = this._http.get(`${apiUrl}/v1/white-label-domains/${encodeURIComponent(hostname)}`, {
      headers: headers,
    });
    getRequest.subscribe(
      (response) => {
        if (response && response['logo_url'] != '') {
          this.setWhiteLabelBranding(
            response['logo_url'],
            response['primary_color'],
            response['secondary_color'],
            response['account_name'],
          );
        } else {
          this.setDefaultBranding();
        }
      },
      (error) => {
        // set the default branding if something bad happend
        this.setDefaultBranding();
      },
    );
  }

  // NOTE: we should probably use these calculations in more shared components
  // to determine text colors with lighter backgrounds to follow W3C Accessibility Standards
  public determineWhiteTextColorFromBgColor(bgHex: string): boolean {
    const color = bgHex.charAt(0) === '#' ? bgHex.substring(1, 7) : bgHex;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const colors = [r / 255, g / 255, b / 255];
    const c = colors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? false : true;
  }

  private setDefaultBranding(): void {
    // default favicon
    const link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
    link.href = demo.assetsLogoPath + demo.partnerFaviconLogo;

    // default logo
    this._fuseConfigService.config = {
      logoUrl: demo.assetsLogoPath + demo.partnerDarkLogo,
    };

    // default title
    this.document.title = demo.partnerName;

    // set text color to black
    this.setDemoTextBlack();
  }

  private setWhiteLabelBranding(logoUrl: string, primaryColor: string, accentColor: string, title: string): void {
    if (title) {
      this.document.title = title;
    }

    if (logoUrl) {
      this._fuseConfigService.config = { logoUrl: logoUrl };
    }

    if (primaryColor) {
      this._primaryColor$.next(primaryColor);
    }

    if (accentColor) {
      this._secondaryColor$.next(accentColor);
    }

    // remove favicon
    const link = document.querySelector(`link[rel='icon']`) as HTMLLinkElement;
    link.href = 'data:;base64,=';

    const sheets = document.styleSheets;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let c = 0; c < sheets.length; c++) {
      // find the right stylesheet
      if (sheets[c].href && sheets[c].href.includes('styles') && sheets[c].cssRules.length > 200) {
        const sheet = sheets[c];
        const rules = sheets[c].cssRules;
        for (let r = 0; r < rules.length; r++) {
          if (rules[r].cssText.includes('body, .theme-default')) {
            if (primaryColor) {
              this.replaceBrandingColors(sheet, r, rules[r].cssText, primaryColor, true);
            }
            if (accentColor) {
              this.replaceBrandingColors(sheet, r, rules[r].cssText, accentColor, false);
            }
          }
          if (rules[r].cssText.includes('.text-primary {')) {
            this.replaceLinkTextColor(sheet, r);
          }
          if (rules[r].cssText.includes('.outline-primary {')) {
            this.replaceOutlineColor(sheet, r);
          }
          if (rules[r].cssText.includes('.text-tilled-primary {')) {
            this.replacePrimaryTextColor(sheet, r);
          }
          if (rules[r].cssText.includes('.bg-primary {')) {
            this.replacePrimaryBackground(sheet, r);
          }
        }
      }
    }
  }

  private setDemoTextBlack() {
    const sheets = document.styleSheets;
    for (var c = 0; c < sheets.length; c++) {
      // find the right stylesheet
      if (sheets[c].href && sheets[c].href.includes('styles') && sheets[c].cssRules.length > 200) {
        const sheet = sheets[c];
        var rules = sheets[c].cssRules;
        for (var r = 0; r < rules.length; r++) {
          if (rules[r].cssText.includes('.text-tilled-primary {')) {
            this.replacePrimaryTextColor(sheet, r);
          }
        }
      }
    }
  }

  private replaceBrandingColors(
    sheet: CSSStyleSheet,
    r: number,
    cssText: string,
    primaryColor: string,
    isPrimary: boolean,
  ): void {
    // I think we should just leave primary / accent flipped for now :|
    // just because of how we reference them throughout the rest of hawkeye.
    let fuseText = '--fuse-primary';
    if (isPrimary) {
      fuseText = '--fuse-accent';
    }
    // ensure we have the correct CSS Rule
    if (cssText.includes(`${fuseText}-500: #`)) {
      const colorPalette = generatePalette(primaryColor);
      let newRule = cssText;
      let stringToReplace = '';
      for (const [key, value] of Object.entries(colorPalette)) {
        // --fuse-primary-500 / --fuse-accent-500 is default
        if (key === '500') {
          // replace hex --fuse-primary:
          stringToReplace = this.getHexStringToReplace(cssText, fuseText);
          newRule = newRule.replace(stringToReplace, `${fuseText}: ${value}`);
          // replace rgb --fuse-primary-rgb:
          stringToReplace = this.getRGBStringToReplace(cssText, fuseText);
          newRule = newRule.replace(stringToReplace, `${fuseText}-rgb: ${chroma(value).rgb().join(',')}`);

          // replace hex --fuse-primary-500:
          stringToReplace = this.getHexStringToReplace(cssText, fuseText, key);
          newRule = newRule.replace(stringToReplace, `${fuseText}-${key}: ${value}`);
          // replace rgb --fuse-primary-500-rgb:
          stringToReplace = this.getRGBStringToReplace(cssText, fuseText, key);
          newRule = newRule.replace(stringToReplace, `${fuseText}-${key}-rgb: ${chroma(value).rgb().join(',')}`);
        } else {
          // replace hex -fuse-primary-X:
          stringToReplace = this.getHexStringToReplace(cssText, fuseText, key);
          newRule = newRule.replace(stringToReplace, `${fuseText}-${key}: ${value}`);
          // replace rgb -fuse-primary-X-rgb:
          stringToReplace = this.getRGBStringToReplace(cssText, fuseText, key);
          newRule = newRule.replace(stringToReplace, `${fuseText}-${key}-rgb: ${chroma(value).rgb().join(',')}`);
        }
      }
      sheet.deleteRule(r);
      sheet.insertRule(newRule, r);
    }
  }

  //.text-primary needs replaced, it gets stored in the source style.css file as a hardcoded color, not --fuse-primary-500
  private replaceLinkTextColor(sheet: CSSStyleSheet, r: number): void {
    const newRule =
      '.text-primary { --tw-text-opacity: 1 !important; color: rgba(var(--fuse-primary-500-rgb), var(--tw-text-opacity)) !important; }';
    sheet.deleteRule(r);
    sheet.insertRule(newRule, r);
  }

  private replaceOutlineColor(sheet: CSSStyleSheet, r: number): void {
    const newRule =
      '.outline-primary { --tw-outline-opacity: 1 !important; color: rgba(var(--fuse-primary-500-rgb), var(--tw-outline-opacity)) !important; }';
    sheet.deleteRule(r);
    sheet.insertRule(newRule, r);
  }

  //.text-tilled-primary we want to default to black for any branded white-label
  private replacePrimaryTextColor(sheet: CSSStyleSheet, r: number): void {
    const newRule =
      '.text-tilled-primary { --tw-text-opacity: 1 !important; color: rgb(0 0 0 / var(--tw-text-opacity)) !important; }';
    sheet.deleteRule(r);
    sheet.insertRule(newRule, r);
  }

  //.bg-primary needs replaced, it gets stored in the source style.css file as a hardcoded color, not --fuse-primary-500
  private replacePrimaryBackground(sheet: CSSStyleSheet, r: number): void {
    const newRule =
      '.bg-primary { --tw-bg-opacity: 1 !important; background-color: rgba(var(--fuse-primary-500-rgb), var(--tw-bg-opacity)) !important; }';
    sheet.deleteRule(r);
    sheet.insertRule(newRule, r);
  }

  private getHexStringToReplace(cssText: string, fuseText: string, key?: string): string {
    let stringToReplace = '';
    let index = 0;
    if (!key) {
      stringToReplace = `${fuseText}:`;
      index = cssText.indexOf(stringToReplace);
      stringToReplace = cssText.substring(index, index + stringToReplace.length + 8);
    } else {
      stringToReplace = `${fuseText}-${key}:`;
      index = cssText.indexOf(stringToReplace);
      stringToReplace = cssText.substring(index, index + stringToReplace.length + 8);
    }
    return stringToReplace;
  }

  private getRGBStringToReplace(cssText: string, fuseText: string, key?: string): string {
    let stringToReplace = '';
    let semiIndex = 0;
    let index = 0;
    if (!key) {
      stringToReplace = `${fuseText}-rgb:`;
      index = cssText.indexOf(stringToReplace);
      semiIndex = cssText.indexOf(';', index);
      stringToReplace = cssText.substring(index, semiIndex);
    } else {
      stringToReplace = `${fuseText}-${key}-rgb:`;
      index = cssText.indexOf(stringToReplace);
      semiIndex = cssText.indexOf(';', index);
      stringToReplace = cssText.substring(index, semiIndex);
    }
    return stringToReplace;
  }
}
