import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TilledInputComponent } from '../form-fields/tilled-input/tilled-input.component';

@Component({
  selector: 'outline-input',
  templateUrl: './outline-input.component.html',
  styleUrls: ['./outline-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
})
export class OutlineInputComponent extends TilledInputComponent {
  @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
  @Input() inputTextStyle?: string = 'ml-4 mb-2 text-tilled-primary py-1';
  @Input() value: any;
  @Input() mask?: string;
  @Input() thousandSeparator?: string = ',';
  @Input() width: string = 'w-24';
  @Input() paragraphText?: string;

  constructor() {
    super();
  }

  ngOnInit() {}

  onInputBlur() {
    this.onBlur.emit(this.label);
  }
}
