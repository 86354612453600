/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import validator from 'validator';

export function isValidUrl(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    return validator.isURL(value, {
      require_host: true,
      require_protocol: true,
      require_tld: false,
    })
      ? null
      : { invalidUrl: true };
  };
}
