<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Details' : null"
  [description]="!forConsole ? 'Tell us about your business' : null"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDetailsForm">
    <tilled-input
      data-test-id="details-legal-name"
      class="w-full"
      name="legalName"
      placeholder="Full Legal Business Name"
      label="LEGAL BUSINESS NAME"
      tilledLabel="true"
    ></tilled-input>
    <tilled-input
      data-test-id="details-dba"
      class="w-full"
      name="dba"
      placeholder="Business “DBA” or Trading Name"
      label="DOING BUSINESS AS"
      tilledLabel="true"
      toolTip="The business name you present to customers"
    ></tilled-input>
    <div class="pt-5">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true">BUSINESS ENTITY TYPE</tilled-label-l1>
        <mat-icon
          class="z-10 ml-2 icon-size-4"
          [svgIcon]="'heroicons_solid:information-circle'"
          matTooltip="Your legal business structure as registered for tax filing"
        ></mat-icon>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="details-entity-type"
          class="italic"
          placeholder="Choose One"
          [formControlName]="'type'"
        >
          <mat-option *ngFor="let type of entityTypes" [value]="type.name"> {{ type.desc }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <tilled-input
      data-test-id="details-business-identifier"
      class="w-full"
      name="businessIdentifier"
      placeholder="Tax ID #, Canadian Business #, Social Security # or EIN"
      label="BUSINESS IDENTIFIER"
      tilledLabel="true"
      mask="000000000"
      toolTip="ie: Tax ID#, Canadian Business #, SSN # or EIN"
      errors="{{ businessDetailsForm.get('businessIdentifier').hasError('minLength') }}"
      errorMessage="Business Identifier must be 9 digits."
    ></tilled-input>
    <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
      <div class="col-span-1 flex flex-col">
        <tilled-label-l1 secondary="true" class="pt-8">COUNTRY OF INCORPORATION</tilled-label-l1>
        <mat-form-field appearance="standard" class="-mt-4 w-full">
          <mat-select
            data-test-id="details-country-inc"
            class="italic"
            placeholder="Choose One"
            [formControlName]="'countryOfInc'"
          >
            <mat-option
              *ngFor="let countryAndCode of validCountriesOfInc | keyvalue : originalOrder"
              [value]="countryAndCode.key"
            >
              {{ countryAndCode.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-1 flex flex-col">
        <tilled-input
          data-test-id="details-years-inc"
          name="yearOfInc"
          label="YEAR OF INCORPORATION"
          tilledLabel="true"
          mask="0000"
          [errors]="
            businessDetailsForm.get('yearOfInc').hasError('min') || businessDetailsForm.get('yearOfInc').hasError('max')
          "
          errorMessage="Must be a valid year."
        ></tilled-input>
      </div>
    </div>
    <div class="pt-8">
      <tilled-label-l1 secondary="true">Industry</tilled-label-l1>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="details-business-category"
          class="italic"
          placeholder="Choose One"
          [formControlName]="'category'"
        >
          <mat-option *ngFor="let code of codes" [value]="code.name"> {{ code.desc }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <tilled-input
      data-test-id="details-statement-descriptor"
      class="w-full pb-2.5"
      name="statementDescriptor"
      placeholder=""
      label="What name do you want to appear on customer credit card statements?"
      tilledLabel="true"
      maxLength="20"
    ></tilled-input>
    <div class="pt-6">
      <tilled-label-l1 secondary="true">DESCRIBE THE PRODUCT AND SERVICES YOU OFFER</tilled-label-l1>
      <mat-form-field appearance="outline" class="w-full">
        <textarea
          class="text-field"
          data-test-id="details-description"
          [formControlName]="'description'"
          matInput
          maxLength="240"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</merchant-app-card>
