<mat-form-field
  class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 {{ styles }}"
  [floatLabel]="'always'"
>
  <mat-icon matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="controlName"
    [matTooltip]="toolTip"
    class="text-secondary text-p3"
  />
  <button *ngIf="showClear" mat-icon-button aria-label="Clear" (click)="clearSearchText()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
