import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Takes 2 strings and will highlight(bold) matching character sequence
 * on the first string based on the passed in 'matchingString'. Case insensitive
 * eg. transform("Tilled", "til") = *Til*led where the Til is bold.
 */
@Pipe({
  name: 'matchingStringToBold',
})
export class MatchingStringToBoldPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string, matchingString): any {
    let sanitized = value.trim();
    try {
      if (sanitized.length > 2 && matchingString.length > 2) {
        return this.sanitize(this.replace(value, matchingString));
      }
    } catch {
      return value;
    }
  }

  replace(str, matchingString) {
    return str.replace(new RegExp(`(${matchingString})`, 'gi'), '<b>$1</b>');
  }

  sanitize(str) {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }
}
