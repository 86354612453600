<div class="flex flex-col flex-auto min-w-80 max-w-120">
  <div class="flex justify-end">
    <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon>
  </div>

  <div class="grid w-full justify-items-center pb-8">
    <!-- Title -->
    <tilled-heading-h2 class="pb-2 text-center">Update Billing and Payout Schedules</tilled-heading-h2>
    <tilled-paragraph-p3 class="text-center"
      >Select the desired billing and payout configurations.<br />The most preferable option for a merchant may vary
      depending on their situation.
      <a
        class="text-primary whitespace-nowrap"
        target="_blank"
        href="https://tilledpartners.zendesk.com/hc/en-us/categories/14767565208468-Billing-Payouts"
        >Learn more ></a
      ></tilled-paragraph-p3
    >
  </div>

  <!-- Form -->
  <form data-test-id="schedule-form" class="w-full" [formGroup]="scheduleForm">
    <div class="pt-5">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true"
          >BILLING FREQUENCY
          <mat-icon
            class="icon-size-4 align-sub relative z-10"
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The billing frequency refers to how often a merchant is billed for fees. While daily billing is standard, some merchants may prefer weekly or monthly."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="schedule-billing-frequency"
          class=""
          placeholder="Choose One"
          [formControlName]="'billingIntervalUnit'"
        >
          <mat-option value="day">Daily</mat-option>
          <mat-option value="week">Weekly</mat-option>
          <mat-option value="month">Monthly</mat-option>
          <!-- Show a disabled option for the existing frequency -->
          <mat-option
            [value]="capability.processing_details.billing_frequency"
            disabled
            *ngIf="capability.processing_details.billing_interval_count !== 1"
            >{{ capability.processing_details.billing_frequency }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('billingIntervalUnit').value === 'week'">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true"
          >BILLING ANCHOR DAY
          <mat-icon
            class="icon-size-4 align-sub relative z-10"
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The billing anchor day refers to the day of the week or month on which fees are billed. If fee collection fails due to a low account balance, the billing job will retry until the collection succeeds. As a result, the actual billing day may be different from the scheduled billing day."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="schedule-billing-anchor-day-of-week"
          class=""
          placeholder="Choose One"
          [formControlName]="'billingAnchorDayOfWeek'"
        >
          <mat-option [value]="0">{{ daysOfWeek[0] }}</mat-option>
          <mat-option [value]="1">{{ daysOfWeek[1] }}</mat-option>
          <mat-option [value]="2">{{ daysOfWeek[2] }}</mat-option>
          <mat-option [value]="3">{{ daysOfWeek[3] }}</mat-option>
          <mat-option [value]="4">{{ daysOfWeek[4] }}</mat-option>
          <mat-option [value]="5">{{ daysOfWeek[5] }}</mat-option>
          <mat-option [value]="6">{{ daysOfWeek[6] }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('billingIntervalUnit').value === 'month'">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true"
          >BILLING ANCHOR DAY
          <mat-icon
            class="icon-size-4 align-sub relative z-10"
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The billing anchor day refers to the day of the week or month on which fees are billed. If fee collection fails due to a low account balance, the billing job will retry until the collection succeeds. As a result, the actual billing day may be different from the scheduled billing day."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="schedule-billing-anchor-day-of-month"
          class=""
          placeholder="Choose One"
          [formControlName]="'billingMonthlyAnchorDateType'"
        >
          <ng-container *ngFor="let anchorType of monthlyAnchorTypes">
            <mat-option [value]="anchorType.value">{{ anchorType.text }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pt-5">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true"
          >PAYOUT FREQUENCY
          <mat-icon
            class="icon-size-4 align-sub relative z-10"
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The merchant's payout frequency refers to how often the merchant receives funds from their settled transactions."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="schedule-payout-frequency"
          class=""
          placeholder="Choose One"
          [formControlName]="'payoutIntervalUnit'"
        >
          <mat-option value="day">Daily</mat-option>
          <mat-option value="week">Weekly</mat-option>
          <mat-option value="month">Monthly</mat-option>
          <!-- Show a disabled option for the existing frequency -->
          <mat-option
            [value]="capability.processing_details.payout_frequency"
            disabled
            *ngIf="capability.processing_details.payout_interval_count !== 1"
            >{{ capability.processing_details.payout_frequency }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('payoutIntervalUnit').value === 'week'">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true"
          >PAYOUT ANCHOR DAY
          <mat-icon
            class="icon-size-4 align-sub relative z-10"
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The payout anchor day refers to the day of the week or month on which funds are disbursed to the merchant's bank account."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="schedule-payout-anchor-day-of-week"
          class=""
          placeholder="Choose One"
          [formControlName]="'payoutAnchorDayOfWeek'"
        >
          <mat-option [value]="0">{{ daysOfWeek[0] }}</mat-option>
          <mat-option [value]="1">{{ daysOfWeek[1] }}</mat-option>
          <mat-option [value]="2">{{ daysOfWeek[2] }}</mat-option>
          <mat-option [value]="3">{{ daysOfWeek[3] }}</mat-option>
          <mat-option [value]="4">{{ daysOfWeek[4] }}</mat-option>
          <mat-option [value]="5">{{ daysOfWeek[5] }}</mat-option>
          <mat-option [value]="6">{{ daysOfWeek[6] }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('payoutIntervalUnit').value === 'month'">
      <div class="flex flex-row items-center">
        <tilled-label-l1 secondary="true"
          >PAYOUT ANCHOR DAY
          <mat-icon
            class="icon-size-4 align-sub relative z-10"
            [svgIcon]="'heroicons_solid:information-circle'"
            matTooltip="The payout anchor day refers to the day of the week or month on which funds are disbursed to the merchant's bank account."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <mat-form-field appearance="standard" class="-mt-4 w-full">
        <mat-select
          data-test-id="schedule-payout-anchor-day-of-month"
          class=""
          placeholder="Choose One"
          [formControlName]="'payoutMonthlyAnchorDateType'"
        >
          <ng-container *ngFor="let anchorType of monthlyAnchorTypes">
            <mat-option [value]="anchorType.value">{{ anchorType.text }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="flex justify-end w-full px-8 pt-8">
    <!-- Cancel Button -->
    <tilled-button
      data-test-id="cancel-btn"
      bgColor="white"
      styles="text-tilled-secondary"
      [whiteText]="false"
      [rounded]="true"
      buttonText="Cancel"
      (buttonClick)="closeDialog()"
      [disabled]="isSubmitting"
    ></tilled-button>

    <!-- Submit Button -->
    <tilled-button
      data-test-id="submit-btn"
      bgColor="primary"
      [rounded]="true"
      buttonText="Submit"
      (buttonClick)="submitClicked()"
      [whiteText]="true"
      [disabled]="isSubmitting"
    ></tilled-button>
  </div>
</div>
