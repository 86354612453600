import { Pipe, PipeTransform } from '@angular/core';
import { Account, AccountCapability, ProductCode } from '../../../../projects/tilled-api-client/src';

@Pipe({
  name: 'capabilityStatus',
})
export class CapabilityStatusPipe implements PipeTransform {
  constructor() {}

  /**
   *
   * @param account Account object
   * @param type The type of capability whose status you are looking for (card, debit, or leave off for overall status)
   * @returns
   */
  transform(account: Account, type?: string): AccountCapability.StatusEnum {
    let status;

    switch (type) {
      case 'card':
        status = this.getStatus(
          account.capabilities?.filter((cap) => {
            if (
              cap.product_code &&
              (cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.CARD ||
                cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.CARD_PRESENT)
            ) {
              return cap;
            }
          }),
        );
        break;
      case 'debit':
        status = this.getStatus(
          account.capabilities?.filter((cap) => {
            if (
              cap.product_code &&
              (cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.ACH_DEBIT ||
                cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.EFT_DEBIT)
            ) {
              return cap;
            }
          }),
        );
        break;
      default:
        if (account.capabilities?.length === 0) {
          status = 'REQUIRES PRICING';
        } else {
          status = this.getStatus(account.capabilities);
        }
        break;
    }

    return status;
  }

  getStatus(capabilities: AccountCapability[]): AccountCapability.StatusEnum {
    const statusOrder: AccountCapability.StatusEnum[] = [
      AccountCapability.StatusEnum.ACTIVE,
      AccountCapability.StatusEnum.IN_REVIEW,
      AccountCapability.StatusEnum.REJECTED,
      AccountCapability.StatusEnum.STARTED,
      AccountCapability.StatusEnum.SUBMITTED,
      AccountCapability.StatusEnum.WITHDRAWN,
      AccountCapability.StatusEnum.DISABLED,
    ];
    if (capabilities?.length === 0) {
      return AccountCapability.StatusEnum.DISABLED;
    } else if (capabilities?.find((cap) => cap.status === AccountCapability.StatusEnum.ACTIVE)) {
      return AccountCapability.StatusEnum.ACTIVE;
    } else if (capabilities?.length > 0) {
      capabilities.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));
      return capabilities[0].status;
    }
  }
}
