import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TimelineActivity } from 'app/shared/payments/payments.types';

@Component({
  selector: 'timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TimelineCardComponent implements OnInit, OnChanges {
  @Input() timelineActivities: TimelineActivity[];
  @Input() hideTransactionIds: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
