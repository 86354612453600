import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes a Date
 */
@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  /**
   *
   * @param status Date
   * @returns
   */
  transform(date: string, dateOnly: boolean = false): string {
    const localDate = new Date(date);

    let options: any;
    if (dateOnly) {
      options = {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      } as const;
    } else {
      options = {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      } as const;
    }
    return localDate.toLocaleDateString('en-us', options);
  }
}
