/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSettings } from './accountSettings';
import { AccountDocument } from './accountDocument';
import { AccountCapability } from './accountCapability';
import { BusinessProfile } from './businessProfile';
import { TerminalReader } from './terminalReader';
import { BankAccount } from './bankAccount';


export interface Account { 
    /**
     * Bank accounts attached to this account. Primarily used for payouts.
     */
    bank_accounts: Array<BankAccount>;
    business_profile?: BusinessProfile;
    /**
     * Capabilities represent the assigned product codes to a given merchant account and their status.
     */
    capabilities?: Array<AccountCapability>;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Documents that have been requested for an account, which are either in the `requested` or `submitted` status
     */
    document_requests: Array<AccountDocument>;
    /**
     * The primary user\'s email address.
     */
    email?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Whether Tilled is providing support for this account.
     */
    merchant_support?: boolean;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * The business name or individual name.
     */
    name?: string;
    settings?: AccountSettings;
    /**
     * Terminal Readers attached to this account.
     */
    terminal_readers: Array<TerminalReader>;
    /**
     * The Tilled account type. Can be `partner` or `merchant`.
     */
    type: Account.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The `partner` white label domain.
     */
    white_label_domain?: string;
}
export namespace Account {
    export type TypeEnum = 'partner' | 'merchant';
    export const TypeEnum = {
        PARTNER: 'partner' as TypeEnum,
        MERCHANT: 'merchant' as TypeEnum
    };
}


