import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, shareReplay, Subject, tap } from 'rxjs';
import {
  CreatePaymentIntentRequestParams,
  CreatePaymentMethodRequestParams,
  GetPaymentIntentRequestParams,
  InternalListPaymentIntentsRequestParams,
  InternalService,
  PaymentIntent,
  PaymentIntentsService,
  PaymentMethod,
  PaymentMethodsService,
  UpdatePaymentIntentRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { InternalPaymentIntent } from '../../../../projects/tilled-api-client/src/model/internalPaymentIntent';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
@Injectable({
  providedIn: 'root',
})
export class PaymentAppService {
  constructor(
    private _paymentIntentsService: PaymentIntentsService,
    private _paymentMethodsService: PaymentMethodsService,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {}

  private _payments$ = new ReplaySubject<InternalPaymentIntent[]>();
  private _paymentsCount$ = new ReplaySubject<number>();
  private _payment$ = new Subject<InternalPaymentIntent>();
  private _cardPresentPayment$ = new Subject<PaymentIntent>();

  public payments$: Observable<InternalPaymentIntent[]> = this._payments$.asObservable();
  public paymentsCount$: Observable<number> = this._paymentsCount$.asObservable();
  public payment$: Observable<InternalPaymentIntent> = this._payment$.asObservable();
  public cardPresentPayment$: Observable<PaymentIntent> = this._cardPresentPayment$.asObservable();

  public getAllPaymentIntents(params: InternalListPaymentIntentsRequestParams): void {
    this._internalService
      .internalListPaymentIntents(params)
      .pipe(
        tap((result) => this._paymentsCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (payments) => {
          this._payments$.next(payments);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: err.error?.message ? err.error.message : 'Could not load all payments',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  public getPaymentIntent(params: GetPaymentIntentRequestParams): void {
    this._internalService.internalGetPaymentIntent(params).subscribe({
      next: (payment) => {
        this._payment$.next(payment);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: "Could not load payment '" + params.id + "'",
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public updatePaymentIntent(params: UpdatePaymentIntentRequestParams): void {
    this._internalService.internalUpdatePaymentIntent(params).subscribe({
      next: (payment) => {
        this._payment$.next(payment);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: "Could not update payment '" + params.id + "'",
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  // error handling/alert is handled in the component
  public createPaymentIntent(
    params: CreatePaymentIntentRequestParams,
    parentAccountId?: string,
  ): Observable<PaymentIntent> {
    return this._paymentIntentsService.createPaymentIntent(params);
  }

  public createCardPresentPayment(params: CreatePaymentMethodRequestParams, amount: number): void {
    this._paymentMethodsService.createPaymentMethod(params).subscribe({
      next: (paymentMethod) => {
        this.createPaymentIntent({
          tilledAccount: params.tilledAccount,
          paymentIntentCreateParams: {
            payment_method_id: paymentMethod.id,
            amount: amount,
            confirm: true,
            currency: PaymentIntent.CurrencyEnum.USD,
            payment_method_types: [PaymentMethod.TypeEnum.CARD_PRESENT],
          },
        }).subscribe({
          next: (payment) => {
            this._cardPresentPayment$.next(payment);
          },
          error: (err) => {
            // generic catch all for error responses
            const message: TilledAlert = {
              message: 'Payment Create failed.',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          },
        });
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not create payment method',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }
}
