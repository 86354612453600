import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StepperHeaderComponent implements OnInit, OnChanges {
  @Input() text1: string;
  @Input() text2: string;
  @Input() text3: string;

  @Output() onClick1: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClick2: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClick3: EventEmitter<string> = new EventEmitter<string>();

  public styles1: string = 'circle-number grey-border';
  public styles2: string = 'circle-number grey-border';
  public styles3: string = 'circle-number grey-border';

  public line1: string = 'grey-line';
  public line2: string = 'grey-line';

  @Input() stepNumber: number = 1;
  constructor() {}

  ngOnInit(): void {
    this.stepSelect();
  }

  ngOnChanges(): void {
    this.stepSelect();
  }

  stepSelect() {
    if (this.stepNumber === 1) {
      this.styles1 = 'circle-number accent-border';
    }
    if (this.stepNumber === 2) {
      this.styles2 = 'circle-number accent-border';
      this.line1 = 'accent-line';
    }
    if (this.stepNumber === 3) {
      this.styles3 = 'circle-number accent-border';
      this.line1 = 'accent-line';
      this.line2 = 'accent-line';
    }
  }

  onClick(stepNum: number) {
    switch (stepNum) {
      case 1:
        this.onClick1.emit(this.text1);
        break;
      case 2:
        this.onClick2.emit(this.text2);
        break;
      case 3:
        this.onClick3.emit(this.text3);
        break;
    }
  }
}
