import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'payment-card',
  templateUrl: './payment-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PaymentCardComponent implements OnInit, OnChanges {
  @Output() click: EventEmitter<string> = new EventEmitter<string>();

  @Input() icon?: string;
  @Input() text?: string;
  @Input() selected?: boolean = false;
  public styles: string = '';

  constructor() { }

  ngOnInit() {
    this.applyFillStyle();
  }

  ngOnChanges() {
    this.applyFillStyle();
  }

  applyFillStyle() {
    this.styles = this.selected ? ' bg-accent-900 ' : ' bg-tilled-neutral-300 ';
  }

  onButtonClick() {
    this.click.emit(this.text);
  }
}
