<div class="flex min-w-100 flex-auto flex-col px-6">
  <div class="-mr-6 flex justify-end">
    <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center">
      <!-- Title -->
      <tilled-heading-h2 data-test-id="userInvite-header" class="pb-2">{{ titleText }}</tilled-heading-h2>
      <tilled-paragraph-p3 class="pb-4">{{ secondaryText }}</tilled-paragraph-p3>

      <!-- Form -->
      <ng-container>
        <div data-test-id="userInvite-form" class="min-w-100">
          <form [formGroup]="userInviteForm">
            <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col pb-4">
              <mat-label class="text-lg font-semibold">EMAIL</mat-label>
              <input
                data-test-id="userInvite-email"
                class="text-secondary text-md font-normal"
                matInput
                formControlName="email"
                placeholder="Enter the user’s email"
              />
              <mat-error
                class="text-sm"
                color="warn"
                *ngIf="
                  userInviteForm.get('email').hasError('email') || userInviteForm.get('email').hasError('required')
                "
                >A valid email is required</mat-error
              >
            </mat-form-field>

            <mat-form-field floatLabel="always" appearance="standard" class="w-full">
              <mat-label class="text-lg font-semibold">USER ROLE</mat-label>
              <mat-select
                data-test-id="userInvite-role"
                class="text-secondary text-md font-normal"
                [formControlName]="'role'"
              >
                <mat-option *ngFor="let userRole of availableUserRoles" [value]="userRole.value">{{
                  userRole.name
                }}</mat-option>
              </mat-select>
              <mat-error class="text-sm" color="warn" *ngIf="userInviteForm.get('role').hasError('required')"
                >A role is required</mat-error
              >
            </mat-form-field>
          </form>

          <div class="pb-6 pt-4">
            <tilled-paragraph-p3 data-test-id="description-header" class="font-semibold text-black"
              >Role Descriptions</tilled-paragraph-p3
            >
          </div>
          <ng-container *ngFor="let userRole of availableUserRoles">
            <div class="pb-2">
              <tilled-paragraph-p3 data-test-id="description-roleName">{{ userRole.name }}</tilled-paragraph-p3>
            </div>
            <div class="pb-4">
              <tilled-paragraph-p4 data-test-id="description" [secondary]="true">{{
                userRole.description
              }}</tilled-paragraph-p4>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <!-- Add Actions -->
    <div class="grid w-full justify-items-end">
      <!-- Save -->
      <tilled-button
        data-test-id="saveBtn"
        bgColor="primary"
        [rounded]="true"
        [buttonText]="buttonText"
        (buttonClick)="inviteOrEditUser()"
      ></tilled-button>
    </div>
  </ng-container>
</div>
