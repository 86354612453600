import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { Payout } from '../../../../projects/tilled-api-client/src';

/**
 * Takes a Payout StatusEnum
 */
@Pipe({
  name: 'payoutStatus',
})
export class PayoutStatusPipe implements PipeTransform {
  /**
   *
   * @param payout Payout
   * @returns
   */
  transform(payout: Payout): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: payout.status.toUpperCase(),
      toolTip: '',
    };
    if (payout.status === Payout.StatusEnum.PAID) {
      if (payout.amount >= 0) {
        chipConfig.color = ChipColorClass.OPAQUE_GREEN;
      } else {
        chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
        chipConfig.text = 'WITHDRAWN';
      }
    } else if (payout.status === Payout.StatusEnum.FAILED || payout.status === Payout.StatusEnum.CANCELED) {
      chipConfig.color = ChipColorClass.OPAQUE_RED;
    } else {
      chipConfig.color = ChipColorClass.OPAQUE_ORANGE;
    }

    return chipConfig;
  }
}
