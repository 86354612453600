<div class="max-h-[100vh] w-fit overflow-y-auto px-4 sm:w-120">
  <form-card
    (exitClick)="closeDialog()"
    [backButton]="false"
    [submitButton]="true"
    (submitClick)="copyPaymentLink()"
    submitText="Copy Payment Link to Clipboard"
  >
    <div data-test-id="modal-header" class="grid w-full justify-items-center pb-2 text-center">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2"
        >Payment Link for {{ amount | minorUnitsToCurrency : currency }}</tilled-heading-h2
      >
      <tilled-paragraph-p3 class="pb-8">Copy and share the unique payment link with your customer.</tilled-paragraph-p3>
    </div>
    <div>
      <div class="flex flex-col pb-6">
        <tilled-label-l1 secondary="true" class="pb-3">ONE-TIME PAYMENT LINK</tilled-label-l1>
        <div
          class="hoverable -ml-0.5 inline-block select-none break-all p-0.5 text-gray-400 hover:bg-primary-100"
          (click)="copyPaymentLink()"
        >
          {{ displayUrl }}
          <mat-icon class="align-[-1px] text-gray-400 icon-size-4" [svgIcon]="'heroicons_solid:clipboard'"></mat-icon>
        </div>
      </div>
    </div>
  </form-card>
</div>
