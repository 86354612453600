import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DocumentDto } from '@tilled-api-client';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { Subscription, takeUntil } from 'rxjs';
import { ComponentBase } from '../../core/componentBase';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'account-documents',
  templateUrl: './account-documents.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class AccountDocumentsComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() merchantAccountId: string;
  @Output() documentsCountChanged: EventEmitter<number> = new EventEmitter<number>();

  public accountId: string;
  public requestedDocsCount: number = 0;
  private documentsAllSub: Subscription;

  constructor(private _authService: AuthService, private _documentsService: DocumentsAppService) {
    super();
  }

  ngOnInit(): void {
    this.accountId = this.merchantAccountId ?? AuthService.getCurrentAccountId();

    this.documentsAllSub = this._documentsService.documentsAllTotal$.subscribe((total) => {
      this.requestedDocsCount = total;
      this.onDocumentsChanged(total);
    });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account) => {
      if (this.merchantAccountId) {
        this._documentsService.getAllDocuments(this.accountId, DocumentDto.StatusEnum.REQUESTED);
      } else {
        this.accountId = account?.id;
      }
    });
  }

  ngOnDestroy(): void {
    this.documentsAllSub.unsubscribe();
  }

  onDocumentsChanged(count: number): void {
    this.documentsCountChanged.emit(count);
  }

  submitDocuments(): void {
    if (this.merchantAccountId) {
      const url = `/documents/${this.accountId}`;
      window.open(url, '_blank');
    } else {
      const url = `/documents`;
      window.open(url, '_blank');
    }
  }
}
