<div class="relative flex h-full w-full max-w-120 flex-col">
  <!-- Dismiss button -->

  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="flex flex-auto flex-col items-center p-8 pb-4 sm:flex-row sm:items-start sm:pb-4">
    <div class="mt-4 flex flex-col items-center space-y-1 text-center sm:mt-0 sm:items-start sm:pr-8 sm:text-left">
      <!-- Title -->

      <div class="self-center text-h2 font-bold text-tilled-primary">Confirm Access Removal</div>

      <!-- Message -->
      <div class="text-center text-p3 text-tilled-primary">{{ subtitle }}</div>

      <ng-container *ngIf="isMultiAccountUser">
        <mat-checkbox
          class="pt-5"
          [color]="'primary'"
          [checked]="isRevokeAll"
          (change)="isRevokeAllCheckboxChanged($event.checked)"
          >Revoke access from all associated accounts</mat-checkbox
        >
        <div class="text-secondary text-md italic">{{ checkboxSubtitle }}</div>
      </ng-container>
    </div>
  </div>

  <!-- Actions -->

  <div class="flex items-center justify-center space-x-3 px-6 py-4 dark:bg-black dark:bg-opacity-10 sm:justify-end">
    <!-- Cancel -->

    <button mat-stroked-button (click)="closeDialog()">Cancel</button>

    <!-- Confirm -->

    <button mat-flat-button [color]="'warn'" (click)="revokeAccess()">Revoke Access</button>
  </div>
</div>
