import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { cloneDeep, sortBy } from 'lodash';
import { Subscription } from 'rxjs';
import {
  AccountCapability,
  FilesService,
  GetContentsOfFileRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { MerchantAppInvitationDialogComponent } from '../merchant-app-invitation/merchant-app-invitation-dialog.component';
import { tableSymbol } from './decorators/column';
import { ColumnModel } from './decorators/column.model';
import { TableModel } from './decorators/table.model';

@Component({
  selector: 'tilled-table',
  templateUrl: './tilled-table.component.html',
  styleUrls: ['./tilled-table.component.scss'],
})
export class TilledTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private _data = [];
  private _originalData: any[] = [];
  private _tableModel: TableModel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private paginatorSub: Subscription;
  private sortSub: Subscription;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public sortInfo = null;
  public isLoading = true;
  public unnamedColumns = [];
  public viewportMaxSize: boolean = false;
  public screenHeight: number;
  public screenWidth: number;
  public actionHover: boolean = false;
  public dialogRef: any;

  @Input() accountId: string;
  @Input() hidePaginator: boolean = false;
  @Input() hideZeroState: boolean = false;
  @Input() queryString: string;
  @Input() queryMerchantId: string;
  @Input() dataLength: number;
  @Input() noDataMainText?: string = 'No data found';
  @Input() noDataSecondaryText?: string = 'Do things to get data.';
  @Input() startingPageLimit: number = DEFAULT_PAGE_LIMIT;
  @Input() startingPageIndex: number = 0;
  @Input() testId: string;
  @Input() hideColumns: number[] = [];
  @Input() hideColumnKeys: string[] = [];
  @Input() scrollable: boolean = false;
  @Input() showDisplayedColumnsDropdown: boolean = false;
  @Input() capabilityStatuses: AccountCapability.StatusEnum[];
  @Input() hasPermissionToView: boolean = true;

  // it may be unnecessary to pass query data here
  @Input() getPageCallback: (
    limit?: number,
    index?: number,
    sort?: string,
    q?: string,
    capability_status?: AccountCapability.StatusEnum[],
  ) => void;
  @Input() getClickCallback: (data: any, event?: MouseEvent) => void;

  @Input() set data(values: any[]) {
    if (values && values.length > 0) {
      this._data = cloneDeep(values);
      this._tableModel = this._data[0][tableSymbol];
      this.buildColumns();
      if (!this._originalData.length) {
        // Keep original order of data
        this._originalData = cloneDeep(this._data);
      }

      //no data, columns are defined, don't want to show 'empty' row
      if (Object.keys(this._data[0]).length === 0) {
        this._data = [];
      }
    }
  }

  get data(): any[] {
    return this._data;
  }

  @Input() instance: any;

  columns: ColumnModel[];
  displayedColumns: string[];

  constructor(
    private _filesService: FilesService,
    private _alertService: AlertService,
    private _matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.pageSize = this.startingPageLimit;
    this.pageIndex = this.startingPageIndex;
    this.onResize();
    if (this.hasPermissionToView === false) {
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.paginatorSub.unsubscribe();
    this.sortSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.paginatorSub = this.paginator.page.subscribe(() => {
      this.pageIndex = this.paginator.pageIndex;
      this.pageSize = this.paginator.pageSize;
      this.getPageCallback(this.pageSize, this.pageIndex, this.sortInfo, this.queryString, this.capabilityStatuses);
    });
    this.sortSub = this.sort.sortChange.subscribe(() => {
      if (!this.sort.active || this.sort.direction === '') {
        this.sortInfo = null;
        return;
      }
      this.sortInfo = this.sort.active + ':' + this.sort.direction;
      this.getPageCallback(this.pageSize, this.pageIndex, this.sortInfo, this.queryString, this.capabilityStatuses);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queryString || (changes.capabilityStatuses && !changes.capabilityStatuses.firstChange)) {
      this.pageIndex = this.startingPageIndex;
      this.getPageCallback(this.pageSize, 0, this.sortInfo, this.queryString, this.capabilityStatuses);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1520) {
      this.viewportMaxSize = true;
    } else {
      this.viewportMaxSize = false;
    }
  }

  sortData(params: Sort) {}

  openAlert(message: string, title: string, error?: boolean): void {
    const alert: TilledAlert = {
      message: message,
      type: error ? 'error' : 'info',
      timer: error ? null : 5000,
      title: title,
    };
    this._alertService.showAlert(alert);
  }

  rowClicked(data: any, event: MouseEvent): void {
    if ((<Element>event.target).localName === 'mat-cell' || this.actionHover) {
      this.getClickCallback(data, event);
    }
  }

  actionClicked(data: any): void {
    if (this.getClickCallback) {
      this.getClickCallback(data);
    }
  }

  async downloadFileClicked(fileId: string, fileName: string): Promise<void> {
    let params: GetContentsOfFileRequestParams = {
      tilledAccount: this.accountId ?? '',
      id: fileId,
    };
    await this._filesService.getContentsOfFile(params).subscribe({
      next: (res) => {
        const blob = new Blob([res], { type: 'text/csv' });
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(objectUrl);
      },
      error: (err) => {
        this.openAlert(`'${fileName}' failed to download`, 'Download failed', true);
      },
    });
  }

  private buildColumns() {
    this.columns = this._tableModel.columns;
    this.sortColumns();
    this.displayedColumns = this.columns.map((col) => col.key);
    if (this.hideColumns) {
      for (const column of this.hideColumns) {
        this.hideColumnKeys.push(this.displayedColumns[column]);
      }
    }
    if (this.hideColumnKeys) {
      for (const key of this.hideColumnKeys) {
        this.displayedColumns = this.displayedColumns.filter((k) => k !== key);
      }
    }
    this.columns.forEach((col) => {
      if (!col.name && this.displayedColumns.includes(col.key)) {
        this.unnamedColumns.push(col.key);
      }
    });

    this.isLoading = false;
  }

  public onColumnSelectionChange(keys: MatSelectChange) {
    this.displayedColumns = keys.value.concat(this.unnamedColumns);
  }

  private sortColumns() {
    this.columns = sortBy(this.columns, ['order']);
  }

  public inviteNewMerchantUser(accountId: string, name: string): void {
    this.dialogRef = this._matDialog.open(MerchantAppInvitationDialogComponent, {
      autoFocus: false,
      data: {
        accountId: accountId,
        title: 'Share merchant application',
        legalName: name,
        showCopyUrlButton: true,
        submitButtonText: 'Share via email',
        showLinkToUsers: true,
      },
    });
  }
}
