import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { Charge, Dispute } from '../../../../projects/tilled-api-client/src';
import { InternalPaymentIntent } from '../../../../projects/tilled-api-client/src/model/internalPaymentIntent';

const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;

/**
 * Takes a InternalPaymentIntent object and boolean to determine solid or opaque
 */
@Pipe({
  name: 'paymentStatus',
})
export class PaymentStatusPipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}

  /**
   *
   * @param payment InternalPaymentIntent object
   * @param solid is the chip color solid (or opaque)
   * @returns
   */
  transform(payment: InternalPaymentIntent, solid: boolean = false): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.SOLID_ACCENT,
      text: '',
      toolTip: '',
    };
    const disputed = payment.charges.find((c) => c.disputed === true);
    // DISPUTED takes precedence over all other statuses that may also apply, e.g. REFUNDED
    if (disputed) {
      chipConfig.color = solid ? ChipColorClass.SOLID_RED : ChipColorClass.OPAQUE_RED;
      chipConfig.text = 'DISPUTED';
      const dispute = disputed.disputes[0];
      if (dispute.status === Dispute.StatusEnum.WARNING_NEEDS_RESPONSE) {
        const daysToRespond = this.daysBetween(new Date(), new Date(dispute.closing_at));
        chipConfig.toolTip = `Evidence submission due in ${daysToRespond} day${daysToRespond === 1 ? '' : 's'}.`;
      } else if (dispute.status === Dispute.StatusEnum.WARNING_UNDER_REVIEW) {
        chipConfig.toolTip = 'No action is needed at this time.';
      } else if (dispute.status === Dispute.StatusEnum.WARNING_CLOSED) {
        chipConfig.toolTip = 'The dispute inquiry was closed.';
      } else if (dispute.status === Dispute.StatusEnum.NEEDS_RESPONSE) {
        const daysToRespond = this.daysBetween(new Date(), new Date(dispute.closing_at));
        chipConfig.toolTip = `Evidence submission due in ${daysToRespond} day${daysToRespond === 1 ? '' : 's'}.`;
      } else if (dispute.status === Dispute.StatusEnum.UNDER_REVIEW) {
        chipConfig.toolTip = 'No action is needed at this time.';
      } else if (dispute.status === Dispute.StatusEnum.CLOSED) {
        chipConfig.toolTip = 'The dispute deadline was missed.';
      } else if (dispute.status === Dispute.StatusEnum.WON) {
        chipConfig.toolTip = 'The dispute was won.';
      } else if (dispute.status === Dispute.StatusEnum.LOST) {
        chipConfig.toolTip = 'The dispute was lost';
      }
      return chipConfig;
    }

    if (payment.status === InternalPaymentIntent.StatusEnum.SUCCEEDED) {
      const successfulCharge = payment.charges.find((c) => c.status === Charge.StatusEnum.SUCCEEDED);
      if (successfulCharge?.refunded) {
        chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
        chipConfig.text = 'REFUNDED';
        chipConfig.toolTip = 'The payment has been fully refunded.';
      } else if (successfulCharge?.amount_refunded > 0) {
        chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
        chipConfig.text = 'PARTIAL REFUND';
        const refundAmountText = this._currencyPipe.transform(successfulCharge.amount_refunded / 100);
        chipConfig.toolTip = `${refundAmountText} was refunded.`;
      } else {
        chipConfig.color = solid ? ChipColorClass.SOLID_GREEN : ChipColorClass.OPAQUE_GREEN;
        chipConfig.text = 'SUCCEEDED';
        chipConfig.toolTip = 'This payment is complete.';
      }
    } else if (payment.status === InternalPaymentIntent.StatusEnum.REQUIRES_PAYMENT_METHOD) {
      if (payment.charges.length > 0) {
        // To determine if an asynchronous charge (ach/eft) was "returned"
        // it would be ideal to search balance transactions for a `type=charge_failure_refund`,
        // but if `charge.amount_captured > 0` is essentially the exact same thing.
        // Ideally, we would confirm that the type='ach_debit' or 'eft_debit' but we don't
        // currently have access to that data on the charge object, and it doesn't
        // actually matter since failed `card` charges don't capture any.
        let foundReturnedCharge = false;
        payment.charges.forEach((ch) => {
          if (ch.status === 'failed' && ch.amount_captured > 0) {
            foundReturnedCharge = true;
          }
        });

        if (foundReturnedCharge) {
          chipConfig.color = solid ? ChipColorClass.SOLID_RED : ChipColorClass.OPAQUE_RED;
          chipConfig.text = 'RETURNED';
          chipConfig.toolTip = payment.last_payment_error?.message;
        } else {
          chipConfig.color = solid ? ChipColorClass.SOLID_RED : ChipColorClass.OPAQUE_RED;
          chipConfig.text = 'FAILED';
          chipConfig.toolTip = payment.last_payment_error?.message;
        }
      } else {
        chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
        chipConfig.text = 'INCOMPLETE';
        chipConfig.toolTip = 'The payment method is required.';
      }
    } else if (payment.status === InternalPaymentIntent.StatusEnum.PROCESSING) {
      chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'PROCESSING';
    } else if (payment.status === InternalPaymentIntent.StatusEnum.CANCELED) {
      chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'CANCELED';
    } else if (payment.status === InternalPaymentIntent.StatusEnum.REQUIRES_CAPTURE) {
      chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'UNCAPTURED';
    } else if (payment.status === InternalPaymentIntent.StatusEnum.REQUIRES_CONFIRMATION) {
      chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'INCOMPLETE';
      chipConfig.toolTip = 'The payment requires confirmation.';
    } else if (payment.status === InternalPaymentIntent.StatusEnum.REQUIRES_ACTION) {
      chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'INCOMPLETE';
      chipConfig.toolTip = 'The payment requires additional actions.';
    } else {
      chipConfig.color = solid ? ChipColorClass.SOLID_ACCENT : ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = payment.status;
    }
    return chipConfig;
  }

  private daysBetween(start: Date, end: Date) {
    const diffMillis = end.getTime() - start.getTime();
    const diffDays = Math.floor(diffMillis / MILLIS_PER_DAY);
    return diffDays;
  }
}
