import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentBase } from 'app/core/componentBase';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { PaymentIntent } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'app-copy-payment-link',
  templateUrl: './copy-payment-link-dialog.component.html',
})
export class CopyPaymentLinkDialogComponent extends ComponentBase implements OnInit {
  public url: string;
  public displayUrl: string;
  public amount: string;
  public currency: PaymentIntent.CurrencyEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private dialogRef: MatDialogRef<CopyPaymentLinkDialogComponent>,
    private _clipboard: Clipboard,
    private _alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.amount = this._data.amount;
    this.url = this._data.url;
    this.currency = this._data.currency;
    // Truncate off the additional information
    this.displayUrl = this.url.split('#')[0];
  }

  public copyPaymentLink(): void {
    this._clipboard.copy(this.url);
    const message: TilledAlert = {
      message: 'Payment link was copied to clipboard',
      title: 'Payment link copied',
      type: 'info',
      timer: 5000,
    };
    this._alertService.showAlert(message);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
