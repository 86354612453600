/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserInvitation { 
    /**
     * The ID of the Account associated with this pending user.
     */
    account_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The pending user\'s email.
     */
    email: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Frontend link for the user invitation.
     */
    invitation_url?: string;
    /**
     * The ID of the user who sent the invitation.
     */
    inviter_user_id?: string;
    /**
     * The pending user\'s role, primarily used in the Tilled Dashboard. The roles have varied restrictions on the actions they can take.
     */
    role: UserInvitation.RoleEnum;
    /**
     * Time at which the invitation was last sent.
     */
    sent_at?: string;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace UserInvitation {
    export type RoleEnum = 'owner' | 'admin' | 'developer' | 'analyst' | 'view_only' | 'merchant_owner' | 'merchant_admin';
    export const RoleEnum = {
        OWNER: 'owner' as RoleEnum,
        ADMIN: 'admin' as RoleEnum,
        DEVELOPER: 'developer' as RoleEnum,
        ANALYST: 'analyst' as RoleEnum,
        VIEW_ONLY: 'view_only' as RoleEnum,
        MERCHANT_OWNER: 'merchant_owner' as RoleEnum,
        MERCHANT_ADMIN: 'merchant_admin' as RoleEnum
    };
}


