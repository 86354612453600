import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
})
export class CalendarPickerComponent implements OnInit {
  selected: Date | null;
  @Input() selectedRangeValue: DateRange<Date> | undefined;
  @Output() setDateRange: EventEmitter<{ minDate: any; maxDate: any }> = new EventEmitter();
  public minDate;
  public maxDate;

  filterForm = new FormGroup({
    minDate: new FormControl(),
    maxDate: new FormControl(),
  });

  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.processQueryParams(this._activatedRoute.snapshot.queryParams);
  }

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.setDateRange.emit({
      minDate: this.selectedRangeValue.start,
      maxDate: this.selectedRangeValue.end,
    });
  }

  /**
   * Takes query params and updates the form input fields and then updates the route after we've
   * filtered the params
   * @param params
   * @returns
   */
  processQueryParams(params: Params) {
    if (Object.keys(params).length > 0) {
      if (params.minDate && params.maxDate) {
        this.minDate = new Date(params.minDate);
        this.maxDate = new Date(params.maxDate);
        this.filterForm.patchValue({ minDate: this.minDate });
        this.filterForm.patchValue({ maxDate: this.maxDate });
      }
    }
  }
}
