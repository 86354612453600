import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TilledParagraphGeneric } from './tilled-paragraph-generic.component';

@Component({
  selector: 'tilled-paragraph-p4',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TilledParagraphP4Component extends TilledParagraphGeneric {
  @Input() bold: boolean = false;
  @Input() italic: boolean = false;
  public styles: string;
  constructor() {
    super();
    this.styles = 'text-p4';
  }
}
